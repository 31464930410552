import React, { useContext, useState, useEffect } from "react";
import "../../css/Complain.css";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import ProfessionPopup from "./ProfessionPopup";
import ProfessionTable from "./ProfessionTable";
// import ManagementPopUp from './ManagementPopUp';
// import ManagementTable from './ManagementTable';
import $ from "jquery";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import SidebarContext from "../sidebarcontext/SidebarContext";

function Profession() {
  const { setPopup } = useContext(SidebarContext);
  const {
    setStep,
    closeChecked,
    setCloseChecked,
    otherSubmit,
    setOtherSubmit,
    editdata,
    setViewPop,
    memberFormValue,
    setActiveTab,
  } = useContext(MemberContext);
  const context = useContext(ProfessionContext);
  const { popup2, setPopup2, setProfessionFormValue, professionvalue } =
    context;
  const [last, setLast] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleAddProfession = (e) => {
    e.preventDefault();
    setPopup2(true);
    setProfessionFormValue(professionvalue);
    $(".complainTopicPop").fadeIn(100);
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const closePopup = (e) => {
    e.preventDefault();
    $(".viewMemberPop").fadeOut(100);
    setPopup(false);
    setOtherSubmit(false);
    setStep(1);
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };

  useEffect(() => {
    if (last === true && otherSubmit) {
      editdata(memberFormValue);
      if (closeChecked === true) {
        setLast(false);
        $(".viewMemberPop").fadeOut(100);
        setViewPop(false);
      }
      setOtherSubmit(false);
    }
  }, [last]);

  const formSubmit = (e) => {
    setLast(true);
    e.preventDefault();
    setOtherSubmit(true);
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <section className="content-section">
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 p-2 Search">
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>

                <div className="col-lg-5 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                  <button
                    className="btn btn-cmpy btn-sm"
                    onClick={handleAddProfession}
                  >
                    {" "}
                    Add Profession +
                  </button>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <ProfessionTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

      <div className="col-lg-12 Member--Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <ProfessionPopup
        professionTrigger={popup2}
        setProfessionTrigger={setPopup2}
      />
    </>
  );
}

export default Profession;
