import React, { useContext, useEffect, useState } from "react";
import "../../css/Complain.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import NoticeContext from "../contextFolder/NoticeContextFolder/NoticeContext";
import Spinner from "../Spinner/Spinner";
import NoticePopUp from "./NoticePopUp";
// import AddNewpopup from './AddNewpopup';
import NotificationTable from "./NotificationTable";
// import ComplainTable from './ComplainTable';
import SidebarContext from "../sidebarcontext/SidebarContext";

function Notification() {
  const { mode, popup, setPopup, mainBg, mainContent } =
    useContext(SidebarContext);
  const memcontext = useContext(MemberContext);
  const { memberDrop, setDef } = memcontext;

  const context = useContext(NoticeContext);
  const {
    setNoticeFormValue,
    noticevalue,
    loading,
    setLoading,
    noticeList,
    chooseMember,
    setChooseMember,
    setChooseOption,
    chooseOption,
  } = context;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseOption(value);
    setChooseMember("");
  };

  const handleChanges = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseMember(value);
  };

  const [searchTerm, setSearchTerm] = useState("");
  // const [filtering, setFilter] = useState("");
  // console.log(filtering)

  // useEffect(() => {
  //   // console.log(memberList)

  //   if (chooseMember) {
  //     if (noticeList) {
  //       const result = noticeList.filter((newData) => {
  //         const { NotFor } = newData
  //         return NotFor.includes(chooseMember)
  //       })
  //       setFilter(result)
  //     }

  //   } else {
  //     setFilter(noticeList)
  //   }
  // }, [filtering, chooseMember])

  const handleAddNotification = (e) => {
    e.preventDefault();
    setPopup(true);
    setNoticeFormValue(noticevalue);
    setDef(Math.random());
  };

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Notification
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>
        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-cmpy btn-sm"
                  style={{ marginRight: "-11px" }}
                  onClick={handleAddNotification}
                >
                  {" "}
                  Add Notification +
                </button>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div
                  className="col-lg-2  col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
              
                   <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    Select Member
                  </label>
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1"
                    value={chooseOption}
                    onChange={handleChange}
                  >
                    <option selected disabled value="" style={{ fontSize: "11px" }}>
                      Select Member
                    </option>
                     <option selected value="0">All</option> 
                     {memberDrop.map((item) => (
                      <option key={item.UserID} value={item.UserID}>
                        {item.FullName}
                      </option>
                    ))}
                  </select> 
                   <i class="fas fa-angle-down  accountarrowposition text-dark"></i> 

       
                  {/* <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1"
                    value={chooseOption}
                    onChange={handleChange}
                  >
                    <option
                      value=""
                      disabled
                      selected
                      style={{ fontSize: "11px" }}
                    >
                      Select Option
                    </option>
                    <option value="-1">All</option>
                    <option value="member">Member</option>
                  </select>
                  <i class="fas fa-angle-down  notificationpositon text-dark"></i> */}
                </div>

                {chooseOption === "member" && (
                  <div
                    className="col-lg-2 col-md-3 col-sm-2 "
                    style={{ padding: "5px" }}
                  >
                    {/* <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseMember}
                      onChange={handleChanges}
                      className="form-control form-control-sm mb-1"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        Select Option
                      </option>
                      {memberList.map((item) => (
                        <option key={item.MemID} value={item.MemID}>
                          {item.FullName}
                        </option>
                      ))}
                    </select>
                    <i class="fas fa-angle-down  notificationpositon text-dark"></i> */}
                  </div>
                )}
                <div
                  className={
                    chooseOption === "member"
                      ? " col-lg-2 offset-lg-6 offset-md-3 offset-sm-0 col-md-3 col-sm-3 align-self-end Search "
                      : "col-lg-2 offset-lg-8 offset-md-6 offset-sm-7 col-md-3 col-sm-3 align-self-end Search"
                  }
                  style={{ padding: "5px" }}
                >
                  <div className="dashboard-search-field justify-content-end">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="">
                <div className="col-lg-12 p-1">
                  <NotificationTable searchTerm={searchTerm} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <NoticePopUp noticeTrigger={popup} setNoticeTrigger={setPopup} />
    </>
  );
}

export default Notification;
