import React, { useContext, useState, useEffect } from "react";
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import SliderContext from "../contextFolder/SliderContextFolder/SliderContext";
import $ from "jquery";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function SliderForm(props) {
  // const { setPopup,popup } = useContext(SidebarContext);

  const context = useContext(SliderContext);

  const {
    sliderFormError,
    setSliderFormError,
    isSubmit,
    setIsSubmit,
    slidervalue,
    sliderFormValue,
    setSliderFormValue,
    typeFile,
    setTypeFile,
    image,
    setImage,
    isUploaded,
    setIsUploaded,
    closeChecked,
    setCloseChecked,
    pushNotice,
    setPushNotice,
    fetchdata,
    setPopup,popup
  } = context;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setSliderFormValue({ ...sliderFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };


  const formSubmit = (e) => {
    // console.log(sliderFormValue);
    // console.log(image);
    e.preventDefault();
    setSliderFormError(validate(sliderFormValue));
    setIsSubmit(true);
    
  };




  const closePopup = (e) => {
    e.preventDefault();
    setPopup(false);
    $(".complainTopicPop").fadeOut(100);
    setSliderFormValue(slidervalue);
    setSliderFormError({});
    setImage("");
    setIsUploaded(false);
    setIsSubmit(false);
    setCloseChecked(false);
  };

  const validate = (values) => {
    const errors = {};
    // if (!values.order) {
    //   errors.order = "Required";
    // }
    if (!values.title) {
      errors.title = "Required";
    }

    return errors;
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="title">
                Title <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={sliderFormValue.title}
                onChange={handleChange}
                name="title"
                className="form-control form-control-sm mb-1"
                id="title"
              />
              <p className="errormsg ">{sliderFormError.title}</p>
            </div>

            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="redurl">
                Redirection Url 
              </label>
              <input
                type="text"
                value={sliderFormValue.redurl}
                onChange={handleChange}
                name="redurl"
                className="form-control form-control-sm mb-1"
                id="redurl"
              />
              <p className="errormsg ">{sliderFormError.redurl}</p>
            </div>


            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="imgorder">
                Order<sup className="sup-col">*</sup>
              </label>
              <select
                id="imgorder"
                name="imgorder"
                onChange={handleChange}
                value={sliderFormValue.imgorder}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Order Note
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {sliderFormError.imgorder}
              </p>
            </div>
          </div>

          <div className="form-group  ">
            <div className="form-label" htmlFor="text">
              Upload Image
            </div>
            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          {/* <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div> */}

          {/* <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div> */}
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
            checked={closeChecked}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
