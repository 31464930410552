import React, { useContext, useState, useEffect, useRef } from "react";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/imagePreview.css";
import "../../css/Member.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import StreetPopup from "../ProfessionPage/StreetPopup";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";

export default function Temporary(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { setPopup, streetPopup, setStreetPopup } = useContext(SidebarContext);
  // const { streetDropdown } = useContext(ProfessionContext);
  const context = useContext(MemberContext);
  const {
    provinceData,
    tdistrictData,
    getTProvince,
    getTDistrict,
    tmunicipalityData,
    reload,
    setReload,
    setStep,
    memberFormValue,
    setMemberFormValue,
    temporaryIsSubmit,
    setTemporaryIsSubmit,
    temporaryFormError,
    setTemporaryFormError,
    setMemberFormErrors,
    setIsSubmit,
    setPermanentFormError,
    setPermanentIsSubmit,
    setFinalSubmit,
    membervalue,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setMemberFormValue(membervalue);
    setMemberFormErrors({});
    setIsSubmit(false);
    setPermanentFormError({});
    setPermanentIsSubmit(false);
    setTemporaryFormError({});
    setTemporaryIsSubmit(false);
    setFinalSubmit(false);
    setStep(1);
    setMemberFormValue(membervalue);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setMemberFormValue({ ...memberFormValue, [name]: value });
  };

  // const handleStreetPopup = (e) => {
  //   e.preventDefault();
  //   setStreetPopup(true);
  //   $(".streetTopicPop").fadeIn(100);
  //   // setProfessionFormValue(professionvalue);
  // };
  const formNext = (e) => {
    e.preventDefault();
    setTemporaryFormError(validate(memberFormValue));
    setTemporaryIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(temporaryFormError).length === 0 && temporaryIsSubmit) {
      // fetchdata(memberFormValue)
      setStep(4);
    }
    setTemporaryIsSubmit(false);
  }, [temporaryFormError]);
  const PreviousPage = () => {
    setStep(2);
  };

  const validate = (values) => {
    const errors = {};
    // if (!values.taddress) {
    //   errors.taddress = "Required";
    //   // inputRef5.current.focus();
    // }
    if (!values.tward) {
      errors.tward = "Required";
      inputRef4.current.focus();
    }
    if (!values.tmunicipality) {
      errors.tmunicipality = "Required";
      inputRef3.current.focus();
    }
    if (!values.tdistrict) {
      errors.tdistrict = "Required";
      inputRef2.current.focus();
    }

    if (!values.tprovience) {
      errors.tprovience = "Required";
      inputRef1.current.focus();
    }

    // if (!values.taddress) {
    //     errors.taddress = "Required";
    // }
    return errors;
  };

  useEffect(() => {
    if (memberFormValue.tprovience != "") {
      getTProvince();
    }
    if (memberFormValue.tdistrict != "") {
      getTDistrict();
    }
  }, [memberFormValue]);

  return (
    <>
      <div className="container-fluid basicform">
        <div className="form-row">
          <div className="form-group col-md-6 pos">
            <label className="form-label" htmlFor="tprovience">
              Province<sup className="sup-col">*</sup>
            </label>

            <select
              id="tprovience"
              name="tprovience"
              onChange={handleChange}
              value={memberFormValue.tprovience}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef1}
              required
            >
              <option value="" selected disabled>
                Select Province
              </option>
              {provinceData.map((item) => {
                return (
                  <option key={item.ProvinceID} value={item.ProvinceID}>
                    {item.Province}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{temporaryFormError.tprovience}</p>
          </div>

          {/* <div className="form-group col-md-4 pos">
            <label className="form-label" htmlFor="tzone">
              Zone<sup className="sup-col">*</sup>
            </label>
            <select
              id="tzone"
              name="tzone"
              onChange={handleChange}
              value={memberFormValue.tzone}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef2}
              required
            >
              <option value="none" selected>
                Select Zone
              </option>
              <option value="1">Bagmati</option>
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{temporaryFormError.tzone}</p>
          </div> */}

          <div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="tdistrict">
              District<sup className="sup-col">*</sup>
            </label>
            <select
              id="tdistrict"
              name="tdistrict"
              onChange={handleChange}
              value={memberFormValue.tdistrict}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef2}
              required
            >
              <option value="" selected disabled>
                Select District
              </option>
              {tdistrictData.map((item) => {
                return (
                  <option key={item.DistrictID} value={item.DistrictID}>
                    {item.District}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{temporaryFormError.tdistrict}</p>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6 pos">
            <label className="form-label" htmlFor="tmunicipality">
              Municipality/VDC<sup className="sup-col">*</sup>
            </label>

            <select
              id="tmunicipality"
              name="tmunicipality"
              onChange={handleChange}
              value={memberFormValue.tmunicipality}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef3}
              required
            >
              <option value="" selected disabled>
                Select Municipality
              </option>
              {tmunicipalityData.map((item) => {
                return (
                  <option key={item.MunicipalityID} value={item.MunicipalityID}>
                    {item.Municipality}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{temporaryFormError.tmunicipality}</p>
          </div>

          <div className="form-group col-md-6 pos">
            <label className="form-label" htmlFor="tward">
              Ward No.<sup className="sup-col">*</sup>
            </label>
            <select
              id="tward"
              name="tward"
              onChange={handleChange}
              value={memberFormValue.tward}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef4}
              required
            >
              <option value="" selected disabled>
                Select Ward
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{temporaryFormError.tward}</p>
          </div>

          {/* <div className="form-group col-md-4  ">
            <label className="form-label" htmlFor="taddress">
              Address
            </label>
            <input
              type="text"
              name="taddress"
              onChange={handleChange}
              value={memberFormValue.taddress}
              className="form-control form-control-sm mb-1"
              id="taddress"
            />
            <p className="errormsg ">{temporaryFormError.taddress}</p>
          </div> */}
        </div>

        <div className="form-row">
          {/* <div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="taddress">
              Street<sup className="sup-col">*</sup>
            </label>
            <div className="uk-flex">
              <select
                style={{ fontSize: "12px" }}
                name="taddress"
                onChange={handleChange}
                value={memberFormValue.taddress}
                className="form-control form-control-sm mb-1"
                id="taddress"
                ref={inputRef5}
                required
              >
                <option value="" selected disabled>
                  Select Street
                </option>
                {streetDropdown.map((item, i) => (
                  <option value={item.ID}>{item.Name}</option>
                ))}
              </select>
              <span>
                <button
                  onClick={handleStreetPopup}
                  type="button"
                  className="btn btn-primary"
                >
                  +
                </button>
              </span>
            </div>
            <p className="errormsg ">{temporaryFormError.taddress}</p>
          </div> */}

<div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              // ref={inputRef5}
              name="address"
              onChange={handleChange}
              value={memberFormValue.taddress}
              className="form-control form-control-sm mb-1"
              id="address"
            />
            {/* <p className="errormsg ">{basicFormErrors.address}</p> */}
          </div>

          <div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="thouse">
              House/Block No.
            </label>
            <input
              type="text"
              name="thouse"
              onChange={handleChange}
              value={memberFormValue.thouse}
              className="form-control form-control-sm mb-1"
              id="thouse"
            />
            {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
          </div>
        </div>

        {/* <div className="form-row">
          <div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="text">
              Latitude
            </label>
            <input
              type="text"
              name="tlatitude"
              className="form-control form-control-sm mb-1"
              id="tlatitude"
              onChange={handleChange}
              value={memberFormValue.tlatitude}
            />
          </div>
          <div className="form-group col-md-6  ">
            <label className="form-label" htmlFor="text">
              Longitude
            </label>
            <input
              type="text"
              name="tlongitude"
              onChange={handleChange}
              value={memberFormValue.tlongitude}
              className="form-control form-control-sm mb-1"
              id="tlongitude"
            />
          </div>
          <div className="form-group col-md-1">

                        <div className="add-title-button">
                            <label className="form-label" htmlFor="button">Add </label>
                            <button className='btn btn-sm btn-cmpy' style={{
                                background: "0079bf", width: "50px"
                            }} ><i class="fa fa-map-marker" aria-hidden="true"></i></button>
                        </div>
                    </div>
        </div> */}
      </div>

      {/* <div className="col-lg-12 notificationFormFooterpermanent ">


                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "red" }} onClick={PreviousPage} >Back</button>
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formNext} >Next</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div> */}

      <div className="col-lg-12 Member-Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "red" }}
            onClick={PreviousPage}
          >
            Back
          </button>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formNext}
          >
            Next
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
      <StreetPopup streetPopup={streetPopup} setStreetPopup={setStreetPopup} />
    </>
  );
}
