import React, { useContext, useEffect, useState } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Complain.css";
import Profession from "../ProfessionPage/Profession";
import PersonalEdit from "./PersonalEdit";
import TemporaryEdit from "./TemporaryEdit";
import PermanentEdit from "./PermanentEdit";
import OtherEdit from "./OtherEdit";
import FamilyMain from "../FamilyPage/FamilyMain";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";

export default function ViewMemberPop(props) {
  const { height } = UsewindowDimension();
  const { setMemberFormValue, membervalue, activeTab, setActiveTab } =
    useContext(MemberContext);
  // const { setFamilyList } = useContext(FamilyContext);
  // const { setProList } = useContext(ProfessionContext);

  const closePopup = (e) => {
    e.preventDefault();
    props.setMemberTrigger(false);
    $(".viewMemberPop").fadeOut(100);
    // setFamilyList("");
    // setProList("");
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };

  useEffect(() => {
    if (props.memberTrigger) {
      $(".viewMemberPop").fadeIn(100);
    }
  }, [props.memberTrigger]);

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
      tab5: false,
      tab6: false,
    });
  };
  const handleTab5 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: true,
      tab6: false,
    });
  };
  const handleTab6 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: true,
    });
  };

  return (
    <div className="popUP viewMemberPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "insidePopup ip500" : "insidePopup"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Edit Member </div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>
        <nav>
          <ul>
            <li
              className={activeTab.tab1 === true ? "active" : ""}
              onClick={handleTab1}
            >
              <i class="fa fa-user icon"></i>Personal{" "}
            </li>
            <li
              className={activeTab.tab2 === true ? "active" : ""}
              onClick={handleTab2}
            >
              <i className="fas fa-home icon"></i>Temporary{" "}
            </li>
            <li
              className={activeTab.tab3 === true ? "active" : ""}
              onClick={handleTab3}
            >
              <i className="fas fa-home icon"></i>Permanent{" "}
            </li>
            {/* <li
              className={activeTab.tab4 === true ? "active" : ""}
              onClick={handleTab4}
            >
              <i className="fas fa-sliders icon"></i>Login Info{" "}
            </li> */}
            {/* <li
              className={activeTab.tab5 === true ? "active" : ""}
              onClick={handleTab5}
            >
              <i className="fas fa-users icon"></i>Family{" "}
            </li>
            <li
              className={activeTab.tab6 === true ? "active" : ""}
              onClick={handleTab6}
            >
              <i className="fas fa-briefcase icon"></i>Profession{" "}
            </li> */}
          </ul>
        </nav>

        <div className="personal-outlet">
          {activeTab.tab1 && <PersonalEdit />}
          {activeTab.tab2 && <TemporaryEdit />}
          {activeTab.tab3 && <PermanentEdit />}
          {activeTab.tab4 && <OtherEdit />}
          {activeTab.tab5 && <FamilyMain />}
          {activeTab.tab6 && <Profession />}
        </div>
      </div>
    </div>
  );
}
