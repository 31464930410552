import React, { useContext, useState, useEffect } from "react";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import $ from "jquery";
import NoticeContext from "../contextFolder/NoticeContextFolder/NoticeContext";
// import Spinner from '../component/Spinner/Spinner';
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import Select from "react-select";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import { Fetchdata } from "../hooks/getData";
import DashboardContext from "../contextFolder/DashboardContextFolder/DashboardContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function NotificationForm(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;

  const memcontext = useContext(MemberContext);
  const { memberDrop, def } = memcontext;

  const { setMsg, urll, popup, setPopup } = useContext(SidebarContext);

  const context = useContext(NoticeContext);

  const {
    noticevalue,
    noticeFormValue,
    setNoticeFormValue,
    noticeFormError,
    setNoticeFormError,
    noticeSubmit,
    setNoticeSubmit,
    image,
    setImage,
    isUploaded,
    setIsUploaded,
    typeFile,
    newValue,
    setNewValue,
    pushNotice,
    setPushNotice,
    NoticePushAll,
    NoticePushindividual,
    setTypeFile,
    reload,
    setReload,
    setCloseChecked,
    closeChecked,
    noticeTitleList,
    easyList,
    chooseMember,
    setChooseMember,
    chooseOption
  } = context;

  const { setShowNotify } = useContext(DashboardContext);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setNoticeFormValue({ ...noticeFormValue, [name]: value });
  };
  //console.log(noticeFormValue);

  const handleDate = ({ bsDate }) => {
    setNoticeFormValue({ ...noticeFormValue, date: bsDate });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  // API to submit post data in notification form
  const obj = {
    tole_id: ToleID,
    user_id:noticeFormValue.people === "-1" ? noticeFormValue.people : newValue,
    title: noticeFormValue.title !== "" ? noticeFormValue.title : "-",
    description:noticeFormValue.description !== "" ? noticeFormValue.description : "-",
    date: noticeFormValue.date !== "" ? noticeFormValue.date : "-",
    image: image !== "" ? image : "-",
    ac_btn_name:noticeFormValue.actionBtn !== "" ? noticeFormValue.actionBtn : "-",
    ac_btn_url:noticeFormValue.actionUrl !== "" ? noticeFormValue.actionUrl : "-",
    status: 1,
    members:noticeFormValue.member
   
  };

  const obj1 = Object.values(obj);
  const obj2 = obj1.join("##");
  // console.log(obj2)

  // const fetchdata = async () => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "12",
  //     tblField:
  //       "tole_id,user_id,title,description,date,image,ac_btn_name,ac_btn_url,status",
  //     tblValues: obj2,
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y",
  //   };

  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   console.log("encplogin", dts);
  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();

  //   if (tole.STATUS_CODE === "0") {
  //     noticeTitleList();
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   }
  // };
  const currentDate = new Date();

  // Optionally format the date (e.g., 'YYYY-MM-DD')
  const formattedDate = currentDate.toISOString().split('T')[0];
  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "i",
      UserID: UserID,
      // MemID: chooseOption === "member" ? chooseMember : "1",
      MemID:obj.members,
      Title: obj.title,
      Description: obj.description,
      NotImage: typeof image === 'string' && image.includes(",") ? image.split(",")[1] : "",
      NotifyUser: pushNotice ? "Y" : "N",
      NotDate: formattedDate,
      FetchURL: `${urll}/admin/notification`,
      Type: "POST",
      
    };
    
    
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        noticeTitleList();
        setShowNotify(true);
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    
    // Perform validation and set errors
    const errors = validate(noticeFormValue);
    setNoticeFormError(errors);
    setNoticeSubmit(true);
    
  
    // Log errors for debugging
    console.log(errors);
  
    // Check if there are any validation errors
    // if (Object.keys(errors).length === 0) {
    //   // No errors, so we can proceed with submission
    //   setNoticeSubmit(true);
    //   setPopup(false);
      
    //   // Optionally, you might also want to call the function that handles data submission here
    //   // fetchdata();
    // } else {
    //   // There are errors, do not submit the form or close the popup
    //   setNoticeSubmit(false);
      
    //   // Optionally, you might show an alert or a message to the user here
    //   console.log("Form submission prevented due to validation errors.");
    // }
  };
  

  useEffect(() => {
    if (Object.keys(noticeFormError).length === 0 && noticeSubmit) {
      fetchdata(noticeFormValue, image);
      // if (pushNotice === true) {
      //   if (noticeFormValue.people === "0") {
      //     NoticePushAll();
      //   } else {
      //     //console.log(noticeFormValue.people, newValue);
      //     NoticePushindividual();
      //   }
      // }

      if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setPopup(false);
      }

      setReload(!reload);
      // setNewValue("member");
      setNoticeFormValue(noticevalue);
      setNoticeSubmit(false);
      setPushNotice(false);
    }
  }, [noticeFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopup").fadeOut(100);
    setReload(!reload);
    setPopup(false);
    setNoticeFormValue(noticevalue);
    setNoticeFormError({});
    setNoticeSubmit(false);
    setIsUploaded(false);
    setImage(null);
    // setNewValue("member");
    setPushNotice(false);
    easyList();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required";
    }

    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.member) {
      errors.member = "Required";
    }

    return errors;
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  //for getting member id in dropdown
  // const handleMember = (e) => {
  //   const target = e.target;
  //   const value = target.value;
  //   setChooseMember(value);
  // };

  // console.log(newValue)
  return (
    <>
      <div className="container-fluid basicform">
        <form>

       
        
            <div className="form-group">
              <label className="form-label" htmlFor="category">
                Select Member<sup className="sup-col">*</sup>
              </label>
              <select
                id="slug-title"
                name="member"
                value={noticeFormValue.member}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                onClick={(e) => {
                  setNewValue(e.target.value);
                  //console.log(e.target.value);
                }}
              >
                <option defaultValue="Select Event Title">Select Member</option>
                <option value="0">All</option>
                {memberDrop.map((item) => (
                  
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{noticeFormError.member}</p>
            </div>
       
          



          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="title">
                Title<sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={noticeFormValue.title}
                onChange={handleChange}
                name="title"
                className="form-control form-control-sm mb-1"
                id="title"
              />
              <p className="errormsg ">{noticeFormError.title}</p>
            </div>

          </div>

          <div className="form-group ">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label"
              placeholder="description"
            >
              Description<sup className="sup-col">*</sup>
            </label>
            <textarea
              className="form-control"
              name="description"
              value={noticeFormValue.description}
              onChange={handleChange}
              id="exampleFormControlTextarea1"
              rows="3"
              style={{ fontSize: "12px" }}
            ></textarea>
            <p className="errormsg ">{noticeFormError.description}</p>
          </div>

         
          <div className="form-group  ">
            <div className="form-label" htmlFor="text">
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                      {/* <p style={{ color: "#444" }}>Click to upload image</p> */}
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 notificationFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

 {/* <div className="form-group col-md-6">
              <label className="form-label" htmlFor="inputDate">
                Date
              </label>

              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={noticeFormValue.date}
                onChange={handleDate}
                hideDefaultValue={true}
                placeholder="mm/dd/yyyy"
                key={def}
              />
            </div> */}


             {/* <div className="form-group">
            <div>
              <label className="form-label" htmlFor="purpose">
                Select Purpose
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="people"
                id="-1"
                value="-1"
                onChange={handleChange}
                onClick={() => setNewValue("member")}
                checked={noticeFormValue.people === "-1"}
              />
              <label
                className="label-radio"
                htmlFor="-1"
                style={{ marginRight: "10px" }}
              >
                All
              </label>
              <input
                type="radio"
                name="people"
                id="member"
                onChange={handleChange}
                value={newValue}
                checked={noticeFormValue.people === "member"}
              />
              <label className="label-radio" htmlFor="member">
                Member
              </label>
            </div>
          </div> */}


           {/* <div className="form-row">
            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="text">
                Action Button
              </label>

              <select
                id="gender"
                name="actionBtn"
                onChange={handleChange}
                value={noticeFormValue.actionBtn}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Action Button
                </option>
                <option value="join">Join</option>
                <option value="submit"> Submit</option>
                <option value="register"> Register</option>
                <option value="website"> Visit Website</option>
                <option value="more"> Visit More</option>
              </select>
            </div>

            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="text">
                Action Url
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={noticeFormValue.actionUrl}
                id="text"
                name="actionUrl"
              />
            </div>
          </div> */}


          // {noticeFormValue.people === "member" && (
          //   <div className="form-group">
          //     <label className="form-label" htmlFor="category">
          //       Select Member<sup className="sup-col">*</sup>
          //     </label>
          //     <select
          //       id="slug-title"
          //       name="member"
          //       value={noticeFormValue.member}
          //       onChange={handleChange}
          //       className="form-control form-control-sm mb-1"
          //       style={{ fontSize: "12px" }}
          //       onClick={(e) => {
          //         setNewValue(e.target.value);
          //         console.log(e.target.value);
          //       }}
          //     >
          //       <option defaultValue="Select Event Title">Select Member</option>
          //       {memberDrop.map((item) => (
          //         <option key={item.MemberID} value={item.MemberID}>
          //           {item.MemberName}
          //         </option>
          //       ))}
          //     </select>
          //     <p className="errormsg ">{noticeFormError.member}</p>
          //   </div>
          // )}
