import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/Management.css";
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";

import ManagementContext from "../contextFolder/ManagementContextFolder/ManagementContext";
import ManagementTypePop from "./ManagementTypePop";
import "../../css/imagePreview.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

export default function ManagementForm(props) {
  const { setMsg, urll,  popup,
    setPopup, } = useContext(SidebarContext);

  const apisignature = process.env.REACT_APP_SIGNATURE;

  const context = useContext(ManagementContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID;

  const {
  
    image,
    setImage,
    managementFormValue,
    setManagementFormValue,
    managementvalue,
    managementFormError,
    setManagementFormError,
    isSubmit,
    setIsSubmit,
    managementTypePop,
    setManagementTypePop,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    mgmtTitle,
    managemenList,
    setPushNotice,
    pushNotice,
    reload,
    setReload,
    setCloseChecked,
    closeChecked,
    ManagementPush,
    fetchdata,
    mgmtDropDown,
  } = context;

  const { memberType } = useContext(MemberContext);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setManagementFormValue({ ...managementFormValue, [name]: value });
  };
  // console.log(managementFormValue);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  // API to sumit Management form Data post Method
  const obj = {
    tole_id: ChhimekiID,
    full_name: managementFormValue.name,
    management_type_id: managementFormValue.position,
    image: image,
    contact: managementFormValue.contact,
    status: 1,
  };

  const obj1 = Object.values(obj);
  const obj2 = obj1.join("##");

  // const fetchdata = async () => {
  // JSON.stringify(dataToSend)

  //   const dataForm = {
  //     ChhimekiID: ChhimekiID,
  //     userID: UserID.toString(),
  //     tblOpt: "27",
  //     tblField: "tole_id,full_name,management_type_id,image,contact,status",
  //     tblValues: obj2,
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   // console.log("encplogin", encrypted.toString())
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   console.log(tole);

  //   if (tole.STATUS_CODE === "0") {
  //     managemenList();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: "Title Alredy Exists. Please choose another Name.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const formSubmit = (e) => {
    // console.log("here")
    e.preventDefault();
    setManagementFormError(validate(managementFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(managementFormError).length === 0 && isSubmit) {
      fetchdata(managementFormValue);
      if (pushNotice === true) {
        ManagementPush();
      }
      if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setPopup(false);
      }
      setReload(!reload);
      setManagementFormValue(managementvalue);
      setIsSubmit(false);
      setPushNotice(false);
    }
  }, [managementFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    setReload(!reload);
    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setManagementFormValue(managementvalue);
    setManagementFormError({});
    setIsSubmit(false);
    setImage(null);
    setIsUploaded(false);
    setPushNotice(false);
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    if (!values.position) {
      errors.position = "Required";
    }
    if (!values.user) {
      errors.user = "Required";
    }

    return errors;
  };

  // for Event Title Popup

  const handleEventTitle = (e) => {
    e.preventDefault();
    setManagementTypePop(true);
  };

  // function handleImageChange(e) {
  //   if (e.target.files && e.target.files[0]) {
  //     setTypeFile(e.target.files[0].type);
  //     let reader = new FileReader();

  //     reader.onload = function (e) {
  //       setImage(e.target.result);
  //       setIsUploaded(true);
  //     };

  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // }

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div
              className="form-group col-md-11"
              style={{ marginBottom: "0px" }}
            >
              <label className="form-label" htmlFor="category">
                Select Position<sup className="sup-col">*</sup>
              </label>
              <select
                id="position"
                name="position"
                value={managementFormValue.position}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option selected>Select Option</option>

                {mgmtDropDown.map((item) => (
                  <option value={item.TypeID}>{item.Type}</option>
                ))}
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{managementFormError.position}</p>
            </div>

            <div className="form-group col-md-1">
              <div className="add-title-button">
                {/* <label className="form-label" htmlFor="button">Add </label> */}
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    background: "0079bf",
                    width: "50px",
                  }}
                  onClick={handleEventTitle}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="form-group  ">
            <label className="form-label" htmlFor="category">
              Select User<sup className="sup-col">*</sup>
            </label>
            <select
              id="user"
              name="user"
              value={managementFormValue.user}
              onChange={handleChange}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option selected>Select Option</option>

              {memberType.map((item) => (
                <option value={item.MemberID}>{item.MemberName}</option>
              ))}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrops"
            ></i>
            <p className="errormsg ">{managementFormError.user}</p>
          </div>
        </form>
      </div>

      <div className="col-lg-12 managementFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <ManagementTypePop
        trigger1={managementTypePop}
        setTriggernew1={setManagementTypePop}
      />
    </>
  );
}
