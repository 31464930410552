import React, { useState, useContext, useEffect, useRef } from 'react'
import UsewindowDimension from '../hooks/UsewindowDimension'
import $ from "jquery";
import '../../css/Notification.css';
import AccountForm from './AccountForm';
import AccountContext from '../contextFolder/AccountContextFolder/AccountContext';
import SidebarContext from '../sidebarcontext/SidebarContext';


export default function AccountPopUp(props) {
    const { height } = UsewindowDimension();

    const context = useContext(AccountContext)

    const {  setAccountFormValue, accountvalue, setAccountFormError, setAccountSubmit, setPushNotice } = context;

    const {setPopup}=useContext(SidebarContext)



    const myRef = useRef(null)


    const closePopup = (e) => {
        e.preventDefault();
        props.setAccountTrigger(false)
        $(".displayPopup").fadeOut(100);
        setPopup(false)
        setAccountFormValue(accountvalue);
        setAccountFormError({});
        setAccountSubmit(false);
        setPushNotice(false);
        myRef.current.scrollIntoView()
    }

    useEffect(() => {

        if (props.accountTrigger) {
            $('.displayPopup').fadeIn(100);
        }
    }, [props.accountTrigger])



    return (

        <div className="noticePopUp displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"} ref={myRef} >
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Create Account</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>



                <div className="account-outlet">
                    <AccountForm />

                </div>

            </div>
        </div>
    )

}