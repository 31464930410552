import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import '../../css/Notification.css';
import NoticeContext from '../contextFolder/NoticeContextFolder/NoticeContext';
import ChhimekiContext from '../contextFolder/ChhimekiContextFolder/ChhimekiContext';
import ChhimekiForm from './ChhimekiForm';
import ChhimekiEdit from './ChhimekiEdit';



export default function ChhimekiPopUpEdit(props) {
    const { height } = UsewindowDimension();

    const context = useContext(ChhimekiContext)

    const {

        chhimekiFormValue, setChhimekiFormValue, chhimekivalue, chhimekiFormError, setChhimekiFormError, setIsEditSubmit, loading, setLoading, editPop, setEditPop,


    } = context;



    const closePopup = (e) => {
        e.preventDefault();
        setIsEditSubmit(false);
        setChhimekiFormError({});
        props.setChhimekTrigger(false)
        $('.chhimekiPops').fadeOut(100);
    }

    useEffect(() => {

        if (props.chhimekTrigger) {
            $('.chhimekiPops').fadeIn(100);
        }
    }, [props.chhimekTrigger])



    return (

        <div className="chhPopUp chhimekiPops container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >

            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"}>
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Edit Chhimkei List</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>




                <div className="notice-outlet">
                    <ChhimekiEdit />

                </div>


            </div>


        </div>
    )

}