import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ProfessionForm from './ProfessionForm';
import ProfessionContext from '../contextFolder/ProfessionContextFolder/ProfessionContext';
import ProfessionEdit from './ProfessionEdit';




export default function ProfessionEditPop(props) {
    const context = useContext(ProfessionContext)

    const { professionvalue, setProfessionFormValue, setProfessionFormError, setEditIsSubmit } = context;
    const { height } = UsewindowDimension();






    const closePopup = (e) => {
        e.preventDefault();
        props.setProfessionTrigger(false)
        $('.displayPopups').fadeOut(100);
        setProfessionFormError({})
        setEditIsSubmit(false)
    }

    useEffect(() => {

        if (props.professionTrigger) {
            $('.displayPopups').fadeIn(100);
        }
    }, [props.professionTrigger])



    return (

        <div className="complainPopUp displayPopups container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >

            <div className={height < 500 ? "inside--prof ip500" : "inside--prof"}>
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Edit Profession</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>



                <div className="notice-outlet">
                    <ProfessionEdit />

                </div>


            </div>


        </div>
    )

}