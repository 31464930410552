import React, { useContext, useState, useEffect } from "react";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/imagePreview.css";
import "../../css/Member.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function OtherEdit(props) {
  const { setPopup } = useContext(SidebarContext);
  const context = useContext(MemberContext);
  const {
    setStep,
    setViewPop,
    memberFormValue,
    setMemberFormValue,
    membervalue,
    fetchdata,
    otherSubmit,
    setOtherSubmit,
    editdata,
    closeChecked,
    setCloseChecked,
    image,
    setActiveTab,
  } = context;
  const [last, setLast] = useState(false);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const closePopup = (e) => {
    e.preventDefault();
    $(".viewMemberPop").fadeOut(100);
    setPopup(false);
    setOtherSubmit(false);
    setStep(1);
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };

  const formSubmit = (e) => {
    setLast(true);
    e.preventDefault();
    setOtherSubmit(true);
    
  };

  useEffect(() => {
    if (last === true && otherSubmit) {
      editdata(memberFormValue, image);
      if (closeChecked === true) {
        setLast(false);
        $(".viewMemberPop").fadeOut(100);
        setViewPop(false);
      }
      setOtherSubmit(false);
    }
  }, [last]);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setMemberFormValue({ ...memberFormValue, [name]: value });
  };

  const handleDate1 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, cDate: bsDate });
  };
  const handleDate3 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, pDate: bsDate });
  };
  const handleDate5 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, vDate: bsDate });
  };
  const handleDate7 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, lDate: bsDate });
  };
  const handleDate9 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, eDate: bsDate });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="citizenship">
                Citizenship No.
              </label>

              <input
                type="text"
                name="citizenship"
                onChange={handleChange}
                value={memberFormValue.citizenship}
                className="form-control form-control-sm mb-1"
                id="citizenship"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="cIssued">
                Issued From
              </label>
              <input
                type="text"
                name="cIssued"
                onChange={handleChange}
                value={memberFormValue.cIssued}
                className="form-control form-control-sm mb-1"
                id="citizenship"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="cDate">
                Date of Issue
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                theme="default"
                language="en"
                values={memberFormValue.cDate}
                onChange={handleDate1}
              />
              <i
                class="fa fa-calendar-o other--date--picker"
                aria-hidden="true"
              ></i>
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="passport">
                Passport No.
              </label>

              <input
                type="text"
                name="passport"
                onChange={handleChange}
                value={memberFormValue.passport}
                className="form-control form-control-sm mb-1"
                id="passport"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="pIssued">
                Issued From
              </label>
              <input
                type="text"
                name="pIssued"
                onChange={handleChange}
                value={memberFormValue.pIssued}
                className="form-control form-control-sm mb-1"
                id="citizenship"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="pDate">
                Date of Issue
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                theme="default"
                language="en"
                values={memberFormValue.pDate}
                onChange={handleDate3}
              />
              <i
                class="fa fa-calendar-o other--date--picker"
                aria-hidden="true"
              ></i>
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="voter">
                Voter ID
              </label>

              <input
                type="text"
                name="voter"
                onChange={handleChange}
                value={memberFormValue.voter}
                className="form-control form-control-sm mb-1"
                id="voter"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="vIssued">
                Issued From
              </label>
              <input
                type="text"
                name="vIssued"
                onChange={handleChange}
                value={memberFormValue.vIssued}
                className="form-control form-control-sm mb-1"
                id="citizenship"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="vDate">
                Date of Issue
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                theme="default"
                language="en"
                values={memberFormValue.vDate}
                onChange={handleDate5}
              />
              <i
                class="fa fa-calendar-o other--date--picker"
                aria-hidden="true"
              ></i>
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="license">
                License No.
              </label>

              <input
                type="text"
                name="license"
                onChange={handleChange}
                value={memberFormValue.license}
                className="form-control form-control-sm mb-1"
                id="license"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="lIssued">
                Issued From
              </label>
              <input
                type="text"
                name="lIssued"
                onChange={handleChange}
                value={memberFormValue.lIssued}
                className="form-control form-control-sm mb-1"
                id="citizenship"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="lDate">
                Date of Issue
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                theme="default"
                language="en"
                values={memberFormValue.lDate}
                onChange={handleDate7}
              />
              <i
                class="fa fa-calendar-o other--date--picker"
                aria-hidden="true"
              ></i>
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="electricity">
                Electricity No.
              </label>

              <input
                type="text"
                name="electricity"
                onChange={handleChange}
                value={memberFormValue.electricity}
                className="form-control form-control-sm mb-1"
                id="electricity"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="eIssued">
                Issued From
              </label>
              <input
                type="text"
                name="eIssued"
                onChange={handleChange}
                value={memberFormValue.eIssued}
                className="form-control form-control-sm mb-1"
                id="eIssued"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="eDate">
                Date of Issue
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                placeholder="YYYY/MM/DD"
                theme="default"
                language="en"
                values={memberFormValue.eDate}
                onChange={handleDate9}
              />
              <i
                class="fa fa-calendar-o other--date--picker"
                aria-hidden="true"
              ></i>
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>
          </div>
        </form>
      </div>

      <div className="col-lg-12 Member-Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
