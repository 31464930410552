import React, { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import $ from "jquery";
import ComplainContext from "../contextFolder/ComplainContextFolder/ComplainContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";

function ComplainFormPop() {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const {
    setMsg,
    URL,
    urll,
    color,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const context = useContext(ComplainContext);

  const {
    complainTitleValue,
    setComplainTitleValue,
    addComplainValue,
    pop,
    setPop,
    complaintopicError,
    setComplainTopicError,
    complainTopicSubmit,
    setComplainTopicSubmit,
    isEdit,
    setIsEdit,
    deactivateComplainCategory,
    reload,
    setReload,
    complainList,
    complainfunction,
  } = context;

  function handle(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setComplainTitleValue({ ...complainTitleValue, [name]: value });
  }

  const [searchTerm, setSearchTerm] = useState("");

  //To post complain title list

  // const fetchdata = async (dataToSend) => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "04",
  //     tblField: "tole_id,title_name,status",
  //     tblValues: hashed,
  //     validationFields: "title_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   if (tole.STATUS_CODE === "0") {
  //     complainfunction();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: tole.MESSAGE,
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "i",
      UserID: UserID,
      Topic: complainTitleValue.title,
      FetchURL: `${urll}/admin/complain-topic`,
      Type: "POST",
      
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        complainfunction();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const formSubmit = (e) => {
    setComplainTopicError(validate(complainTitleValue));
    setComplainTopicSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(complaintopicError).length === 0 && complainTopicSubmit) {
      fetchdata(complainTitleValue);
      setComplainTitleValue({ title: "" });
      setComplainTopicSubmit(false);
    }
  }, [complaintopicError]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Required";
    }
    return errors;
  };

  //Update function
  const [titleID, setTitleId] = useState(null);
  function selectComplain(datas) {
    setComplainTitleValue({
      title: datas.Topic,
    });
    setTitleId(datas.TopicID);

    setIsEdit(true);
  }

  // const updateUser = async () => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "04",
  //     tblField: "title_name",
  //     tblValues: complainTitleValue.title,
  //     tblID: titleID.toString(),
  //     validationFields: "title_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"

  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   // console.log(tole);
  //   if (tole.STATUS_CODE === "0") {
  //     complainfunction();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Edited",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: tole.MESSAGE,
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const updateUser = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "U",
      UserID: UserID,
      TopicID: titleID,
      Topic: complainTitleValue.title,
      FetchURL: `${urll}/admin/complain-topic`,
      Type: "POST",
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        complainfunction();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const changeStatus = (comID, IsActive) => {
    deactivateComplainCategory(comID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };

  if (complainList.length > 0) {
    return (
      <>
        <div className="container-fluid basicform">
          <div className="form-group " style={{ paddingTop: "10px" }}>
            <label className="form-label" htmlFor="titleName">
              Title <sup className="sup-col">*</sup>
            </label>
            <input
              type="text"
              name="title"
              onChange={handle}
              value={complainTitleValue.title}
              className="form-control form-control-sm mb-1"
              id="titleName"
            />
            <p className="errormsg ">{complaintopicError.title}</p>
          </div>

          <div className="floatyy">
            {isEdit ? (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={updateUser}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={formSubmit}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <section
          className="content-section main-content"
          style={{ margin: "3px" }}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-md-4 Search" style={{ padding: "0px" }}>
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      style={{ border: "none" }}
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-1">
                <div className="outer-wrapper">
                  <div
                    className="table-wrapper"
                    style={{
                      overflowX: "scroll",
                      overflowY: "scroll",
                      margin: "3px",
                      height: "240px",
                    }}
                  >
                    <table className="table ">
                      <thead>
                        <tr className="tableHead">
                          <td>S.N.</td>
                          <td>Topic</td>
                          <td>No of Complain</td>
                          <td className="widthOfT"> Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {complainList
                          .filter((val) => {
                            if (searchTerm === "") {
                              return val;
                            } else if (
                              val.Title.toLowerCase().includes(
                                searchTerm.toLowerCase()
                              )
                            ) {
                              return val;
                            }
                          })
                          .map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.Topic}</td>
                              <td>{item.NoOfComplain}</td>
                              {/* <td className="tc">{toShowStatus(item.Status)}</td> */}
                              <td>
                                <span
                                  className="rejectspan badge"
                                  onClick={() => {
                                    selectComplain(item);
                                  }}
                                >
                                  Edit
                                </span>{" "}
                                <span
                                  className="editspan badge"
                                  onClick={() =>
                                    changeStatus(item.TitleID, item.Status)
                                  }
                                >
                                  {checkStatus(item.Status)}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <div className="container-fluid basicform">
          <div className="form-group " style={{ paddingTop: "10px" }}>
            <input
              type="text"
              name="title"
              onChange={handle}
              value={complainTitleValue.title}
              className="form-control form-control-sm mb-1"
              placeholder="Title"
              id="titleName"
            />
            <p className="errormsg ">{complaintopicError.title}</p>
          </div>

          <div className="floatyy">
            {isEdit ? (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={updateUser}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={formSubmit}
              >
                Save
              </button>
            )}
          </div>
        </div>

        <section
          className="content-section main-content"
          style={{ margin: "3px" }}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-md-4 Search" style={{ padding: "0px" }}>
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-1">
                <div className="outer-wrapper">
                  <div
                    className="table-wrapper"
                    style={{
                      overflowX: "scroll",
                      overflowY: "scroll",
                      margin: "3px",
                      height: "260px",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr className="tableHead">
                          <td>S.N.</td>
                          <td>Topic</td>
                          <td>Status</td>
                          <td style={{ width: "220px" }}> Action</td>
                        </tr>
                      </thead>
                    </table>
                    <div className="text-center mt-4 text-dark">
                      No Data Found
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ComplainFormPop;
