import React, { useContext, useState, useEffect } from "react";

import $ from "jquery";

// import Spinner from '../component/Spinner/Spinner';
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import Select from "react-select";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import ChhimekiContext from "../contextFolder/ChhimekiContextFolder/ChhimekiContext";
import { Fetchdata } from "../hooks/getData";

export default function ChhimekiEdit(props) {
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const ChhimekiID = userinfo.ChhimekiID;
    const UserID = userinfo.UserID;

    const memcontext = useContext(MemberContext);
    const { memberList } = memcontext;

    const { setMsg, urll } = useContext(SidebarContext);

    const context = useContext(ChhimekiContext);

    const {
        chhimekiFormValue, setChhimekiFormValue, chhimekivalue, editPop, setEditPop, chhimekiFormError, setChhimekiFormError, isEditSubmit, setIsEditSubmit, loading, setLoading, image, setImage, isUploaded, setIsUploaded, typeFile, setTypeFile, closeChecked, setCloseChecked, pushNotice, setPushNotice, chmList
    } = context;

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type == "checkbox" ? target.checked : target.value;
        setChhimekiFormValue({ ...chhimekiFormValue, [name]: value });
    };
    //   console.log(noticeFormValue);

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handlePushNotification = () => {
        setPushNotice(!pushNotice)
    }




    const formSubmit = (e) => {
        // console.log("here")
        console.log(chhimekiFormValue);
        e.preventDefault();
        setChhimekiFormError(validate(chhimekiFormValue));
        setIsEditSubmit(true);

        setEditPop(false);
    };


    useEffect(() => {
        if (Object.keys(chhimekiFormError).length === 0 && isEditSubmit) {
            //   fetchdata(chhimekiFormValue);

            const dataForm = {


                ChhimekiID: chhimekiFormValue.chhimekiID,
                Flag: "U",
                Name: chhimekiFormValue.name,
                Address: chhimekiFormValue.address,
                Contact: chhimekiFormValue.contact,
                Email: chhimekiFormValue.email,
                Logo: image !== null ? image.split(',')[1] : "",
                About: chhimekiFormValue.about,
                Website: chhimekiFormValue.website,
                Facebook: chhimekiFormValue.facebook,
                EstablishDate: chhimekiFormValue.establishDate,
                Adakshya: chhimekiFormValue.head,
                Latitude: chhimekiFormValue.latitude,
                Longitude: chhimekiFormValue.longitude,
                IsAllow: chhimekiFormValue.allowStatus,
                ExpiryDate: chhimekiFormValue.expiryDate,
                UpdatedBy: "1",
                CType: "1",
                SenderID: chhimekiFormValue.senderID,
                ServerKey: chhimekiFormValue.serverKey,
                FetchURL: `${urll}/api/sadmin/chhimeki-info`,
                Type: "POST",

            }
            console.log(dataForm)
            Fetchdata(dataForm).then(function (result) {
                console.log(result);
                if (result.StatusCode === 200) {
                    chmList();
                    setMsg({
                        msg: result.Message,
                        type: "alert alert-success",
                    });
                } else {
                    setMsg({
                        msg: result.Message,
                        type: "alert alert-danger",
                    });
                }
            });




            if (closeChecked === true) {
                $(".chhimekiPops").fadeOut(100);
                setEditPop(false);
            }

            setIsEditSubmit(false);
            setPushNotice(false)
        }
    }, [chhimekiFormError]);

    const closePopup = (e) => {
        e.preventDefault();
        $(".chhimekiPops").fadeOut(100);
        setEditPop(false);
        setChhimekiFormError({});
        setIsEditSubmit(false);
        setIsUploaded(false);
        setImage(null);
        setPushNotice(false)
    };

    const validate = (values) => {
        const errors = {};
        const numv = /^[0-9]+$/i;
        const digits = /^\d{10}$/;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.chhimekiID) {
            errors.chhimekiID = "Required";
        }
        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.address) {
            errors.address = "Required";
        }
        if (!values.about) {
            errors.about = "Required";
        }
        if (!values.head) {
            errors.head = "Required";
        }
        if (!values.establishDate) {
            errors.establishDate = "Required";
        }
        if (!values.expiryDate) {
            errors.expiryDate = "Required";
        }
        if (!values.allowStatus) {
            errors.allowStatus = "Required";
        }
        if (!values.contact) {
            errors.contact = "Required";
        } else if (!numv.test(values.contact)) {
            errors.contact = "Must be digits";
        } else if (!digits.test(values.contact)) {
            errors.contact = "Must be 10 digits";
        }
        if (values.email.length === 0) {
            setIsEditSubmit(true);
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format";
        }

        return errors;
    };

    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            setTypeFile(e.target.files[0].type);
            let reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    }


    // console.log(newValue)

    return (
        <>
            <div className="container-fluid basicform">
                <form>
                    <div className="form-row">
                        <div className="col-md-4">
                            <label className="form-label" htmlFor="chhimekiID">
                                Chhimeki ID<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.chhimekiID}
                                onChange={handleChange}
                                name="chhimekiID"
                                className="form-control form-control-sm mb-1"
                                id="chhimekiID"
                                disabled

                            />
                            <p className="errormsg ">{chhimekiFormError.chhimekiID}</p>
                        </div>
                        <div className="col-md-8">
                            <label className="form-label" htmlFor="name">
                                Name<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.name}
                                onChange={handleChange}
                                name="name"
                                className="form-control form-control-sm mb-1"
                                id="name"
                            />
                            <p className="errormsg ">{chhimekiFormError.name}</p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="contact">
                                Contact<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.contact}
                                onChange={handleChange}
                                name="contact"
                                className="form-control form-control-sm mb-1"
                                id="contact"
                            />
                            <p className="errormsg ">{chhimekiFormError.contact}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.email}
                                onChange={handleChange}
                                name="email"
                                className="form-control form-control-sm mb-1"
                                id="email"
                            />
                            <p className="errormsg ">{chhimekiFormError.email}</p>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="address">
                            Address<sup className="sup-col">*</sup>
                        </label>
                        <input
                            type="text"
                            value={chhimekiFormValue.address}
                            onChange={handleChange}
                            name="address"
                            className="form-control form-control-sm mb-1"
                            id="address"
                        />
                        <p className="errormsg ">{chhimekiFormError.address}</p>
                    </div>




                    <div className="form-group">
                        <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                            placeholder="about"
                        >
                            About<sup className="sup-col">*</sup>
                        </label>
                        <textarea
                            className="form-control"
                            name="about"
                            value={chhimekiFormValue.about}
                            onChange={handleChange}
                            id="exampleFormControlTextarea1"
                            rows="3"
                            style={{ fontSize: "12px" }}
                        ></textarea>
                        <p className="errormsg ">{chhimekiFormError.about}</p>
                    </div>



                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="website">
                                Website
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.website}
                                onChange={handleChange}
                                name="website"
                                className="form-control form-control-sm mb-1"
                                id="website"
                            />

                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="facebook">
                                Facebook
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.facebook}
                                onChange={handleChange}
                                name="facebook"
                                className="form-control form-control-sm mb-1"
                                id="facebook"
                            />

                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="establishDate">
                                Established Date<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="date"
                                value={chhimekiFormValue.establishDate}
                                onChange={handleChange}
                                name="establishDate"
                                className="form-control form-control-sm mb-1"
                                id="establishDate"
                            />
                            <p className="errormsg ">{chhimekiFormError.establishDate}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="head">
                                Aadikshya<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.head}
                                onChange={handleChange}
                                name="head"
                                className="form-control form-control-sm mb-1"
                                id="head"
                            />
                            <p className="errormsg ">{chhimekiFormError.head}</p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="latitude">
                                Latitude
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.latitude}
                                onChange={handleChange}
                                name="latitude"
                                className="form-control form-control-sm mb-1"
                                id="latitude"
                            />

                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="longitude">
                                Longtitude
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.longitude}
                                onChange={handleChange}
                                name="longitude"
                                className="form-control form-control-sm mb-1"
                                id="longitude"
                            />

                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="expiryDate">
                                Expiry Date<sup className="sup-col">*</sup>
                            </label>
                            <input
                                type="date"
                                value={chhimekiFormValue.expiryDate}
                                onChange={handleChange}
                                name="expiryDate"
                                className="form-control form-control-sm mb-1"
                                id="expiryDate"
                            />
                            <p className="errormsg ">{chhimekiFormError.expiryDate}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="allowStatus">
                                Select Allow Status<sup className="sup-col">*</sup>
                            </label>
                            <select
                                id="allowStatus"
                                name="allowStatus"
                                onChange={handleChange}
                                value={chhimekiFormValue.allowStatus}
                                className="form-control form-control-sm mb-1"
                                style={{ fontSize: "12px" }}
                            >

                                <option value=" " selected >
                                    Select option
                                </option>
                                <option value="Y">Allow</option>
                                <option value="N"> Disallow</option>
                            </select>
                            <p className="errormsg ">{chhimekiFormError.allowStatus}</p>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="serverKey">
                                Server Key
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.serverKey}
                                onChange={handleChange}
                                name="serverKey"
                                className="form-control form-control-sm mb-1"
                                id="serverKey"
                            />

                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="senderID">
                                Sender ID
                            </label>
                            <input
                                type="text"
                                value={chhimekiFormValue.senderID}
                                onChange={handleChange}
                                name="senderID"
                                className="form-control form-control-sm mb-1"
                                id="senderID"
                            />

                        </div>
                    </div>



                    <div className="form-group  ">
                        <div className="form-label" htmlFor="text">
                            Upload Image
                        </div>

                        <div className="BoxUpload">
                            <div className="image-upload">
                                {!image ? (
                                    <>
                                        <label htmlFor="upload-input">
                                            <img
                                                src={Plus}
                                                draggable={"false"}
                                                alt="placeholder"
                                                style={{ width: 90, height: 100, paddingTop: "10px" }}
                                            />
                                            {/* <p style={{ color: "#444" }}>Click to upload image</p> */}
                                        </label>

                                        <input
                                            id="upload-input"
                                            type="file"
                                            accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                                            onChange={handleImageChange}
                                        />
                                    </>
                                ) : (
                                    <div className="ImagePreview">
                                        <img
                                            className="close-icon"
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            onClick={() => {
                                                setIsUploaded(false);
                                                setImage(null);
                                            }}
                                        />

                                        <img
                                            id="uploaded-image"
                                            src={image}
                                            draggable={false}
                                            alt="uploaded-img"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-12 notificationFormFooter ">
                <div class="form-check notification-form-check" style={{ display: 'flex' }}>
                    <div><input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        name="isChecked"
                        onChange={handlePushNotification}
                        checked={pushNotice}
                    />
                        <label class="form-check-label" for="exampleCheck1">
                            Notify User
                        </label>
                    </div>

                    <div className="checkbox-close">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck2"
                            name="closeChecked"
                            onChange={handleOnChange}
                            checked={closeChecked}
                        />
                        <label class="form-check-label" for="exampleCheck2">
                            Exit after submit
                        </label>
                    </div>
                </div>
                <div className="notification-form-button">
                    <button
                        className="btn btn-sm btn-cmpy ml-2"
                        style={{ background: "green" }}
                        onClick={formSubmit}
                    >
                        Submit
                    </button>
                    <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
}
