import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import "../../css/imagePreview.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import IncomeContext from "../contextFolder/IncomeContextFolder/IncomeContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Fetchdata } from "../hooks/getData";

export default function IncomeForm(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { setMsg, urll, setPopup } = useContext(SidebarContext);

  const memcontext = useContext(MemberContext);
  const { memberDrop, def } = memcontext;


  const {
    incomeFormError,
    setIncomeFormError,
    incomeSubmit,
    setIncomeSubmit,
    incomeFormValue,
    setIncomeFormValue,
    incomevalue,
    closeChecked,
    setCloseChecked,
    reload,
    setReload,
    incomeListShown,
    setPushNotice,
    pushNotice,
    IncomePush,
  } = useContext(IncomeContext);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setIncomeFormValue({ ...incomeFormValue, [name]: value });
  };
  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  const handleDate = ({ bsDate }) => {
    setIncomeFormValue({ ...incomeFormValue, nepaliDate: bsDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setIncomeFormError(validate(incomeFormValue));
    setIncomeSubmit(true);
    console.log(incomeFormValue);
  };

  useEffect(() => {
    if (Object.keys(incomeFormError).length === 0 && incomeSubmit) {
      fetchdata(incomeFormValue);
      if (pushNotice === true) {
        IncomePush();
      }
      if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setPopup(false);
      }
      setIncomeFormValue(incomevalue);
      setIncomeSubmit(false);
      setPushNotice(false);
    }
  }, [incomeFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setIncomeFormValue(incomevalue);
    setIncomeFormError({});
    setIncomeSubmit(false);
    setPushNotice(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.member) {
      errors.member = "Required";
    }

    if (!values.nepaliDate) {
      errors.nepaliDate = "Required";
    }
    if (!values.particular) {
      errors.particular = "Required";
    }

    return errors;
  };

  //API to hit Income post
  const obj = {
    tole_id: ToleID,
    user_id: UserID,
    receipt_no: incomeFormValue.receiptNumber,
    nepali_date: incomeFormValue.nepaliDate,
    amount: incomeFormValue.amount,
    receipt: incomeFormValue.receipt,
    particulars: incomeFormValue.particular,
    status: "1",
  };
  // const obj1 = Object.values(obj);
  // const obj2 = obj1.join("##");

  // const fetchdata = async () => {

  //     const dataForm = {
  //         ToleID: ToleID,
  //         userID: UserID.toString(),
  //         tblOpt: "30",
  //         tblField: "tole_id,user_id,receipt_no,nepali_date,payment,receipt,particulars,status",
  //         tblValues: obj2,
  //         validationFields: "receipt_no",
  //         fieldforList: "-",
  //         orderbyField: "-",
  //         DateTime: "2022/02/03 03:00 AM",
  //         Flag: "Y"
  //     }
  //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //     var encrypted = CryptoJS.AES.encrypt(
  //         JSON.stringify(dataForm),
  //         key, //dataForm
  //         {
  //             iv: iv,
  //             mode: CryptoJS.mode.CBC,
  //             padding: CryptoJS.pad.Pkcs7,
  //         }
  //     );

  //     const dts = {
  //         param: encrypted.toString(),
  //     };
  //     const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             Signature: apisignature,
  //         },
  //         body: JSON.stringify(dts), //dts
  //     });
  //     const tole = await response.json();
  //     console.log(tole);

  //     if (tole.STATUS_CODE === "0") {
  //         accountListShown();
  //         setReload(!reload)
  //         setMsg({
  //             msg: tole.MESSAGE,
  //             type: "alert alert-success",
  //         });
  //     } else if (tole.STATUS_CODE === "1") {
  //         setMsg({
  //             msg: tole.MESSAGE,
  //             type: "alert alert-danger",
  //         });
  //     }

  // };
  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "I",
      UserID: UserID,
      // MemID: incomeFormValue.member,
      AccNo: incomeFormValue.receiptNumber,
      AccType: incomeFormValue.member,
      EngDate: incomeFormValue.nepaliDate,
      NepDate: incomeFormValue.nepaliDate,
      Debit: incomeFormValue.member === "I" ? incomeFormValue.amount : "0",  // If Income, send in Debit
      Credit: incomeFormValue.member === "E" ? incomeFormValue.amount : "0", // If Expense, send in Credit
      Particulars: incomeFormValue.particular,
      Notify: pushNotice ? "y" : "n",
      FetchURL: `${urll}/admin/inc-exp`,
      Type: "POST",
    };
    
    console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        incomeListShown();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div
              className="form-group col-md-12"
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="member">
                Select Type <sup className="sup-col">*</sup>
              </label>

              <select
                id="member"
                name="member"
                onChange={handleChange}
                value={incomeFormValue.member}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Type
                </option>
               
                  <option value="I">
                    Income
                  </option>
                  <option value="E">
                    Expense
                  </option>
            
              </select>
              <p className="errormsg ">{incomeFormError.member}</p>
            </div>
          </div>

          <div className="form-row">
            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label
                htmlFor="nepaliDate"
                className="form-label"
                placeholder="description"
              >
                Nepali Date<sup className="sup-col">*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={incomeFormValue.nepaliDate}
                onChange={handleDate}
                defaultDate="YYYY/MM/DD"
              />
              <p className="errormsg ">{incomeFormError.nepaliDate}</p>
            </div>

            <div
              className="form-group col-md-6  "
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="receiptNumber">
                Account Number
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={incomeFormValue.receiptNumber}
                id="receiptNumber"
                name="receiptNumber"
              />
            </div>
          </div>









          <div className="form-row">
            <div
              className="form-group col-md-12"
              style={{ marginBottom: "-0.8px" }}
            >
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                name="amount"
                value={incomeFormValue.amount}
                onChange={handleChange}
                id="amount"
              ></input>
            </div>

          </div>


















          <div className="form-row">
            <div
              className="form-group col-md-12  "
              style={{ marginTop: "10px" }}
            >
              <label className="form-label" htmlFor="particular">
                Particular <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={incomeFormValue.particular}
                id="particular"
                name="particular"
              />
              <p className="errormsg ">{incomeFormError.particular}</p>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
