import React, { useContext, useEffect } from "react";
import GovernmentContext from "../contextFolder/GovernmentContextFolder/GovernmentContext";
import $ from "jquery";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

export default function GovernmentForm(props) {
  const { setPopup } = useContext(SidebarContext);

  const context = useContext(GovernmentContext);

  const {
    governmentFormError,
    setGovernmentFormError,
    governmentSubmit,
    setGovernmentSubmit,
    governmentvalue,
    governmentFormValue,
    setGovernmentFormValue,
    closeChecked,
    setCloseChecked,
    pushGovernment,
    setPushGovernment,
    fetchdata,
    setNewValue,
  } = context;
  const memcontext = useContext(MemberContext);
  const { memberDrop, def } = memcontext;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setGovernmentFormValue({ ...governmentFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handlePushGovernment = () => {
    setPushGovernment(!pushGovernment);
  };

  const formSubmit = (e) => {
    console.log(governmentFormValue);
  
    e.preventDefault();
     setGovernmentFormError(validate(governmentFormValue));
    setGovernmentSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(governmentFormError).length === 0 && governmentSubmit) {
      fetchdata(governmentFormValue);
      if (closeChecked === true) {
        $(".complainTopicPop").fadeOut(100);
        setPopup(false);
      }
      setGovernmentFormError({});
      setGovernmentFormValue(governmentvalue);
      //console.log(governmentFormValue);
      setGovernmentSubmit(false);
    }
  }, [governmentFormError]);


  const closePopup = (e) => {
    e.preventDefault();
    setPopup(false);
    $(".complainTopicPop").fadeOut(100);
    setGovernmentFormValue(governmentvalue);
    setGovernmentFormError({});
    setGovernmentSubmit(false);
    setCloseChecked(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.identype) {
      errors.identype = "Required";
    }
    if (!values.identno) {
      errors.identno = "Required";
    }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">

             <div className="form-group col-md-12">
              <label className="form-label" htmlFor="category">
                Select Member<sup className="sup-col">*</sup>
              </label>
              <select
                id="slug-title"
                name="member"
                value={governmentFormValue.member}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                onClick={(e) => {
                  setNewValue(e.target.value);
                  //console.log(e.target.value);
                }}
              >
                <option defaultValue="Select Event Title">Select Member</option>
                {memberDrop.map((item) => (
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{governmentFormError.member}</p>
            </div>

            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="identype">
                Identity Type<sup className="sup-col">*</sup>
              </label>
              <select
                id="identype"
                name="identype"
                onChange={handleChange}
                value={governmentFormValue.identype}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Identity Type
                </option>
                <option value="C">Citizenship</option>
                <option value="D">Driving</option>
                <option value="P">Passport</option>
                <option value="V">Voter</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {governmentFormError.identype}
              </p>
            </div>



            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="identno">
                Identity No <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={governmentFormValue.identno}
                onChange={handleChange}
                name="identno"
                className="form-control form-control-sm mb-1"
                id="identno"
              />
              <p className="errormsg ">{governmentFormError.identno}</p>
            </div>



            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="issuedate">
                Issued Date <sup className="sup-col">*</sup>
              </label>
              <input
                type="date"
                value={governmentFormValue.issuedate}
                onChange={handleChange}
                name="issuedate"
                className="form-control form-control-sm mb-1"
                id="issuedate"
              />
              <p className="errormsg ">{governmentFormError.issuedate}</p>
            </div>


            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="issueby">
                Issued By <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={governmentFormValue.issueby}
                onChange={handleChange}
                name="issueby"
                className="form-control form-control-sm mb-1"
                id="issueby"
              />
              <p className="errormsg ">{governmentFormError.issueby}</p>
            </div>


          </div>

        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          {/* <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushGovernment}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div> */}

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
