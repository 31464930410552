import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ProfessionForm from './ProfessionForm';
import ProfessionContext from '../contextFolder/ProfessionContextFolder/ProfessionContext';




export default function ProfessionPopup(props) {
  const context = useContext(ProfessionContext)

  const { professionvalue, setProfessionFormValue, setProfessionFormError, setProfessionSubmit } = context;
  const { height } = UsewindowDimension();






  const closePopup = (e) => {
    e.preventDefault();
    setProfessionFormValue(professionvalue)
    props.setProfessionTrigger(false)
    $('.complainTopicPop').fadeOut(100);
    setProfessionFormError({})
    setProfessionSubmit(false)
  }

  useEffect(() => {

    if (props.professionTrigger) {
      $('.complainTopicPop').fadeIn(100);
    }
  }, [props.professionTrigger])



  return (

    <div className="complainPopUp complainTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
    >
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "inside--prof ip500" : "inside--prof"}>
        <div className="popUpHeader ">
          <div className='popUpHeaderText '>Add Profession</div>
          <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
        </div>

        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="notice-outlet">
          <ProfessionForm />

        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  )

}