import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Member.css";
import HelplineContext from "../contextFolder/HelplineContextFolder/HelplineContext";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function HelplinePopup(props) {
  const { setPopup } = useContext(SidebarContext);
  const { height } = UsewindowDimension();
  const {
    helplineFormValue,
    setHelplineFormValue,
    helplineFormError,
    setHelplineFormError,
    isSubmit,
    setIsSubmit,
    closeChecked,
    setCloseChecked,
    helplineValue,
    fetchdata,
  } = useContext(HelplineContext);

  const closePopup = (e) => {
    e.preventDefault();
    props.setHelplineTrigger(false);
    $(".complainTopicPop").fadeOut(100);
    setHelplineFormValue(helplineValue);
    setHelplineFormError({});
    setIsSubmit(false);
    setCloseChecked(false);
  };

  useEffect(() => {
    if (props.helplineTrigger) {
      $(".complainTopicPop").fadeIn(100);
    }
  }, [props.helplineTrigger]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setHelplineFormValue({ ...helplineFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    if (!values.service) {
      errors.service = "Required";
    }
  
    if (!values.number) {
      errors.number = "Required";
    } else if (!numv.test(values.number)) {
      errors.number = "Must be digits";
    } else if (!digits.test(values.number)) {
      errors.number = "Must be 10 digits";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setHelplineFormError(validate(helplineFormValue));
    setIsSubmit(true);
  };
  
  useEffect(() => {
    if (Object.keys(helplineFormError).length === 0 && isSubmit) {
      fetchdata(helplineFormValue);
      if (closeChecked === true) {
        $(".complainTopicPop").fadeOut(100);
        setPopup(false);
      }
      setHelplineFormError({});
      setHelplineFormValue(helplineValue);
      setIsSubmit(false);
    }
  }, [helplineFormError]);

  return (
    <div className="complainPopUp complainTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div className={height < 500 ? "inside--fam ip500" : "inside--fam"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">New Helpline</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="notice-outlet">
          <div className="container-fluid basicform">
            <form>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="form-label" htmlFor="service">
                    Title <sup className="sup-col">*</sup>
                  </label>

                  <input
                    type="text"
                    name="service"
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="service"
                    value={helplineFormValue.service}
                  />
                  <p className="errormsg ">{helplineFormError.service}</p>
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label" htmlFor="name">
                    Name 
                  </label>

                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="name"
                    value={helplineFormValue.name}
                  />
                  
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label" htmlFor="number">
                    Contact <sup className="sup-col">*</sup>
                  </label>

                  <input
                    type="text"
                    name="number"
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="number"
                    value={helplineFormValue.number}
                  />
                  <p className="errormsg ">{helplineFormError.number}</p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-12 managementFormFooter ">
            <div class=" notification-form-check" style={{ display: "flex" }}>
              <div className="checkbox-close">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck2"
                  name="closeChecked"
                  onChange={handleOnChange}
                  checked={closeChecked}
                />
                <label class="form-check-label" for="exampleCheck2">
                  Exit after submit
                </label>
              </div>
            </div>
            <div className="notification-form-button">
              <button
                className="btn btn-sm btn-cmpy ml-2"
                style={{ background: "green" }}
                onClick={formSubmit}
              >
                Submit
              </button>
              <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
