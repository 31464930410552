import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import ManagementContext from "../contextFolder/ManagementContextFolder/ManagementContext";
import CryptoJS from "crypto-js";
import $ from "jquery";
import SidebarContext from "../sidebarcontext/SidebarContext";

function ManagementPositionForm() {
  const apisignature = process.env.REACT_APP_SIGNATURE;

  const context = useContext(ManagementContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID;
  const {
    setMsg,
    URL,
    urll,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const {
    data,
    setData,
    positionFormError,
    setPositionFormError,
    positionSubmit,
    setPositionSubmit,
    managementTitleList,
    setPopup,
    update,
    setUpdate,
    deactivateManagementPosition,
    reload,
    setReload,
    mgmtTitle,
  } = context;

  function handle(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setData({ ...data, [name]: value });
  }
  //Api to post management position form
  const obj = {
    tole_id: ChhimekiID,
    type_name: data.titleName,
    status: 1,
  };

  // const obj1 = Object.values(obj);
  // const obj2 = obj1.join("##");

  // const fetchdata = async () => {
  //   // JSON.stringify(dataToSend)

  //   const dataForm = {
  //     ChhimekiID: ChhimekiID,
  //     userID: UserID.toString(),
  //     tblOpt: "26",
  //     tblField: "tole_id,type_name,status",
  //     tblValues: obj2,
  //     validationFields: "type_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   if (tole.STATUS_CODE === "0") {
  //     managementTitleList();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: "Title Alredy Exists. Please choose another Name.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const fetchdata = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      UserID: UserID,
      Flag: "i",
      Type: data.titleName,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management-type`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          managementTitleList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const formSubmit = (e) => {
    setPositionFormError(validate(data));
    setPositionSubmit(true);
    setPopup(false);
  };

  useEffect(() => {
    if (Object.keys(positionFormError).length === 0 && positionSubmit) {
      fetchdata(data);
      setReload(!reload);
      setData({ titleName: "" });
      setPositionSubmit(false);
    }
  }, [positionFormError]);

  const validate = (values) => {
    const errors = {};

    if (!values.titleName) {
      errors.titleName = "Required";
    }
    return errors;
  };

  const [searchTerm, setSearchTerm] = useState("");

  //Update Function

  const [titleID, setTitleId] = useState(null);

  function selectUser(datas) {
    setData({
      titleName: datas.Type,
    });
    setTitleId(datas.TypeID);
    setUpdate(true);
  }

  // const updateUser = async () => {
  //   const dataForm = {
  //     ChhimekiID: ChhimekiID,
  //     userID: UserID.toString(),
  //     tblOpt: "26",
  //     tblField: "type_name",
  //     tblValues: data.titleName,
  //     tblID: titleID.toString(),
  //     validationFields: "type_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   console.log(tole);
  //   if (tole.STATUS_CODE === "0") {
  //     managementTitleList();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Edited",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: "Title Alredy Exists. Please choose another Name.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const updateUser = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      UserID: UserID,
      Flag: "U",
      Type: data.titleName,
      TypeID: titleID,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management-type`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          managementTitleList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const changeStatus = (posID, IsActive) => {
    deactivateManagementPosition(posID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <div className="form-group  ">
          <label className="form-label" htmlFor="slug">
            Title <sup className="sup-col">*</sup>
          </label>
          <input
            type="text"
            name="titleName"
            onChange={(e) => handle(e)}
            value={data.titleName}
            className="form-control form-control-sm mb-1"
            id="titleName"
          />
          <p className="errormsg ">{positionFormError.titleName}</p>
        </div>

        <div className="floatyy">
          {update ? (
            <button
              className="btn btn-sm btn-cmpy "
              style={{ background: "0079bf" }}
              onClick={updateUser}
            >
              Update
            </button>
          ) : (
            <button
              className="btn btn-sm btn-cmpy "
              style={{ background: "0079bf" }}
              onClick={formSubmit}
            >
              Save
            </button>
          )}
        </div>
      </div>

      <section
        className="content-section main-content"
        style={{ margin: "3px" }}
      >
        <div className="content">
          <div className=" col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-md-4 Search" style={{ padding: "0px" }}>
                <div className="dashboard-search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none" }}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <i
                    style={{
                      paddingTop: "7px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      color: "#999",
                    }}
                    className="fas fa-search searchIconPosition"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 p-1">
              <div className="outer-wrapper">
                <div
                  className="table-wrapper"
                  style={{
                    overflowX: "scroll",
                    overflowY: "scroll",
                    margin: "3px",
                    height: "260px",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr className="tableHead">
                        <td >S.N.</td>
                        <td >Position</td>
                        <td >No. of Management</td>
                        <td > Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {mgmtTitle
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.Type.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td >{i + 1}</td>
                            <td >{item.Type}</td>
                            <td >{item.NoOfManagement}</td>
                            <td >
                              <span
                                className="rejectspan badge"
                                onClick={() => selectUser(item)}
                               
                              >
                                Edit
                              </span>{" "}
                              <span
                                className="editspan badge"
                                onClick={() =>
                                  changeStatus(item.TypeID, item.Status)
                                }
                               
                              >
                                {checkStatus(item.Status)}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ManagementPositionForm;
