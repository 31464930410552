import React, { useState, useContext, useEffect, useRef } from 'react'
import UsewindowDimension from '../hooks/UsewindowDimension'
import $ from "jquery";
import '../../css/Notification.css';
import GovernmentContext from '../contextFolder/GovernmentContextFolder/GovernmentContext';
import GovernmentEdit from './GovernmentEdit';



export default function GovernmentEditPop(props) {
    const { height } = UsewindowDimension();

    const context = useContext(GovernmentContext)

    const { setPushGovernment, setGovernmentFormError, setEditIsSubmit } = context;


    const myRef = useRef(null)

    const closePopup = (e) => {
        e.preventDefault();
        props.setGovernmentTrigger(false)
        $('.displayPopups').fadeOut(100);
        setGovernmentFormError({});
        setEditIsSubmit(false);
        setPushGovernment(false)
        myRef.current.scrollIntoView()
    }

    useEffect(() => {

        if (props.governmentTrigger) {
            $('.displayPopups').fadeIn(100);
        }
    }, [props.governmentTrigger])



    return (

        <div className="noticePopUp displayPopups container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"} ref={myRef} >
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Edit Identity</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>
                <div className="notice-outlet">
                    <GovernmentEdit />

                </div>

            </div>

        </div>
    )

}