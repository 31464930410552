import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import "../../css/Member.css";
import Personal from "./Personal";
import Other from "./Other";
import Permanent from "./Permanent";
import Temporary from "./Temporary";
import { Step, StepLabel, Stepper } from "@material-ui/core";

import Profession from "./Profession";
import FamilyMain from "./FamilyMain";

export default function MemberPopUp(props) {
  const { height } = UsewindowDimension();

  const context = useContext(MemberContext);

  const {
    currentStep,
    setStep,
    membervalue,
    memberFormErrors,
    memberFormValue,
    setMemberFormValue,
    isSubmit,
    setIsSubmit,
    setMemberFormErrors,
    permanentIsSubmit,
    setPermanentIsSubmit,
    permanentFormError,
    setPermanentFormError,
    temporaryIsSubmit,
    setTemporaryIsSubmit,
    temporaryFormError,
    setTemporaryFormError,
    finalSubmit,
    setFinalSubmit,
    setProfessionSubmit2,
    setFamilySubmit1,
  } = context;

  const closePopup = (e) => {
    e.preventDefault();
    props.setMemberTrigger(false);
    $(".displayPopup").fadeOut(100);
    setMemberFormValue(membervalue);
    setMemberFormErrors({});
    setIsSubmit(false);
    setPermanentFormError({});
    setPermanentIsSubmit(false);
    setTemporaryFormError({});
    setTemporaryIsSubmit(false);
    setFinalSubmit(false);
    // setFamilySubmit1(false);
    // setProfessionSubmit2(false);
    setStep(1);
  };

  useEffect(() => {
    if (props.memberTrigger) {
      $(".displayPopup").fadeIn(100);
      const value = document.querySelector(".StepButtonContent-0-2-5");
      if (value != null) {
        value.innerHTML = "1";
      }
    }
  }, [props.memberTrigger]);

  useEffect(() => {}, []);

  function showStep(step) {
    switch (step) {
      case 1:
        return <Personal />;
      case 2:
        return <Permanent />;
      case 3:
        return <Temporary />;
      // case 4:
      //   return <FamilyMain />;
      // case 5:
      //   return <Profession />;
      case 4:
        return <Other />;
    }
  }

  return (
    <div
      className="popUP displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
      style={{ overflow: "scroll" }}
    >
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "insidePopup ip500" : "insidePopup"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">New Member</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        {/* {loading ? <Spinner/> :(        */}

        <div className="member-outlet">
          {/* {activeTab.tab1 &&(<Basicform active={activeTab} setActive={setActiveTab} />)} */}
          {/* <Personal />  */}
          {/* <Other /> */}

          {/* <Temporary />  */}
          <div className="form-stepper">
            <Stepper
              style={{ width: "100%" }}
              activeStep={currentStep - 1}
              orientation="horizontal"
              alternativeLabel
            >
              <Step>
                <StepLabel>Personal</StepLabel>
              </Step>
              <Step>
                <StepLabel>Permanent Address</StepLabel>
              </Step>
              <Step>
                <StepLabel>Temporary Address</StepLabel>
              </Step>
               <Step>
                <StepLabel>Login Info</StepLabel>
              </Step>
              {/* <Step>
                <StepLabel>Profession</StepLabel>
              </Step>
              <Step>
                <StepLabel>Other</StepLabel> */}
              {/* </Step> */} 
            </Stepper>
          </div>
          {showStep(currentStep)}
          {/* <Permanent /> */}
        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  );
}
