import React, { useState, useEffect, useContext, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../App.css";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import bankingimage from "../images/banking.png";
import logo from "../images/logo.png";
import loginImg from "../images/community3.jpg";
import loginImg2 from "../images/community2.png";
import loginImg3 from "../images/community.png";
import "../css/login.css";
import AuthContext from "./auth-context";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import { Fetchdata } from "./hooks/getData";
import { FaRegEye, FaRegEyeSlash, FaUserCircle } from "react-icons/fa";
import { BsFillKeyFill } from "react-icons/bs";
import { FaIdCardAlt } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/bundle";

export default function Login() {
  const authCtx = useContext(AuthContext);
  const apiKey = process.env.REACT_APP_KEY;
  const loginurl = process.env.REACT_APP_URL;
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const initalvalue = { UserName: "", Password: "", ToleID: "" };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [border, setBorder] = useState(false);
  const [border1, setBorder1] = useState(false);
  const [border2, setBorder2] = useState(false);

  const inputRef = useRef();
  const inputRef1 = useRef();
  const inputRef2 = useRef();

  const [color, setColor] = useState({
    color: "",
  });

  const [passwordType, setPasswordType] = useState("password");

  const date = new Date();
  let year = date.getFullYear();

  let navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // const auth = localStorage.getItem("userInfo");

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };
  let location = useLocation();

 

  const FormError = () => {
    const Error = sessionStorage.getItem("msg");
    const ErrorMsg = JSON.parse(Error);
    setformErrors({ errorv: ErrorMsg.Message });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //   fetchdata(chhimekiFormValue);

      const dataForm = {
        ToleID: formValues.ToleID,
        UserName: formValues.UserName,
        Password: formValues.Password,
        NoToken: "",
        Source: "A",
        FetchURL: `${loginurl}/login`,
        Type: "POST",
      };
      console.log(dataForm);
      Fetchdata(dataForm)
        .then(function (result) {
          const res = result.loginLst[0];
          const results = result.StatusCode;

          if (results === 200) {
            const userInfo = {
             
              ToleID: res.ToleID,
              ToleName: res.ToleName,
              ToleAddress: res.ToleAddress,
              ToleDistrict: res.ToleDistrict,
              ToleWoda: res.ToleWoda,
              ToleMunicipality: res.ToleMunicipality,
              ToleProvince: res.ToleProvince,
              ToleContact: res.ToleContact,
              ToleEmail: res.ToleEmail,
              ToleLogo: res.ToleLogo,
              ToleAbout: res.ToleAbout,
              ToleWebsite: res.ToleWebsite,
              ToleFb: res.ToleFb,
              ToleRegDate: res.ToleRegDate,
              ToleLat: res.ToleLat,
              ToleLong: res.ToleLong,
              UserID: res.UserID,
              UserName: res.UserName,
              FirstName: res.FirstName,
              MiddleName: res.MiddleName,
              LastName: res.LastName,
              UserImage: res.UserImage,
              Contact: res.Contact,
              Phone: res.Phone,
              Email: res.Email,
              UserType:res.UserType,
              BirthAD: res.BirthAD,
              BirthBS: res.BirthBS,
              Nationality: res.Nationality,
              PAN: res.PAN,
              Religion: res.Religion,
              Gender:res.Gender,
              MaritalStatus: res.MaritalStatus,
              BloodGroup: res.BloodGroup,
              FatherName: res.FatherName,
              MotherName: res.MotherName,
              GrandfatherName: res.GrandfatherName,
              Qualification: res.Qualification,
              PermAddress: res.PermAddress,
              PermProvince: res.PermProvince,
              PermDistrict: res.PermDistrict,
              PermMunic: res.PermMunic,
              PermWard: res.PermWard,
              PermHouse: res.PermHouse,
              TempAddress: res.TempAddress,
              TempProvince: res.TempProvince,
              TempDistrict: res.TempDistrict,
              TempMunic: res.TempMunic,
              TempWard: res.TempWard,
              TempHouse: res.TempHouse,
              LastLoginedDate: res.LastLoginedDate
            };
            console.log("UserInfo to be stored:", userInfo);
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            authCtx.login(res.UserID);
            navigate("/member");
            setIsSubmit(false);
          } else {
            setIsSubmit(false);
            // setformErrors({ ...formErrors, errorv: result.Message });
            FormError();
          }
        })

        .catch((result) => {
          setIsSubmit(false);
         
          FormError();
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.Password) {
      errors.Password = "Password  is required";
      inputRef2.current.focus();
      setBorder2(true);
    }


    if (!values.UserName) {
      errors.UserName = "Username is required";
      inputRef1.current.focus();
      setBorder1(true);
      setColor({
        color: "#ff4949",
      });
    }
    if (!values.ToleID) {
      errors.ToleID = "Id is required";
      inputRef.current.focus();
      setBorder(true);
      setColor({
        color: "#ff4949",
      });
    }

    return errors;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return authCtx.isLoggedIn ? (
    <Navigate to="/member" replace state={{ from: location }} />
  ) : (
    <>
     

      <div className="uk-container uk-container-expand uk-margin-small-top uk-margin-bottom">
        <img
          src={logo}
          alt="logo"
          style={{ height: "60px" }}
          className="uk-margin-left"
        />
      </div>

      <section className="login__page uk-container">
        <div className="uk-grid uk-child-width-1-2">
          <div className="login-img-wrapper">
            <section className="login__img">
              <div className="txt">
                <h1>
                  Welcome to the <br /> <span>Hami</span> chhimeki
                </h1>
              </div>

              <div className="slider-img">
                <Swiper
                  className="mySwiper"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  <SwiperSlide className="swiper-slide">
                    <img src={loginImg} alt="image" />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img src={loginImg2} alt="image" />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img src={loginImg3} alt="image" />
                  </SwiperSlide>
                </Swiper>
              </div>
            </section>
          </div>

          <div className="login-form-wrapper">
            <section className="login__form">
              <h3>
                Login <span>Now!</span>
              </h3>
              <h5>Enter your valid credentials below.</h5>
              <form>
                <p className="errormsg"> {formErrors.errorv}</p>
                <div className="username-input">
                  <div className="uk-position-relative">
                    <input
                      type="text"
                      className={`uk-input ${border ? "border" : ""}`}
                      name="ToleID"
                      onChange={handleChange}
                      value={formValues.ToleID}
                      autoComplete="off"
                      id="id"
                      placeholder="Enter your ID"
                      onKeyPress={() => {
                        setBorder(false);
                      }}
                      ref={inputRef}
                      required
                      autoFocus
                    />
                    <span className="username-icon">
                      <FaIdCardAlt color="#0049ae" size="1.5rem" />
                    </span>
                  </div>
                  <span style={color}>{formErrors.ToleID}</span>
                </div>

                <div className="username-input">
                  <div className="uk-position-relative">
                    <input
                      type="text"
                      className={`uk-input ${border1 ? "border1" : ""}`}
                      name="UserName"
                      value={formValues.UserName}
                      onChange={handleChange}
                      autoComplete="off"
                      id="userName"
                      placeholder="Username"
                      onKeyPress={() => {
                        setBorder1(false);
                      }}
                      ref={inputRef1}
                      required
                    />
                    <span className="username-icon">
                      <FaUserCircle color="#0049ae" size="1.5rem" />
                    </span>
                  </div>
                  <span style={color}>{formErrors.UserName}</span>
                </div>

                <div className="password-input">
                  <div className="uk-position-relative">
                    <input
                      type={passwordType}
                      className={`uk-input ${border2 ? "border2" : ""}`}
                      name="Password"
                      value={formValues.Password}
                      onChange={handleChange}
                      id="password"
                      placeholder="Password"
                      onKeyPress={() => {
                        setBorder2(false);
                      }}
                      ref={inputRef2}
                      required
                    />
                    <span className="password-icon">
                      <BsFillKeyFill color="#0049ae" size="1.5rem" />
                    </span>
                    <span
                      className="toggle-password"
                      uk-toggle="target: .toggle"
                      onClick={showPassword}
                    >
                      <i className="toggle" title="Show Password">
                        <FaRegEyeSlash />
                      </i>
                      <i className="toggle" title="Hide Password" hidden>
                        <FaRegEye />
                      </i>
                    </span>
                  </div>
                  <span style={color}>{formErrors.Password}</span>
                </div>

                <button
                  type="submit"
                  className="uk-button"
                  onClick={handleSubmit}
                >
                  {isSubmit ? <span>Loading ...</span> : <span>Login</span>}
                </button>
              </form>
            </section>
          </div>
        </div>
      </section>

      <span className="copyright">
        {" "}
        &copy; 2024 Easy Software Pvt. Ltd. All Rights Reserved
      </span>
    </>
  );
}

