import CryptoJS from "crypto-js";
import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import DashboardContext from "../DashboardContextFolder/DashboardContext";
import ManagementContext from "./ManagementContext";

function ManagementState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { setShowMgmt } = useContext(DashboardContext);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pushNotice, setPushNotice] = useState(false);

  const [managementFormError, setManagementFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [managementTypePop, setManagementTypePop] = useState(false);

  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [editPop, setEditPop] = useState(false);

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [reload, setReload] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);

  const managementvalue = {
    position: "",
    user: "",
    isChecked: false,
  };

  const [managementFormValue, setManagementFormValue] =
    useState(managementvalue);

  const [data, setData] = useState({
    titleName: "",
  });
  const [positionFormError, setPositionFormError] = useState({});
  const [positionSubmit, setPositionSubmit] = useState(false);

  const [update, setUpdate] = useState(false);
  // console.log(managementList);

  // API to get Management Position List ////

  const [mgmtTitle, setMgmtTitle] = useState([]);

  useEffect(() => {
    managementTitleList();
  }, []);

  // const managementTitleList = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     tblOpt: "26",
  //     tblField: "ID PositionID,type_name PositionName,status Status",
  //     conditionField: "tole_id",
  //     conditionValues: ToleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectdatawithcondition`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setMgmtTitle(resp.dataList)
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };

  const managementTitleList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
     ManagementYearID: 26
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          managementDropList();
          const postResult = resp.ManagementLst ? resp.ManagementLst : "";
          setLoading(false);
          setMgmtTitle(postResult);
        } else {
          setMgmtTitle([]);
        }
      });
    });
  };

  //API to hit management position list in dropdown

  const [mgmtDropDown, setMgmtDropDown] = useState([]);

  useEffect(() => {
    managementDropList();
  }, []);

  const managementDropList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
      Status: "1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.MgmtLst ? resp.MgmtLst : "";
          setLoading(false);
          setMgmtDropDown(postResult);
        } else {
          setMgmtDropDown([]);
        }
      });
    });
  };

  // API to hit Management Type Status

  // const posStateInitial = [];
  // const [posanewStat, setPosNewStat] = useState(posStateInitial);

  // const deactivateManagementPosition = async (posID, IsActive) => {

  //   const formData = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "26",
  //     tblField: "status",
  //     tblValues: IsActive.toString(),
  //     tblID: posID.toString(),
  //     validationFields: "type_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   if (IsActive === 1) {
  //     formData.tblValues = 0
  //   } else if (IsActive === 0) {
  //     formData.tblValues = 1
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(formData),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   // console.log("encplogin", encrypted.toString())
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();

  //   if (tole.STATUS_CODE === "0") {
  //     managementTitleList();
  //     setReload(!reload)
  //     let statsN = JSON.parse(JSON.stringify(posanewStat));
  //     let pitchStatus;

  //     if (formData.tblValues === 1) {
  //       pitchStatus = "Activated";

  //     } else if (formData.tblValues === 0) {
  //       pitchStatus = "Deactivated";
  //     }
  //     console.log(pitchStatus)
  //     setPosNewStat(statsN)
  //     setMsg({
  //       msg: `Management Position ${pitchStatus} Successfully`,
  //       type: "alert alert-success",
  //     });
  //   } else {
  //     setMsg({
  //       msg: "Something went wrong.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // }

  const stateTInitial = [];
  const [newTStat, setNewTStat] = useState(stateTInitial);

  const deactivateManagementPosition = (ID, IsActive) => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "US",
      UserID: UserID,
      TypeID: ID,
      Status: IsActive,
    };
    if (IsActive === 1) {
      dataForm.Status = 0;
    } else {
      dataForm.Status = 1;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          managementTitleList();
          let statsN = JSON.parse(JSON.stringify(newTStat));
          let pitchStatus;

          if (dataForm.Status === 1) {
            pitchStatus = "Activated";
          } else if (dataForm.Status === 0) {
            pitchStatus = "Deactivated";
          }
          // console.log(pitchStatus);
          setNewTStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  // API to hit fetch Data

  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "U",
      MemberID: managementFormValue.user,
      ManagementTypeID: managementFormValue.position,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          managemenList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  //API to hit Management List

  const [managementList, setManagementList] = useState([]);

  useEffect(() => {
    managemenList();
  }, [reload]);

  // const managemenList = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     repoOpt: "management",
  //     repoID1: ToleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setManagementList(resp.dataList)
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };

  const managemenList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.MgmtLst ? resp.MgmtLst : "";
          setLoading(false);
          setShowMgmt(true);
          setManagementList(postResult);
        } else {
          setManagementList([]);
        }
      });
    });
  };

  //Edit Management

  const handleEdit = (datas) => {
    setEditPop(true);
    setManagementFormValue({
      position: datas.ManagementTypeID,
      user: datas.MemberID,
    });
  };

  // To delete a management position
  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  const handleDelete = (data) => {
    setACData(data.MemberID);
    setDelPopup({
      show: true,
      data,
    });
  };
  const handleDeleteTrue = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "U",
      MemberID: Acdata,
      ManagementTypeID: "-1",
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/management`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          managemenList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
          setDelPopup({
            show: false,
            data: null,
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };

  //API to hit push notification

  const ManagementPush = () => {
    const listForm = {
      Title: "management test",
      Body: "management test",
      UserID: 0,
      Topic: ToleID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listForm),
    };
    fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          // console.log(resp);
        });
      }
    );
  };

  return (
    <ManagementContext.Provider
      value={{
        popup,
        setPopup,
        image,
        setImage,
        managementFormValue,
        setManagementFormValue,
        managementvalue,
        managementFormError,
        setManagementFormError,
        isSubmit,
        setIsSubmit,
        managementTypePop,
        setManagementTypePop,
        data,
        setData,
        positionFormError,
        setPositionFormError,
        positionSubmit,
        setPositionSubmit,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        loading,
        setLoading,
        editIsSubmit,
        setEditIsSubmit,
        editPop,
        setEditPop,
        update,
        setUpdate,
        mgmtTitle,
        deactivateManagementPosition,
        reload,
        setReload,
        ManagementPush,
        pushNotice,
        setPushNotice,
        handleEdit,
        setCloseChecked,
        closeChecked,
        managemenList,
        managementList,
        managementTitleList,
        fetchdata,
        delPopup,
        handleDelete,
        handleDeleteTrue,
        handleDeleteFalse,
        mgmtDropDown,
      }}
    >
      {props.children}
    </ManagementContext.Provider>
  );
}

export default ManagementState;
