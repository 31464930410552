import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import IncomeContext from "./IncomeContext";
import UseHttp from '../../hooks/Use-http';
import { Fetchdata } from "../../hooks/getData";


function IncomeState(props) {

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const ToleID = userinfo.ToleID;
    const UserID = userinfo.UserID
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { setMsg, urll } = useContext(SidebarContext);
    const { sendRequest } = UseHttp();
    const [loading, setLoading] = useState(true);


    const [popup, setPopup] = useState(false);
    const [incomeFormError, setIncomeFormError] = useState({});
    const [incomeSubmit, setIncomeSubmit] = useState(false);
    const [reload, setReload] = useState(false);

    const [chooseOption, setChooseOption] = useState("-1")

    const incomevalue = {
        member: "",
        nepaliDate: "",
        receiptNumber: "",
        amount: "",
        receipt: "",
        particular: "",
        isChecked: false,
    };
    const [incomeFormValue, setIncomeFormValue] = useState(incomevalue);
    const [closeChecked, setCloseChecked] = useState(false)
    const [pushNotice, setPushNotice] = useState(false);


    //date value for account
    const datevalue = {
        fromDate: "",
        toDate: "",
    };
    const [dateFormValue, setDateFormValue] = useState(datevalue);

    //date value for account ends

    //date value for account summary
    const sumdatevalue = {
        fromDate: "",
        toDate: "",
    };
    const [sumDateFormValue, setSumDateFormValue] = useState(sumdatevalue);
    //date value for account summary ends

    //API to get account List 

    const [incomeList, setIncomeList] = useState([]);

    useEffect(() => {
        incomeListShown();
    }, [chooseOption, dateFormValue.fromDate || dateFormValue.toDate, dateFormValue.toDate, dateFormValue.fromDate])


    // const accountListShown = () => {
    //     const listForm = {
    //         ToleID: ToleID,
    //         repoOpt: "acindividualweb",
    //         repoID1: chooseOption,
    //         dtFrom: dateFormValue.fromDate,
    //         dtUpto: dateFormValue.toDate,
    //         DateTime: "2022/02/03 03:00 AM",
    //         Flag: "N"

    //     };
    //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    //     var encrypted = CryptoJS.AES.encrypt(
    //         JSON.stringify(listForm),
    //         key,
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );
    //     const dts = {
    //         param: encrypted.toString(),
    //     };
    //     console.log(dts)
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             "Content-Type": "application/json",
    //             Signature: apisignature,
    //         },
    //         body: JSON.stringify(dts)
    //     };
    //     fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
    //         .then((result) => {
    //             result.json().then((resp) => {
    //                 console.log(resp)
    //                 if (resp.STATUS_CODE === "0") {
    //                     setAccountList(resp.dataList);
    //                     setLoading(false)
    //                 }
    //             })
    //         })
    // };

    const incomeListShown = () => {
        const params = {
            ToleID: ToleID,
            Flag: "S",
            UserID: UserID,
            MemID: -1,  //chooseOption
            // FromDate: dateFormValue.fromDate,
            // ToDate: dateFormValue.toDate,
            AccType: chooseOption,
            Type: "POST",
            FetchURL: `${urll}/admin/inc-exp`,
        };

        Fetchdata(params).then(function (result) {
            // console.log(result)
            if (result.StatusCode === 200) {
                const postResult = result.IncExpLst ? result.IncExpLst : "";
                setLoading(false);
                setIncomeList(postResult);

            } else {
                setIncomeList([]);
            }
        });
    }




    const [delPopup, setDelPopup] = useState({
        show: false, // initial values set to false and null
        data: null,
    });
    const [todos, setToDos] = useState([]);

    const [Acdata, setACData] = useState()


    // To delete the Account List




    const handleDelete = (data) => {

        setACData(data)

        setDelPopup({
            show: true,
            data,
        });

    };

    // This will perform the deletion and hide the Confirmation Box

    // const handleDeleteTrue = async () => {

    //     const dataForm = {
    //         ToleID: ToleID,
    //         userID: UserID.toString(),
    //         tblOpt: "30",
    //         tblField: "status",
    //         tblValues: "0",
    //         tblID: Acdata,
    //         validationFields: "-",
    //         fieldforList: "-",
    //         orderbyField: "-",
    //         DateTime: "2022/02/03 03:00 AM",
    //         Flag: "Y"
    //     }
    //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    //     var encrypted = CryptoJS.AES.encrypt(
    //         JSON.stringify(dataForm),
    //         key, //dataForm
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );

    //     const dts = {
    //         param: encrypted.toString(),
    //     };
    //     const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Signature: apisignature,
    //         },
    //         body: JSON.stringify(dts), //dts
    //     });
    //     const tole = await response.json();
    //     console.log(tole);

    //     if (tole.STATUS_CODE === "0") {
    //         setReload(!reload)
    //         accountListShown();
    //         setMsg({
    //             msg: tole.MESSAGE,
    //             type: "alert alert-success",
    //         });
    //     } else if (tole.STATUS_CODE === "1") {
    //         setMsg({
    //             msg: tole.MESSAGE,
    //             type: "alert alert-danger",
    //         });
    //     }
    //     setDelPopup({
    //         show: false,
    //         data: null,

    //     });
    // };

    const handleDeleteTrue = () => {
        const dataForm = {
            ToleID: ToleID,
            UserID: UserID,
            Flag: "R",
            AccID: Acdata,
            // Status: "0",
            Type: "POST",
            FetchURL: `${urll}/admin/inc-exp`,
        }
        Fetchdata(dataForm).then(function (result) {
            // console.log(result);
            if (result.StatusCode === 200) {
                incomeListShown();
                setMsg({
                    msg: result.Message,
                    type: "alert alert-success",
                });
            } else {
                setMsg({
                    msg: result.Message,
                    type: "alert alert-danger",
                });
            }
        });
    }

    // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {
        setDelPopup({
            show: false,
            data: null,
        });
    };


    //API to hit push notification


    const IncomePush = () => {
        const listForm = {
            Title: "ledger test",
            Body: "ledger test",
            UserID: 0,
            Topic: ToleID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(listForm)
        };
        fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions)
            .then((result) => {
                result.json().then((resp) => {
                    // console.log(resp)
                })
            })
    }


    return (
        <IncomeContext.Provider
            value={{
                popup,
                setPopup, incomeFormError, setIncomeFormError, incomeSubmit, setIncomeSubmit, incomeFormValue, setIncomeFormValue, incomevalue, closeChecked, setCloseChecked, reload, setReload, chooseOption, setChooseOption, dateFormValue, setDateFormValue, sumDateFormValue, setSumDateFormValue, sumdatevalue, handleDelete, loading, incomeList, incomeListShown, IncomePush, pushNotice, setPushNotice, handleDeleteTrue, handleDeleteFalse, delPopup
            }}
        >
            {props.children}
        </IncomeContext.Provider>
    );
}

export default IncomeState;
