import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import NoticeContext from "../contextFolder/NoticeContextFolder/NoticeContext";
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import { Fetchdata } from "../hooks/getData";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function NoticeEdit(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const memcontext = useContext(MemberContext);
  const { memberList } = memcontext;

  const { setMsg, urll, popup, setPopup } = useContext(SidebarContext);

  const context = useContext(NoticeContext);

  const {
    noticeFormValue,
    setNoticeFormValue,
    noticeFormError,
    setNoticeFormError,
    image,
    setImage,
    isUploaded,
    setIsUploaded,
    setTypeFile,
    setPushNotice,
    setEditPop,
    noticeTitleList,
    pushNotice,
    NoticePushAll,
    NoticePushindividual,
    editIsSubmit,
    setEditIsSubmit,
    titleID,
    closeChecked,
    setCloseChecked,
    date,
    setDate,
    chooseMember,
    chooseOption
  } = context;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setNoticeFormValue({ ...noticeFormValue, [name]: value });
  };

  const handleDate = ({ bsDate }) => {
    setNoticeFormValue({ ...noticeFormValue, date: bsDate });
    setDate(Math.random());
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  console.log(noticeFormValue);
  const [newValue, setNewValue] = useState("member");
  // API to submit edit data in notification form
  const obj = {
    title: noticeFormValue.title,
    date: noticeFormValue.date,
    description:
      noticeFormValue.description,
    user_id:
      noticeFormValue.people === "-1" ? noticeFormValue.people : newValue,
    ac_btn_name:
      noticeFormValue.actionBtn,
    ac_btn_url:
      noticeFormValue.actionUrl ,
      members:noticeFormValue.member,
  };

  // const obj1 = Object.values(obj);
  // const obj2 = obj1.join("##");
  // console.log(obj2);
  // console.log(titleID)

  // const fetchdata = async () => {
  //     const dataForm = {
  //         ToleID: ToleID,
  //         userID: UserID.toString(),
  //         tblOpt: "12",
  //         tblField: "title,description,date,image,ac_btn_name,ac_btn_url",
  //         tblValues: obj2,
  //         tblID: titleID.toString(),
  //         validationFields: "-",
  //         fieldforList: "-",
  //         orderbyField: "-",
  //         DateTime: "2022/02/03 03:00 AM",
  //         Flag: "Y"
  //     }
  //     console.log(typeof (dataForm.tblID))

  //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //     var encrypted = CryptoJS.AES.encrypt(
  //         JSON.stringify(dataForm),
  //         key, //dataForm
  //         {
  //             iv: iv,
  //             mode: CryptoJS.mode.CBC,
  //             padding: CryptoJS.pad.Pkcs7,
  //         }
  //     );

  //     const dts = {
  //         param: encrypted.toString(),
  //     };
  //     console.log("encplogin", dts);
  //     const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             Signature: apisignature,
  //         },
  //         body: JSON.stringify(dts), //dts
  //     });
  //     const tole = await response.json();
  //     console.log(tole);

  //     if (tole.STATUS_CODE === "0") {
  //         noticeTitleList();
  //         setMsg({
  //             msg: "Successfully Edited",
  //             type: "alert alert-success",
  //         });
  //     }
  // };

  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "U",
      UserID: UserID,
      NotificationID: titleID,
      // MemID: chooseOption === "member" ? chooseMember : "1",
      MemID:obj.members,
      Title: obj.title,
      Description: obj.description,
      //Date: obj.date,
      // Image: image !== null ? image.split(",")[1] : "",
      // AcBtn: obj.ac_btn_name,
      // AcUrl: obj.ac_btn_url,
      FetchURL: `${urll}/admin/notification`,
      Type: "POST",
    };
     console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        noticeTitleList();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const formSubmit = (e) => {
    console.log("here")
    e.preventDefault();
    setNoticeFormError(validate(noticeFormValue));
    setEditIsSubmit(true);
    setEditPop(false);
  };

  useEffect(() => {
    if (Object.keys(noticeFormError).length === 0 && editIsSubmit) {
      fetchdata(noticeFormValue, image);
      // if (pushNotice === true) {
      //   if (noticeFormValue.people === "-1") {
      //     NoticePushAll();
      //   } else {
      //     console.log(noticeFormValue.people, newValue);
      //     NoticePushindividual();
      //   }
      // }
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(200);
        setEditPop(false);
      }
      // setNewValue("member");
      setEditIsSubmit(false);
      setPushNotice(false);
    }
  }, [noticeFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopups").fadeOut(100);
    setEditPop(false);
    setNoticeFormError({});
    setEditIsSubmit(false);
    setIsUploaded(false);
    setImage(null);
    setNewValue("member");
    setPushNotice(false);
    setDate(Math.random());
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required";
    }
    // if (!values.member) {
    //   errors.member = "Required";
    // }

    if (!values.description) {
      errors.description = "Required";
    }
    // if (!values.people) {
    //   errors.people = "Required";
    // }
    // if (!values.people) {
    //   errors.people = "Required";
    // }

    return errors;
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <div className="container-fluid basicform">
        <form>




{/*         
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="purpose">
                Select Purpose
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="people"
                id="-1"
                value="-1"
                onChange={handleChange}
                onClick={() => setNewValue("member")}
                checked={noticeFormValue.people === "-1"}
              />
              <label
                className="label-radio"
                htmlFor="-1"
                style={{ marginRight: "10px" }}
              >
                All
              </label>
              <input
                type="radio"
                name="people"
                id="member"
                onChange={handleChange}
                value={newValue}
                checked={noticeFormValue.people === "member"}
              />
              <label className="label-radio" htmlFor="member">
                Member
              </label>
            </div>
          </div> */}

          {noticeFormValue.people === "member" && (
            <div className="form-group">
              <label className="form-label" htmlFor="category">
                Select Member<sup className="sup-col">*</sup>
              </label>
              <select
                id="slug-title"
                name="member"
                value={noticeFormValue.member}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                onClick={(e) => {
                  setNewValue(e.target.value);
                   console.log(e.target.value);
                }}
              >
                <option defaultValue="Select Event Title">Select Member</option>
                {memberList.map((item) => (
                  <option key={item.MemberID} value={item.MemberID}>
                    {item.MemberName}
                  </option>
                ))}
              </select>
               <p className="errormsg ">{noticeFormError.member}</p>
              </div> 
           )}  

          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="title">
                Title<sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={noticeFormValue.title}
                onChange={handleChange}
                name="title"
                className="form-control form-control-sm mb-1"
                id="title"
              />
              <p className="errormsg ">{noticeFormError.title}</p>
            </div>





            {/* <div className="form-group col-md-6">
              <label className="form-label" htmlFor="inputDate">
                Date
              </label>

              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={noticeFormValue.date}
                hideDefaultValue={true}
                placeholder={noticeFormValue.date}
                onChange={handleDate}
                key={date}
              />
            </div> */}
          </div>

          <div className="form-group  ">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label"
              placeholder="description"
            >
              Description<sup className="sup-col">*</sup>
            </label>
            <textarea
              className="form-control"
              name="description"
              value={noticeFormValue.description}
              onChange={handleChange}
              id="exampleFormControlTextarea1"
              rows="3"
              style={{ fontSize: "12px" }}
            ></textarea>
            <p className="errormsg ">{noticeFormError.description}</p>
          </div>

          {/* <div className="form-row">
                        <div className="form-group col-md-6  ">
                            <label className="form-label" htmlFor="text">
                                Action Button
                            </label>

                            <select
                                id="gender"
                                name="actionBtn"
                                onChange={handleChange}
                                value={noticeFormValue.actionBtn}
                                className="form-control form-control-sm mb-1"
                                style={{ fontSize: "12px" }}
                            >
                                <option value="none" selected>
                                    Action Button
                                </option>
                                <option value="join">Join</option>
                                <option value="submit"> Submit</option>
                                <option value="register"> Register</option>
                                <option value="website"> Visit Website</option>
                                <option value="more"> Visit More</option>
                            </select>
                        </div>

                        <div className="form-group col-md-6  ">
                            <label className="form-label" htmlFor="text">
                                Action Url
                            </label>
                            <input
                                type="text"
                                className="form-control form-control-sm mb-1"
                                onChange={handleChange}
                                value={noticeFormValue.actionUrl}
                                id="text"
                                name="actionUrl"
                            />
                        </div>
                    </div> */}

          <div className="form-group  ">
            <div className="form-label" htmlFor="text">
              Upload Image
            </div>

            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                      {/* <p style={{ color: "#444" }}>Click to upload image</p> */}
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 notificationFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
