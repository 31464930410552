import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import SliderContext from "../contextFolder/SliderContextFolder/SliderContext";
import { showImgPreview } from "../hooks/ImagePreview";
import SliderDelete from "./SliderDelete";

import SidebarContext from "../sidebarcontext/SidebarContext";



const SliderTable = ({ searchTerm }) => {
  const {
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);
  const {
    slideList,
    handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
    handleDelete,
    deactivateSlider
  } = useContext(SliderContext);
  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  //For Status
  const changeStatus = (SliderID, IsActive) => {
    deactivateSlider(SliderID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };

  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (slideList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "370px",
            }}
          >
            <div>
            
              <div ref={ref}>
                <table ref={componentRef} className="table" id="table-to-xls">
                  <thead>
                    <tr className="tableHead">
                      <td style={tableHeadBg}>S.N.</td>
                      <td style={tableHeadBg}>Image</td>
                    
                      <td style={tableHeadBg}>Title</td>
                      <td style={tableHeadBg}>Order</td>
                   
                      <td style={tableHeadWidthBg}> Status</td>
                      <td style={tableHeadWidthBg}> Action</td>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {slideList
                      .filter((val) => {
                        if (searchTerm === "") {
                          return val;
                        } else if (
                          val.Title.toLowerCase().includes(
                            searchTerm.toLowerCase()
                          )
                        ) {
                          return val;
                        }
                      })
                      .map((item, i) => (
                        <tr key={i}>
                          <td style={tableDataBg}>{i + 1}</td>
                          <td style={tableDataBg} className="contentLogo ">
                            <div className="mgmtImg ">
                              <img
                                src={item.ImgUrl}
                                alt="slider"
                                onClick={() => {
                                  setImagePre(item.ImgUrl);
                                  setImgPrv(true);
                                }}
                              />{" "}
                            </div>
                          </td>
                          <td style={tableDataBg}>{item.Title}</td>
                          <td style={tableDataBg}>{item.Imgorder}</td>
                          


                          <td style={tableDataBg}>
                            <span
                              className="rejectspan badge"
                              onClick={() =>
                                changeStatus(item.SliderID, item.IsActive)
                              }
                              style={darkText}
                            >
                             {checkStatus(item.IsActive)}
                            </span>

                          </td>



                          <td style={tableDataBg}>
                            <span
                              className="rejectspan badge"
                              onClick={() => {
                                handleDelete(item.SliderID);
                              }}
                              style={darkText}
                            >
                              Remove
                            </span>

                          </td>

                          
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {imgPrv &&
          showImgPreview({
            img: imagePre,
            setTrigger: setImgPrv,
          })}

        {delPopup.show && (
          <SliderDelete
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
           
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>S.N.</td>
                    <td style={tableHeadBg}>Image</td>
                    <td style={tableHeadBg}>Title</td>
                    {/* <td style={tableHeadBg}>Order</td> */}
                    <td style={tableHeadWidthBg}> Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default SliderTable;
