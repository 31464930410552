import React,{useCallback, useState} from 'react'

export default function UseHttp() {
    const [isLoading, setIsLoading] = useState(false)
    const[error,setError] = useState(null);
    
    const sendRequest = useCallback(async(requestConfig,applyData) => {
        setIsLoading(true);
        setError(null);
        try{
            const response = await fetch(
                requestConfig.url,{
                    method:requestConfig.method,
                    headers:requestConfig.headers,
                    body:JSON.stringify(requestConfig.body)
                }
            );
       if(!response.ok){
           throw new Error('Request failed');
       }
       const data = await response.json();
       applyData(data);
        }
        catch(err){
            setError(err.message || 'Something went wrong!');
        }
        setIsLoading(false);
    },[])
  return  { isLoading,
    error,
    sendRequest,
};
}
