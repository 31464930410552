import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from "../hooks/UsewindowDimension";
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ProfessionForm from "./ProfessionForm";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import "../../css/imagePreview.css";
import RelationPopupForm from "./RelationPopupForm";

// const newName = [
//   {
//     id: 1,
//     index: "",
//     name: "",
//   },
// ];
export default function RelationPopup(props) {
  const [last, setLast] = useState(false);
  const context = useContext(ProfessionContext);

  // const {
    // setRelationName,
    // setAddRelationSubmit,
    // setAddRelationFormError,
    // setUpdate,
  // } = context;
  const { height } = UsewindowDimension();

  const closePopup = (e) => {
    e.preventDefault();
    // setProfessionFormValue(professionvalue);
    props.setRelationPopup(false);
    $(".relationTopicPop").fadeOut(100);
    // setAddRelationSubmit(false);
    // setRelationName({ name: "" });
    // setAddRelationFormError({});
    // setUpdate(false);
    // setProfessionFormError({});
    // setProfessionSubmit(false);
  };

  useEffect(() => {
    if (props.relationPopup) {
      $(".relationTopicPop").fadeIn(100);
    }
  }, [props.relationPopup]);

  return (
    <div className="complainPopUp relationTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "inside--prof ip500" : "inside--prof"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Add Relation</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>
        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="notice-outlet">
          <RelationPopupForm />
        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  );
}
