import React, { useContext, useState } from 'react'
import '../../css/Complain.css';
import ChhimekiContext from '../contextFolder/ChhimekiContextFolder/ChhimekiContext';
import Spinner from '../Spinner/Spinner';
import ChhimekiPopUp from './ChhimekiPopUp';
import ChhimekiTable from './ChhimekiTable';



function Chhimeki() {

    const context = useContext(ChhimekiContext)
    const { popup, setPopup, setChhimekiFormValue, chhimekiFormValue, chhimekivalue, loading, setLoading, setCloseChecked } = context;


    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
    };


    const [searchTerm, setSearchTerm] = useState("");



    const handleAddChhimeki = (e) => {
        console.log("true")
        e.preventDefault();
        setPopup(true);
        setCloseChecked(false)
        setChhimekiFormValue(chhimekivalue);
    }



    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 contentMainSection">
                <div>
                    <div className="mainHeader">
                        <div className="fontHeader">Chhimeki List</div>

                    </div>
                    <hr />
                </div>
                <section className="content-section main-content">
                    <div className="content">
                        <div className=" col-lg-12 col-sm-12">

                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                                    <div className="dashboard-search-field">
                                        <input type="text" placeholder="Search" value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                            }} style={{ border: "none" }} />
                                        <i style={{ paddingTop: "7px", paddingRight: "5px", color: "#999" }} className="fas fa-search searchIconPosition"></i>
                                    </div>
                                </div>

                                <div className="col-lg-6 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                                    <button className="btn btn-cmpy btn-sm" onClick={handleAddChhimeki}> Add Chhimeki +</button>
                                </div>

                            </div>
                        </div>
                        {<div className="row">
                            <div className="">
                                <div className="col-lg-12 p-1">
                                    {/* {loading ? <Spinner /> : ( */}
                                    <ChhimekiTable searchTerm={searchTerm} />
                                    {/* )} */}

                                </div>
                            </div>

                        </div>}


                    </div>
                </section>
            </div>

            <ChhimekiPopUp chhimekiTrigger={popup} setChhimekiTrigger={setPopup} />
        </>
    )
}

export default Chhimeki