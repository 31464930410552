import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from "../hooks/UsewindowDimension";
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ProfessionForm from "./ProfessionForm";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import "../../css/imagePreview.css";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function StreetPopupForm(props) {
  const { setMsg, URL, urll, mode, tableHeadBg, tableDataBg, darkText } =
    useContext(SidebarContext);
  const context = useContext(ProfessionContext);

  // const {
    // setStreetName,
    // streetName,
    // setAddStreetPopup,
    // tableData4,
    // update4,
    // setUpdate4,
    // addStreetSubmit,
    // setAddStreetSubmit,
    // addStreetFormError,
    // setAddStreetFormError,
    // reload4,
    // setReload4,
    // deactivateAddStreet,
    // streetTableDataShown,
  // } = context;
  const { height } = UsewindowDimension();

  const [searchTerm, setSearchTerm] = useState("");

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID;

  function handle(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    // setStreetName({ ...streetName, [name]: value });
  }

  const obj = {
    tole_id: ChhimekiID,
    // name: streetName.name,
    status: 1,
  };

  const fetchdata = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      UserID: UserID,
      Flag: "i",
      TFlag: "S",
      // Name: streetName.name,
    };
    console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/values`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          console.log("res", resp);
          // streetTableDataShown();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const formSubmit = (e) => {
    // setAddStreetFormError(validate(streetName));
    // setAddStreetSubmit(true);
    // setAddStreetPopup(false);
  };

  // useEffect(() => {
  //   if (Object.keys(addStreetFormError).length === 0 && addStreetSubmit) {
  //     fetchdata(streetName);
  //     setReload4(!reload4);
  //     setStreetName({ name: "" });
  //     setAddStreetSubmit(false);
  //   }
  // }, [addStreetFormError]);

  const [titleID, setTitleId] = useState(null);

  function selectUser(datas) {
    // setStreetName({
    //   name: datas.Name,
    // });
    setTitleId(datas.ID);
    // setUpdate4(true);
  }

  const updateUser = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      UserID: UserID,
      Flag: "U",
      TFlag: "S",
      ID: titleID,
      // Name: streetName.name,
    };
    console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/values`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          // streetTableDataShown();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Required";
    }
    return errors;
  };

  const changeStatus = (posID, IsActive) => {
    // deactivateAddStreet(posID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <div className="form-group  ">
          <label className="form-label" htmlFor="slug">
            Name <sup className="sup-col">*</sup>
          </label>
          {/* <input
            type="text"
            name="name"
            onChange={(e) => handle(e)}
            value={streetName.name}
            autoComplete="off"
            className="form-control form-control-sm mb-1"
            id="name"
          /> */}
          {/* <p className="errormsg ">{addStreetFormError.name}</p> */}
        </div>
        <div className="row px-2">
          <div className="col-lg-3 col-md-6 col-sm-6 p-2 Search">
            <div className="dashboard-search-field">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
                style={{ border: "none" }}
              />
              <i
                style={{
                  paddingTop: "7px",
                  paddingRight: "5px",
                  color: "#999",
                }}
                className="fas fa-search searchIconPosition"
              ></i>
            </div>
          </div>

          <div className="col-lg-5 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
            {/* {update4 ? (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={updateUser}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-sm btn-cmpy "
                style={{ background: "0079bf" }}
                onClick={formSubmit}
              >
                Save
              </button>
            )} */}
          </div>
        </div>
      </div>

      <div
        className="table-wrapper px-3"
        style={{
          overflowX: "scroll",
          overflowY: "scroll",
          margin: "3px",
          height: "260px",
        }}
      >
        <table className="table mt-3">
          <thead>
            <tr className="tableHead">
              <td style={tableHeadBg} className="tl">
                S.N.
              </td>
              <td className="tl ps-3" style={tableHeadBg}>
                Name
              </td>
              <td style={tableHeadBg}> Action</td>
            </tr>
          </thead>

          <tbody>
            {/* {tableData4
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.Name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((data, index) => {
                return (
                  <tr key={index}>
                    <td style={tableDataBg}>{index + 1}</td>
                    <td className="ps-3" style={tableDataBg}>
                      {data.Name}
                    </td>
                    <td style={tableDataBg}>
                      <span
                        className="rejectspan badge"
                        onClick={() => selectUser(data)}
                        style={darkText}
                      >
                        Edit
                      </span>{" "}
                      <span
                        className="editspan badge"
                        onClick={() => changeStatus(data.ID, data.Status)}
                        style={darkText}
                      >
                        {checkStatus(data.Status)}
                      </span>
                    </td>
                  </tr>
                );
              })} */}
          </tbody>
        </table>
      </div>
    </>
  );
}
