import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Member.css";
import LedgerContext from "../contextFolder/LedgerContextFolder/LedgerContext";

export default function EditLedger(props) {
  const { height } = UsewindowDimension();
  const {
    ledgerFormValue,
    setLedgerFormValue,
    ledgerFormError,
    setLedgerFormError,
    perEditSubmit,
    setPerEditSubmit,
    closeChecked,
    setCloseChecked,
    ledgervalue,
    editdata,
    setViewPop,
  } = useContext(LedgerContext);

  const closePopup = (e) => {
    e.preventDefault();

    $(".viewHelplinePop").fadeOut(100);
    setLedgerFormValue(ledgervalue);
    setLedgerFormError({});
    setPerEditSubmit(false);
    setCloseChecked(false);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setLedgerFormValue({ ...ledgerFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    // if (!values.service) {
    //   errors.service = "Required";
    // }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.acctype) {
      errors.acctype = "Required";
    }
    if (!values.receiptNumber) {
      errors.receiptNumber = "Required";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLedgerFormError(validate(ledgerFormValue));
    // console.log(validate(ledgerFormValue))
    setPerEditSubmit(true);

  };
  
  
  useEffect(() => {
    console.log(ledgerFormValue);
    if (Object.keys(ledgerFormError).length === 0 && perEditSubmit) {
      
      editdata(ledgerFormValue);
      console.log(ledgerFormValue);
      // if (closeChecked === true) {
        $(".viewHelplinePop").fadeOut(100);
        setViewPop(false);
      // }
      setLedgerFormError({});
      setLedgerFormValue(ledgervalue);
      setPerEditSubmit(false);
    }
  }, [ledgerFormError]);


  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">


          {/* <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="member">
                Member <sup className="sup-col">*</sup>
              </label>

              <select disabled
                id="member"
                name="member"
                onChange={handleChange}
                value={ledgerFormValue.member}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Member
                </option>
                {memberDrop.map((item) => (
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{ledgerFormError.member}</p>
            </div> */}


            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="acctype">
                Type <sup className="sup-col">*</sup>
              </label>

              <select
                id="acctype"
                name="acctype"
                onChange={handleChange}
                value={ledgerFormValue.acctype}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Type
                </option>
               
                  <option value="Deposit">
                   Deposit
                  </option>
                  <option value="Loan Saving">
                   Loan Saving
                  </option>
             
              </select>
              <p className="errormsg ">{ledgerFormError.acctype}</p>
            </div>


            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="name">
                Account Name <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="name"
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="name"
                value={ledgerFormValue.name}
              />
              <p className="errormsg ">{ledgerFormError.name}</p>
            </div>


            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="receiptNumber">
                Account Number <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="receiptNumber"
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="receiptNumber"
                value={ledgerFormValue.receiptNumber}
              />
              <p className="errormsg ">{ledgerFormError.receiptNumber}</p>
            </div>


          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div class=" notification-form-check" style={{ display: "flex" }}>
          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
