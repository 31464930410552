import React, { useContext, useEffect, useState } from "react";
import NoticeContext from "./NoticeContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";

function NoticeState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const apisignature = process.env.REACT_APP_SIGNATURE;

  const [popup, setPopup] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newValue, setNewValue] = useState("member");

  const [noticeFormError, setNoticeFormError] = useState({});
  const [noticeSubmit, setNoticeSubmit] = useState(false);

  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [reload, setReload] = useState(false);

  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);
  

  const noticevalue = {
    people: "-1",
    member: "",
    title: "",
    description: "",
    actionBtn: "",
    actionUrl: "",
    date: "",
    isChecked: false,
  };
  const [noticeFormValue, setNoticeFormValue] = useState(noticevalue);

  const [pushNotice, setPushNotice] = useState(false);

  const [chooseOption, setChooseOption] = useState("1");
  // console.log(chooseOption)

  const [chooseMember, setChooseMember] = useState("1");

  //Edit Notification

  const [titleID, setTitleId] = useState(null);
  const [date, setDate] = useState();

  const handleEdit = (datas) => {
    // setImage(datas.NotByImage);
    setTitleId(datas.NotificationID);
    setEditPop(true);
    setNoticeFormValue({
      title: datas.Title,
      description: datas.Description,
      date: datas.NotDate,
    });
    console.log(datas.NotificationID);
    setDate(Math.random());
  };

  // API to hit Notification Info
  // useEffect(() => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     tblOpt: "12",
  //     tblField: "ID NotificationID,user_id UserID,title Title,description Description,date Date,image Image,ac_btn_name AcBtn,ac_btn_url AcUrl",
  //     conditionField: "ID",
  //     conditionValues: titleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   var encryptedData = encrypted.toString();

  //   const dataToSend = {
  //     param: encryptedData,
  //   };
  //   const eventTitleList = async (dataToSend) => {

  //     const response = await fetch(
  //       `${URL}/toleAPI/api/selectdatawithcondition`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Signature: apisignature,
  //         },
  //         body: JSON.stringify(dataToSend),
  //       }
  //     );
  //     const getData = await response.json();
  //     return getData;
  //   };

  //   eventTitleList(dataToSend).then(function (result) {
  //     const code = CryptoJS.AES.decrypt(result.MESSAGE, key, {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     });
  //     const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //     console.log(response)
  //     if (response.STATUS_CODE === "0") {
  //       const data = response.dataList[0]

  //       setNoticeFormValue({
  //         people: data.UserID,
  //         member: data.UserID,
  //         title: data.Title,
  //         description: data.Description,
  //         actionBtn: data.AcBtn,
  //         actionUrl: data.AcUrl,
  //       });
  //       setImage(data.Image)
  //     }
  //   });
  // }, [titleID]);

  // useEffect(() => {
  //   easyList();
  // }, [titleID]);

  const easyList = () => {
    const params = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SI",
      NotificationID: titleID,
      FetchURL: `${urll}/admin/notification`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.notifyRes[0] ? result.notifyRes[0] : "";
        // console.log(data, "amam");
        // setNoticeFormValue({
        //   people: data.MemberID.toString(),
        //   member: data.MemberID,
        //   title: data.Title,
        //   description: data.Description,
        //   actionBtn: data.AcBtn,
        //   actionUrl: data.AcUrl,
        //   date: data.Datel,
        // });
        // setImage(data.Image);
      } else {
        // console.log("Fetch error!");
      }
    });
  };

  //API to hit notification list

  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    noticeTitleList();
  }, [chooseMember, chooseOption,titleID]);

  // const noticeTitleList = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     repoOpt: "recentnotice",
  //     repoID1: ToleID,
  //     repoID2: chooseOption === "member" ? chooseMember : "-1",
  //     // repoID2: "1",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setNoticeList(resp.dataList)
  //           setLoading(false)
  //         }
  //         else if (resp.STATUS_CODE === "1") {
  //           setNoticeList({})
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };

  const noticeTitleList = () => {
    const params = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "S",
      MemID: chooseOption,
      // MemID:chooseOption === "member" ? chooseMember : "0",
      // Status: "-1",
      // NotificationID: titleID,
      Type: "POST",
      FetchURL: `${urll}/admin/notification`,
    };


    Fetchdata(params).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        const postResult = result.NotificationLst ? result.NotificationLst : "";
        // setNoticeFormValue({
        //   people: postResult.MemID,
        //   member: "1",
        //   title: postResult.Title,
        //   description: postResult.Description,
        //   actionBtn: postResult.AcBtn,
        //   actionUrl: postResult.AcUrl,
        //   date: postResult.NotDate,
        // });
        // setImage(postResult.Image);
        setLoading(false);
        setNoticeList(postResult);
      } else {
        setNoticeList([]);
      }
    });
  };

  // API to hit Notification Status

  // const stateInitial = [];
  // const [newStat, setNewStat] = useState(stateInitial);

  // const deactivateNotice = async (NotificationID, IsActive) => {

  //   const formData = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "12",
  //     tblField: "status",
  //     tblValues: IsActive.toString(),
  //     tblID: NotificationID.toString(),
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   if (IsActive === 1) {
  //     formData.tblValues = 0
  //   } else {
  //     formData.tblValues = 1
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(formData),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();

  //   if (tole.STATUS_CODE === "0") {
  //     noticeTitleList();
  //     let statsN = JSON.parse(JSON.stringify(newStat));
  //     let pitchStatus;

  //     if (formData.tblValues === 1) {
  //       pitchStatus = "Activated";

  //     } else if (formData.tblValues === 0) {
  //       pitchStatus = "Deactivated";
  //     }
  //     console.log(pitchStatus)
  //     setNewStat(statsN)
  //     setMsg({
  //       msg: `Notification ${pitchStatus} Successfully`,
  //       type: "alert alert-success",
  //     });
  //   } else {
  //     setMsg({
  //       msg: "Something went wrong.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // }

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateNotice = (ID, IsActive) => {
    console.log(ID);
    const dataForm = {
      ToleID: ToleID,
      Flag: "US",
      UserID: UserID,
      NotificationID: ID,
      Status: IsActive,
      FetchURL: `${urll}/admin/notification`,
      Type: "POST",
    };
    if (IsActive === 1) {
      dataForm.Status = 0;
    } else {
      dataForm.Status = 1;
    }
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        noticeTitleList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.Status === 1) {
          pitchStatus = "Activated";
        } else if (dataForm.Status === 0) {
          pitchStatus = "Deactivated";
        }
        // console.log(pitchStatus);
        setNewStat(statsN);
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  //API to hit push notification
  const NoticePushAll = () => {
    const listForm = {
      Title: "notice test all",
      Body: "notice test all",
      UserID: 0,
      Topic: ToleID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listForm),
    };
    fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          // console.log(resp);
        });
      }
    );
  };


  //to delete
  const [Acdata, setACData] = useState();
  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const handleDelete = (data) => {
    setACData(data);

    setDelPopup({
      show: true,
      data,
    });
  };


  const handleDeleteTrue = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "R",
      NotificationID: Acdata,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/notification`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          noticeTitleList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
          setDelPopup({
            show: false,
            data: null,
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };


  const NoticePushindividual = () => {
    const listForm = {
      Title: "notice test",
      Body: "notice test",
      UserID: newValue,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listForm),
    };
    fetch(
      `${URL}/toleAPI/api/push-notification-by-deviceID`,
      requestOptions
    ).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
      });
    });
  };

  return (
    <NoticeContext.Provider
      value={{
        popup,
        setPopup,
        noticevalue,
        noticeFormValue,
        setNoticeFormValue,
        noticeFormError,
        setNoticeFormError,
        noticeSubmit,
        setNoticeSubmit,
        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        loading,
        setLoading,
        editPop,
        setEditPop,
        handleEdit,
        titleID,
        setTitleId,
        editIsSubmit,
        setEditIsSubmit,
        deactivateNotice,
        reload,
        setReload,
        chooseOption,
        setChooseOption,
        chooseMember,
        setChooseMember,
        noticeList,
        closeChecked,
        setCloseChecked,
        newValue,
        setNewValue,
        noticeTitleList,
        pushNotice,
        setPushNotice,
        NoticePushAll,
        NoticePushindividual,
        easyList,
        date,
        setDate,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        delPopup,
      }}
    >
      {props.children}
    </NoticeContext.Provider>
  );
}

export default NoticeState;
