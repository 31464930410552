import React, { useContext, useState, useEffect } from "react";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
// import "../../css/Complain.css";

import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import EventTitlePop from "./EventTitlePop";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function EventEdit(props) {
  const { setMsg, URL } = useContext(SidebarContext);
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;

  const context = useContext(EventContext);

  const {
    eventTitlePop,
    setEventTitlePop,
    editPop,
    setEditPop,
    basicFormErrors,
    setBasicFormErrors,
    initialvalue,
    basicFormValue,
    setBasicFormvalue,
    image,
    setImage,
    editIsSubmit,
    setEditIsSubmit,
    latitude,
    longitude,
    eventtitle,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    eventTitleID,
    eventListPop,
    eventPush,
    editdata,
    eventDropTitle,
    eventTitle,
    setReload,
    reload,
    closeChecked,
    setCloseChecked,
    pushNotice,
    setPushNotice,
    date,
    setDate,
  } = context;

  const handleDate = ({ bsDate }) => {
    setBasicFormvalue({ ...basicFormValue, date: bsDate });
    setDate(Math.random());
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setBasicFormvalue({ ...basicFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  // console.log(basicFormValue);
  const selectValue = (value) => {
    setBasicFormvalue({ ...basicFormValue, actionBtn: value.value });
  };

  const formSubmit = (e) => {
    //  console.log(basicFormErrors)
    e.preventDefault();
    setBasicFormErrors(validate(basicFormValue));
    setEditIsSubmit(true);
    setEditPop(false);
  };

  useEffect(() => {
    if (Object.keys(basicFormErrors).length === 0 && editIsSubmit) {
      editdata(basicFormValue, image);
      // if (pushNotice === true) {
      //   eventPush();
      // }
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(200);
        setEditPop(false);
       }
      // setReload(!reload);
      // setEditPop(false);
      setBasicFormErrors({});
      setBasicFormvalue(initialvalue);
      setEditIsSubmit(false);
      // setPushNotice(false);
    }
  }, [basicFormErrors]);

  const closePopup = (e) => {
    e.preventDefault();
    //  setReload(!reload);
    $(".displayPopups").fadeOut(100);
    setEditPop(false);
    // setBasicFormvalue(initialvalue);
    
    setBasicFormErrors({});
    setEditIsSubmit(false);
    setPushNotice(false);
    setIsUploaded(false);
    setImage(null);
    //setCloseChecked(false);
    
    // setDate(Math.random());
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.title) {
      errors.title = "Required";
    }
    // if (!values.category) {
    //   errors.category = "Required";
    // }
    if (!values.description) {
      errors.description = "Required";
    }
    // if (!values.date) {
    //   errors.date = "Required";
    // }

    // if (!values.time) {
    //   errors.time = "Required";
    // }
    // if (!values.time2) {
    //   errors.time2 = "Required";
    // }
    if (!values.organized) {
      errors.organized = "Required";
    }
    if (!values.venue) {
      errors.venue = "Required";
    }
    if (!values.purpose) {
      errors.purpose = "Required";
    }
    if (!values.contact) {
      errors.contact = "Required";
    } else if (!numv.test(values.contact)) {
      errors.contact = "Must be digits";
    } else if (!digits.test(values.contact)) {
      errors.contact = "Must be 10 digits";
    }
    if (values.email.length === 0) {
      setEditIsSubmit(true);
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    return errors;
  };

  const options = [
    { value: "join", label: "Join" },
    { value: "submit", label: "Submit" },
    { value: "register", label: "Register" },
    { value: "website", label: "Visit Website" },
    { value: "more", label: "Visit More" },
  ];

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // useEffect(() => {
  //     var allFields = document.querySelectorAll(".form-control");
  //     allFields[0].focus();
  //     for (var i = 0; i < allFields.length; i++) {
  //         allFields[i].addEventListener("keyup", function (event) {
  //             if (event.keyCode === 13) {

  //                 event.preventDefault();

  //                 if (this.parentElement.nextElementSibling.querySelector("input")) {
  //                     this.parentElement.nextElementSibling.querySelector('input').focus();
  //                 }
  //                 else if (this.parentElement.nextElementSibling.querySelector("select")) {
  //                     this.parentElement.nextElementSibling.querySelector('select').focus();
  //                 }
  //                 else if (this.parentElement.nextElementSibling.querySelector("button")) {
  //                     this.parentElement.nextElementSibling.querySelector('button').click();
  //                 }
  //             }

  //         }
  //         )
  //     }
  // }, [])

  // for Event Title Popup

  const handleEventTitle = (e) => {
    e.preventDefault();
    setEventTitlePop(true);
    // console.log(eventTitlePop);
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-group">
            <div>
              <label className="form-label" htmlFor="purpose">
                Select Purpose <sup className="sup-col">*</sup>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="purpose"
                id="E"
                value="E"
                onChange={handleChange}
                checked={basicFormValue.purpose == "E"}
              />
              <label
                className="label-radio"
                htmlFor="E"
                style={{ marginRight: "10px" }}
                selected
              >
                Event
              </label>

              <input
                type="radio"
                name="purpose"
                id="M"
                value="M"
                onChange={handleChange}
                checked={basicFormValue.purpose == "M"}
              />
              <label className="label-radio" htmlFor="M">
                Meeting
              </label>
            </div>
            <p className="errormsg ">{basicFormErrors.purpose}</p>
          </div>

          <div className="form-row">
            {/* <div
              className="form-group col-md-11"
              style={{ marginBottom: "0px" }}
            >
              <label className="form-label" htmlFor="category">
                Select Event Title<sup className="sup-col">*</sup>
              </label>
              <select
                id="slug-title"
                name="title"
                value={basicFormValue.title}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Event Title
                </option>
                {eventDropTitle.map((item) => (
                  <option value={item.TitleID}>{item.Title}</option>
                ))}
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{basicFormErrors.title}</p>
            </div> */}

            {/* <div className="form-group col-md-1">
              <div className="add-title-button">
                <label className="form-label" htmlFor="button">Add </label>
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    background: "0079bf",
                    width: "50px",
                  }}
                  onClick={handleEventTitle}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div> */}
          </div>





          <div className="form-group">
            <label className="form-label" htmlFor="title">
              Title<sup className="sup-col">*</sup>
            </label>
            <input
              type="text"
              value={basicFormValue.title}
              onChange={handleChange}
              name="title"
              className="form-control form-control-sm mb-1"
              id="title"
            />
            <p className="errormsg ">{basicFormErrors.title}</p>
          </div>

          <div className="form-group  ">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Description<sup className="sup-col">*</sup>
            </label>
            <textarea
              className="form-control"
              name="description"
              value={basicFormValue.description}
              onChange={handleChange}
              id="exampleFormControlTextarea1"
              rows="3"
              style={{ fontSize: "12px" }}
            ></textarea>
            <p className="errormsg ">{basicFormErrors.description}</p>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="inputDate">
                Date From<sup className="sup-col">*</sup>
              </label>

              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={basicFormValue.date_from}
                hideDefaultValue={true}
                placeholder={basicFormValue.date_from}
                onChange={handleDate}
                key={date}
              />
              <p className="errormsg ">{basicFormErrors.date_from}</p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="inputDate">
                Date To<sup className="sup-col">*</sup>
              </label>

              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={basicFormValue.date_to}
                hideDefaultValue={true}
                placeholder={basicFormValue.date_to}
                onChange={handleDate}
                key={date}
              />
              <p className="errormsg ">{basicFormErrors.date_to}</p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="time_from">
                Time Start
              </label>
              <input
                type="time"
                name="time_from"
                value={basicFormValue.time_from}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="time"
              />
              <p className="errormsg ">{basicFormErrors.time_from}</p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="time_to">
                Time To
              </label>
              <input
                type="time"
                name="time_to"
                value={basicFormValue.time_to}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="time"
              />
              <p className="errormsg ">{basicFormErrors.time_to}</p>
            </div>
          </div>
          <div className="form-group  ">
            <label className="form-label" htmlFor="text">
              Venue<sup className="sup-col">*</sup>
            </label>
            <input
              type="text"
              name="venue"
              value={basicFormValue.venue}
              onChange={handleChange}
              className="form-control form-control-sm mb-1"
              id="text"
            />
            <p className="errormsg ">{basicFormErrors.venue}</p>
          </div>
    
          <div className="form-group  ">
            <label className="form-label" htmlFor="text">
              Organized By<sup className="sup-col">*</sup>
            </label>
            <input
              type="text"
              name="organized"
              value={basicFormValue.organized}
              onChange={handleChange}
              className="form-control form-control-sm mb-1"
              id="text"
            />
            <p className="errormsg ">{basicFormErrors.organized}</p>
          </div>
          <div className="form-row  ">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="inputEmail4">
                Email
              </label>
              <input
                type="email"
                className="form-control form-control-sm mb-1"
                value={basicFormValue.email}
                onChange={handleChange}
                name="email"
                id="inputEmail4"
                pattern=".+@globex\.com"
              />
              <p className="errormsg ">{basicFormErrors.email}</p>
            </div>
            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="inputContact">
                Contact<sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                name="contact"
                value={basicFormValue.contact}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="text"
              />
              <p className="errormsg ">{basicFormErrors.contact}</p>
            </div>
          </div>
          {/* <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label className="form-label" htmlFor="text">
                Action Button
              </label>
              <input type="contact" className="form-control form-control-sm mb-1" onChange={handleChange} value={basicFormValue.actionBtn} id="text" name="actionBtn" />
              <Select options={options} name="actionBtn" onChange={selectValue} />
            </div>
            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="text">
                Action Url
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={basicFormValue.actionUrl}
                id="text"
                name="actionUrl"
              />
            </div>
          </div> */}

          <div className="form-group  ">
            <div className="form-label" htmlFor="text">
              Upload Image
            </div>
            <div className="BoxUpload">
              <div className="image-upload">
                {!image ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="col-lg-12 notificationFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          {/* <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div> */}

          {/* <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div> */}
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <EventTitlePop
        trigger1={eventTitlePop}
        setTriggernew1={setEventTitlePop}
      />
    </>
  );
}
