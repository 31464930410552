import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import DashboardContext from "./DashboardContext";

function DashboardState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const [showEvent, setShowEvent] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [showMember, setShowMember] = useState(false);
  const [showHelpline, setShowHelpline] = useState(false);
  const [showMgmt, setShowMgmt] = useState(false);

  //   for dashboard list
  const [dashboardList, setDashboardList] = useState([]);

  useEffect(() => {
    dashList();
  }, [showEvent, showNotify, showMember]);

  const dashList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    // fetch(`${urll}/admin/dashboard`, requestOptions).then((result) => {
    //   result.json().then((resp) => {
    //     // console.log(resp);
    //     if (resp.StatusCode === 200) {
    //       const postResult = resp.dashRes ? resp.dashRes : "";
    //       setDashboardList(postResult);
    //     } else {
    //       setDashboardList([]);
    //     }
    //   });
    // });
  };

  //   For Upcoming event
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    evntList();
  }, [showEvent]);

  const evntList = () => {
    const dataForm = {
      ToleID: ToleID,
      Count: "10",
      Flag: "S",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.Eventlst ? resp.Eventlst : "";
          setEventList(postResult);
        } else {
          setEventList([]);
        }
      });
    });
  };

  //  For Upcoming birthday
  const [birthdayList, setBirthdayList] = useState([]);

  // useEffect(() => {
  //   bdList();
  // }, []);

  // const bdList = () => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     Count: "5",
  //   };
  //   // console.log(dataForm);

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(dataForm),
  //   };
  //   fetch(`${urll}/bday-list`, requestOptions).then((result) => {
  //     result.json().then((resp) => {
  //       // console.log(resp);
  //       if (resp.StatusCode === 200) {
  //         const postResult = resp.BirthdayLst ? resp.BirthdayLst : "";
  //         setBirthdayList(postResult);
  //       } else {
  //         setBirthdayList([]);
  //       }
  //     });
  //   });
  // };

  //   For helpline
  const [helplineList, setHelplineList] = useState([]);
  // useEffect(() => {
  //   getHelpline();
  // }, [showHelpline]);
  // const getHelpline = () => {
  //   fetch(`${urll}/helpline?ToleID=${ToleID}`, {
  //     method: "GET",
  //   })
  //     .then((data) => data.json())
  //     .then((res) => {
  //       // console.log("helpliine", res.HelplineLst);

  //       if (res.StatusCode === 200) {
  //         setHelplineList(res.HelplineLst);
  //       } else {
  //         setHelplineList([]);
  //       }
  //     })
  //     .catch((err) => console.log("error" + err));
  // };

  //  For notification
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    notifyList();
  }, [showNotify]);

  const notifyList = () => {
    const dataForm = {
      ToleID: ToleID,
      MemberID: "-1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/notification`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.notifyRes ? resp.notifyRes : "";
          setNotificationList(postResult);
        } else {
          setNotificationList([]);
        }
      });
    });
  };

  //  For Management
  // const [managementList, setManagementList] = useState([]);

  // useEffect(() => {
  //   manageList();
  // }, [showMgmt]);

  // const manageList = () => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     Count: "5",
  //   };
    // console.log(dataForm);

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(dataForm),
  //   };
  //   fetch(`${urll}/management`, requestOptions).then((result) => {
  //     result.json().then((resp) => {
  //       console.log(resp);
  //       if (resp.StatusCode === 200) {
  //         const postResult = resp.MgmtLst ? resp.MgmtLst : "";
  //         setManagementList(postResult);
  //       } else {
  //         setManagementList([]);
  //       }
  //     });
  //   });
  // };

  //  For complain list
  const [complainList, setComplainList] = useState([]);

  useEffect(() => {
    CplnList();
  }, []);

  const CplnList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
      TitleID: "-1",
      MemberID: "-1",
      FromDate: "2021-02-02",
      ToDate: "2023-02-02",
      ComplainStatus: "-1",
      Status: "-1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/complain`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.ComplainLst ? resp.ComplainLst : "";
          setComplainList(postResult);
        } else {
          setComplainList([]);
        }
      });
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardList,
        eventList,
        birthdayList,
        notificationList,
        // managementList,
        complainList,
        helplineList,
        setShowEvent,
        setShowHelpline,
        setShowMember,
        setShowMgmt,
        setShowNotify,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
}

export default DashboardState;
