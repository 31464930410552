import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import ComplainContext from "./ComplainContext";
import CryptoJS from "crypto-js";
import { Fetchdata } from "../../hooks/getData";

function ComplainState(props) {

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const [closeChecked, setCloseChecked] = useState(false);

  const [popup, setPopup] = useState(false);
  const [pop, setPop] = useState(false);
  const [complainFormError, setComplainFormError] = useState({});
  const [complainSubmit, setComplainSubmit] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pushNotice, setPushNotice] = useState(false);

  const datevalue = {
    fromDate: "",
    toDate: "",
  };
  const [dateFormValue, setDateFormValue] = useState(datevalue);
  const [chooseOption, setChooseOption] = useState("-1");
  const [chooseMember, setChooseMember] = useState("-1")
  const [chooseComplainStatus, setChooseComplainStatus] = useState("-1")



  const complainvalue = {
    complainStatus: "1",
    description: "",
    isChecked: false,
  };
  const [complainFormValue, setComplainFormValue] = useState(complainvalue);

  //Initializing initial value for Add Complain Popup

  const [complaintopicError, setComplainTopicError] = useState({});
  const [complainTopicSubmit, setComplainTopicSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [complainTitleValue, setComplainTitleValue] = useState({ title: "" });

  //API to hit complain topic List

  const [complainList, setComplainList] = useState([]);
  useEffect(() => {
    complainfunction();
  }, [])



  const checkComplainType = (isComplain) => {
    if (isComplain === "P") {
      return "Pending";
    // } else if (isComplain === "P") {
    //   return "Reviewed";
    } else if (isComplain === "A") {
      return "Approved";
    } else if (isComplain === "R") {
      return "Rejected";
    }
  };
  // const complainfunction = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     tblOpt: "04",
  //     tblField: "ID TopicID,title_name TopicName,status Status",
  //     conditionField: "tole_id",
  //     conditionValues: ToleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectdatawithcondition`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setComplainList(resp.dataList)
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };

  const complainfunction = () => {
    const params = {
      ToleID: ToleID,
      UserID: "1",
      Flag: "S",
      // Status: "-1",
      Type: "POST",
      FetchURL: `${urll}/admin/complain-topic`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result)
      if (result.StatusCode === 200) {
        const postResult = result.ComplainTopicLst ? result.ComplainTopicLst : "";
        setLoading(false);
        setComplainList(postResult);

      } else {
        setComplainList([]);
      }
    });
  }


  // API to hit Complain Type Status

  const stateTInitial = [];
  const [newTStat, setNewTStat] = useState(stateTInitial);

  const deactivateComplainCategory = (ID, IsActive) => {

    const dataForm = {
      ToleID: ToleID,
      Flag: "US",
      UserID: UserID,
      TitleID: ID,
      Status: IsActive,
      FetchURL: `${urll}/admin/complain-topic`,
      Type: "POST",
    }
    if (IsActive === 1) {
      dataForm.Status = 0
    } else {
      dataForm.Status = 1
    }
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        complainfunction();
        let statsN = JSON.parse(JSON.stringify(newTStat));
        let pitchStatus;

        if (dataForm.Status === 1) {
          pitchStatus = "Activated";

        } else if (dataForm.Status === 0) {
          pitchStatus = "Deactivated";
        }
        // console.log(pitchStatus)
        setNewTStat(statsN)
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });

      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });

      }
    });
  }

  // const posStateInitial = [];
  // const [posanewStat, setPosNewStat] = useState(posStateInitial);

  // const deactivateComplainCategory = async (comID, IsActive) => {

  //   const formData = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "04",
  //     tblField: "status",
  //     tblValues: IsActive.toString(),
  //     tblID: comID.toString(),
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   if (IsActive === 1) {
  //     formData.tblValues = 0
  //   } else if (IsActive === 0) {
  //     formData.tblValues = 1
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(formData),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   // console.log("encplogin", encrypted.toString())
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();

  //   if (tole.STATUS_CODE === "0") {
  //     complainfunction();
  //     setReload(!reload)
  //     let statsN = JSON.parse(JSON.stringify(posanewStat));
  //     let pitchStatus;

  //     if (formData.tblValues === 1) {
  //       pitchStatus = "Activated";

  //     } else if (formData.tblValues === 0) {
  //       pitchStatus = "Deactivated";
  //     }
  //     console.log(pitchStatus)
  //     setPosNewStat(statsN)
  //     setMsg({
  //       msg: `Complain Topic ${pitchStatus} Successfully`,
  //       type: "alert alert-success",
  //     });
  //   } else {
  //     setMsg({
  //       msg: "Something went wrong.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // }

  //To edit complain

  const [state, setState] = useState()
  const [compID, setCompID] = useState()

  const handleViewComplain = (item) => {
    setState(item)
    setPopup(true);
    setCompID(item.ComplainID)
    setComplainFormValue({
      complainStatus: item.ComplainStatus,
      description: item.ComplainRemarks
    })
  }


  // API to hit verify complain

  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "AD",
      ComplainID: compID,
      ComplainStatus: complainFormValue.complainStatus,
      ComplainRemarks: complainFormValue.description,
      NotifyUser:pushNotice ? "Y" : "N"
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataForm)
    };
    fetch(`${urll}/admin/complain`, requestOptions)
      .then((result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            complainMainFunction();
            setMsg({
              msg: resp.Message,
              type: "alert alert-success",
            });
          } else {
            setMsg({
              msg: resp.Message,
              type: "alert alert-danger",
            });
          }

        })
      })
  }

  //API to hit main complain list 
  const [complainMainList, setComplainMainList] = useState([]);
  useEffect(() => {
    complainMainFunction();
  }, [chooseOption, chooseMember, chooseComplainStatus, dateFormValue.fromDate || dateFormValue.toDate])
  const complainMainFunction = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "ST",
      Topic: chooseOption,
      // MemberID: chooseMember,
      ComplainDate: "-1",
      // ToDate: dateFormValue.toDate,
      // ComplainStatus: chooseComplainStatus,
      // Status: "-1"

    };
    // console.log(dataForm)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataForm)
    };
    fetch(`${urll}/admin/complain`, requestOptions)
      .then((result) => {
        result.json().then((resp) => {
          // console.log(resp)
          if (resp.StatusCode === 200) {
            const postResult = resp.ComplainLst ? resp.ComplainLst : "";
            setLoading(false);
            setComplainMainList(postResult);

          } else {
            setComplainMainList([]);
          }

        })
      })
  }


  //API to hit push notification

  const ComplainPush = () => {
    const listForm = {
      Title: "complain test",
      Body: "complain test",
      UserID: 0,
      Topic: ToleID,
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(listForm)
    };
    fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions)
      .then((result) => {
        result.json().then((resp) => {
          // console.log(resp)
        })
      })
  }

  return (
    <ComplainContext.Provider
      value={{
        popup,
        setPopup,
        complainFormError,
        setComplainFormError,
        complainSubmit,
        setComplainSubmit,
        complainFormValue,
        setComplainFormValue,
        complainvalue,
        pop,
        setPop,
        complainTitleValue,
        setComplainTitleValue,
        complaintopicError,
        setComplainTopicError,
        complainTopicSubmit,
        setComplainTopicSubmit, complainList, complainfunction, ComplainPush, pushNotice, setPushNotice,
        isEdit, setIsEdit, deactivateComplainCategory, reload, setReload, fetchdata, handleViewComplain, state, setState, closeChecked, setCloseChecked, setLoading, loading, complainMainList, compID,
        dateFormValue, setDateFormValue, chooseOption, setChooseOption, chooseMember, setChooseMember,
        chooseComplainStatus, setChooseComplainStatus,checkComplainType
      }}
    >
      {props.children}
    </ComplainContext.Provider>
  );
}

export default ComplainState;
