import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import "../../css/imagePreview.css";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import ProfessionPopup2 from "./ProfessionPopup2";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function ProfessionEdit(props) {
  const context = useContext(ProfessionContext);
  const { professionPopup2, setProfessionPopup2 } = useContext(SidebarContext);
  const {
    popup,
    setEditPop,
    professionFormValue,
    setProfessionFormValue,
    professionvalue,
    editIsSubmit,
    setEditIsSubmit,
    professionFormError,
    setProfessionFormError,
    editData,
    closeChecked,
    setCloseChecked,
    professionDropdown,
  } = context;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setProfessionFormValue({ ...professionFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const formSubmit = (e) => {
    // console.log("here")
    e.preventDefault();
    setProfessionFormError(validate(professionFormValue));
    setEditIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(professionFormError).length === 0 && editIsSubmit) {
      editData(professionFormValue);
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(100);
        setEditPop(false);
      }

      setEditIsSubmit(false);
    }
  }, [professionFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopups").fadeOut(100);
    setEditPop(false);
    setProfessionFormError({});
  };

  const handleProfessionPopup = (e) => {
    // e.preventDefault();
    setProfessionPopup2(true);
    $(".professionTopicPop").fadeIn(100);
    // setProfessionFormValue(professionvalue);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.position) {
      errors.position = "Required";
    }
    if (!values.oName) {
      errors.oName = "Required";
    }

    if (!values.profession) {
      errors.profession = "Required";
    }

    return errors;
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="profession">
                Profession <sup className="sup-col">*</sup>
              </label>
              <div className="uk-flex">
                <select
                  id="profession"
                  name="profession"
                  onChange={handleChange}
                  value={professionFormValue.profession}
                  className="form-control form-control-sm mb-1"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" selected disabled>
                    Select Profession
                  </option>

                  {professionDropdown.map((item) => (
                    <option value={item.ID}>{item.Name}</option>
                  ))}
                </select>

                <span>
                  <button
                    onClick={handleProfessionPopup}
                    type="button"
                    className="btn btn-primary"
                  >
                    +
                  </button>
                </span>
              </div>
              <p className="errormsg ">{professionFormError.profession}</p>
            </div>

            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="position">
                Position <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={professionFormValue.position}
                id="position"
                name="position"
              />
              <p className="errormsg ">{professionFormError.position}</p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="oName">
                Organization Name <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={professionFormValue.oName}
                id="oName"
                name="oName"
              />
              <p className="errormsg ">{professionFormError.oName}</p>
            </div>

            <div className="form-group col-md-6  ">
              <label className="form-label" htmlFor="address">
                Address
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={professionFormValue.address}
                id="address"
                name="address"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <ProfessionPopup2
        professionPopup2={professionPopup2}
        setProfessionPopup2={setProfessionPopup2}
      />
    </>
  );
}
