import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import { usePosition } from "use-position";
import EventContext from "./EventContext";
import CryptoJS from "crypto-js";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import DashboardContext from "../DashboardContextFolder/DashboardContext";
import $ from "jquery"

function EventState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;

  const { setShowEvent } = useContext(DashboardContext);

  const [popup, setPopup] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [eventTitlePop, setEventTitlePop] = useState(false);
  const [basicFormErrors, setBasicFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [image, setImage] = useState("");
  // const [slug, setSlug] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);
  const [pushNotice, setPushNotice] = useState(false);

  const [reload, setReload] = useState(false);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const { setMsg, URL, urll } = useContext(SidebarContext);

  const datevalue = {
    fromDate: "",
    toDate: "",
  };
  const [dateFormValue, setDateFormValue] = useState(datevalue);
  const [chooseOption, setChooseOption] = useState("-1");
  const [chooseType, setChooseType] = useState("-1");

  const initialvalue = {
    title: "",
    description: "",
    time_from: "",
    time_to: "",
    organized: "",
    venue: "",
    contact: "",
    email: "",
    actionBtn: "",
    actionUrl: "",
    event_from: "",
    event_to:"",
    purpose: "M",
    // latitude: "",
    // longitude: "",
    isChecked: false,
  };
  const watch = true;
  // const { latitude, longitude } = usePosition(watch);

  const [basicFormValue, setBasicFormvalue] = useState(initialvalue);
  const navigate = useNavigate();

  const [data, setData] = useState({
    titleName: "",
  });
  const [sluga, setSluga] = useState("");
  const [eventFormError, setEventFormError] = useState({});
  const [titleSubmit, setTitleSubmit] = useState(false);

  // API to get Event Title List ////

  const [eventTitle, setEventTitle] = useState([]);

  // useEffect(() => {
  //   eventTitleList();
  // }, []);

  // const eventTitleList = () => {
  //   const dataForm = {
  //     ToleID: ToleID,
  //     tblOpt: "07",
  //     tblField: "ID EventTitleID,title_name TitleName,slug Slug,status Status",
  //     conditionField: "tole_id",
  //     conditionValues: ToleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key,
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectdatawithcondition`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setEventTitle(resp.dataList);
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };
  // const eventTitleList = () => {
  //   const params = {
  //     ToleID: ToleID,
  //     UserID: "-1",
  //     Flag: "S",
  //     Status: "-1",
  //     Type: "POST",
  //     FetchURL: `${urll}/admin/event-title`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     console.log(result);
  //     if (result.StatusCode === 200) {
  //       eventDropList();
  //       const postResult = result.Eventlst ? result.Eventlst : "";
  //       setLoading(false);
  //       setEventTitle(postResult);
  //     } else {
  //       setEventTitle([]);
  //     }
  //   });
  // };

  //Api to hit event title list in dropdown




  const [eventDropTitle, setEventDropTitle] = useState([]);

  useEffect(() => {
    eventDropList();
  }, []);

  const eventDropList = () => {
    const params = {
      ToleID: ToleID,
      UserID: "-1",
      Flag: "S",
      Status: "1",
      Type: "POST",
      FetchURL: `${urll}/admin/event`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        const postResult = result.Eventlst ? result.Eventlst : "";
        setLoading(false);
        setEventDropTitle(postResult);
      } else {
        setEventDropTitle([]);
      }
    });
  };

  //Event Edit
  const [eventTitleID, setEventTitleId] = useState(null);
  const [date, setDate] = useState();
  const handleEdit = (datas) => {
    setEventTitleId(datas.EventID);
    setEditPop(true);
    eventInfo();
    setDate(Math.random());
  };

  //// API to get Event  Info ////

  useEffect(() => {
    eventInfo();
  }, [eventTitleID]);

  const eventInfo = () => {
    const params = {
      ToleID: ToleID,
      Flag: "SI",
      UserID:UserID,
      EventID: eventTitleID,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    fetch(`${urll}/admin/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const data = resp.eventlst[0] ? resp.eventlst[0] : "";
          // console.log(data);
          setBasicFormvalue({
            purpose: data.EventType,
            venue: data.Venue,
            title: data.Title,
            description: data.Description,
            date_from: data.DateFrom,
            date_to: data.DateTo,
            time_from: data.TimeFrom,
            time_to: data.TimeTo,
            organized: data.OrganisedBy,
            email: data.Email,
            contact: data.Contact,
            actionBtn: data.AcBtn,
            actionUrl: data.EveLink,
          
          });
          setImage(data.Banner);
        }
      });
    });
  };

  // useEffect(() => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     tblOpt: "06",
  //     tblField: "ID EventID,title_id TitleID,type Type,description Description,nepali_date NepaliDate,time_start TimeStart,time_end TimeEnd,venue Venue,ac_btn_name AcBtn,ac_btn_url AcUrl,category Category,contact Contact,email Email,latitude Latitude,longitude Longitude,banner Banner,organized_by OrganizedBy",
  //     conditionField: "ID",
  //     conditionValues: eventTitleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   var encryptedData = encrypted.toString();

  //   const dataToSend = {
  //     param: encryptedData,
  //   };
  //   const eventTitleList = async (dataToSend) => {
  //     // console.log(JSON.stringify(dataToSend))
  //     const response = await fetch(
  //       `${URL}/toleAPI/api/selectdatawithcondition`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Signature: apisignature,
  //         },
  //         body: JSON.stringify(dataToSend),
  //       }
  //     );
  //     const getData = await response.json();
  //     return getData;
  //   };

  //   eventTitleList(dataToSend).then(function (result) {
  //     const code = CryptoJS.AES.decrypt(result.MESSAGE, key, {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     });
  //     const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //     // console.log(response)
  //     if (response.STATUS_CODE === "0") {
  //       const data = response.dataList[0]
  //       setBasicFormvalue({
  //         purpose: data.Type,
  //         venue: data.Venue,
  //         title: data.TitleID,
  //         category: data.Category,
  //         description: data.Description,
  //         date: data.NepaliDate,
  //         time: data.TimeStart,
  //         time2: data.TimeEnd,
  //         organized: data.OrganizedBy,
  //         email: data.Email,
  //         contact: data.Contact,
  //         actionBtn: data.AcBtn,
  //         actionUrl: data.AcUrl,
  //         image: data.Banner,
  //       });

  //     }
  //   });
  // }, [eventTitleID]);
  useEffect(() => {
    if (Object.keys(eventFormError).length === 0 && editIsSubmit) {
      editdata(basicFormValue);
      // if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setEditPop(false);
      // }
      setEventFormError({});
      setBasicFormvalue(basicFormValue);
      setEditIsSubmit(false);
    }
  }, [eventFormError]);

  const editdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "U",
      EventID: eventTitleID,
      Title: obj.title_id,
      Slug: obj.title_id,
      EventType: obj.type,
      Description: obj.description,
      DateFrom: obj.date_from,
      DateTo: obj.date_to,
      FromTime: obj.time_start,
      ToTime: obj.time_end,
      Venue: obj.venue,
      AcBtn: obj.ac_btn_name,
      EveLink: obj.ac_btn_url,
      Category: obj.category,
      Contact: obj.contact,
      Email: obj.email,
      Banner: image !== null ? image.split(",")[1] : "",
      OrganisedBy: obj.organized_by,
      IsActive: "A",
      NotifyUser: "Y"
    };
    console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          eventListPop();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  //API to hit Event Status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateEvent = (ID, IsActive) => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "US",
      UserID: UserID,
      EventID: ID,
      Status: IsActive,
    };
    if (IsActive === 1) {
      dataForm.Status = 0;
    } else {
      dataForm.Status = 1;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          eventListPop();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.Status === 1) {
            pitchStatus = "Activated";
          } else if (dataForm.Status === 0) {
            pitchStatus = "Deactivated";
          }
          console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  // API to hit Event Title Status

  const stateTInitial = [];
  const [newTStat, setNewTStat] = useState(stateTInitial);

  const deactivateEventTitle = (ID, IsActive) => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "US",
      UserID: UserID,
      TitleID: ID,
      Status: IsActive,
      FetchURL: `${urll}/admin/event`,
      Type: "POST",
    };
    if (IsActive === 1) {
      dataForm.Status = 0;
    } else {
      dataForm.Status = 1;
    }
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        // eventTitleList();
        let statsN = JSON.parse(JSON.stringify(newTStat));
        let pitchStatus;

        if (dataForm.Status === 1) {
          pitchStatus = "Activated";
        } else if (dataForm.Status === 0) {
          pitchStatus = "Deactivated";
        }
        console.log(pitchStatus);
        setNewTStat(statsN);
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  // API to submit form Data post method

  const obj = {
    tole_id: ToleID,
    title_id: basicFormValue.title,
    type: basicFormValue.purpose,
    description: basicFormValue.description,
    date_from: basicFormValue.event_from,
    date_to: basicFormValue.event_to,
    time_start: basicFormValue.time_from,
    time_end: basicFormValue.time_to,
    venue: basicFormValue.venue,
    ac_btn_name: basicFormValue.actionBtn,
    ac_btn_url: basicFormValue.actionUrl,
    category: basicFormValue.category,
    contact: basicFormValue.contact,
    email: basicFormValue.email,
    latitude: basicFormValue.latitude,
    longitude: basicFormValue.longitude,
    organized_by: basicFormValue.organized,
    status: "1",
  };
  // console.log(Object.values(obj))

  // const obj1 = Object.values(obj);
  // const obj2 = obj1.join("##");
  // console.log(obj2);
  useEffect(() => {
    if (Object.keys(eventFormError).length === 0 && isSubmit) {
  // const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "i",
      Title: obj.title_id,
      Slug: obj.title_id,
      EventType: obj.type,
      Description: obj.description,
      DateFrom: obj.date_from,
      DateTo: obj.date_to,
      FromTime: obj.time_start,
      ToTime: obj.time_end,
      Venue: obj.venue,
      OrganisedBy: obj.organized_by,
      Contact: obj.contact,
      Email: obj.email,
      EveLink: obj.ac_btn_url,
      Banner: image !== null ? image.split(",")[1] : "",
       IsActive: "A",
  NotifyUser: "Y",
    FetchURL: `${urll}/admin/event`,
      Type: "POST",

    }
    // console.log("is this".dataForm)
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        setPopup(false);
        $(".displayPopup").fadeOut(100);
        eventListPop();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  // }
};
}, [eventFormError]);

  //API to hit event list

  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    eventListPop();
  }, [
    chooseOption,
    chooseType,
    dateFormValue.fromDate || dateFormValue.toDate,
  ]);

  const eventListPop = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "S",
      EventType: chooseType,
      // TitleID: chooseOption,
      // Type: chooseType,
      // FromDate: dateFormValue.fromDate,
      // ToDate: dateFormValue.toDate,
      IsActive: "-1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/event`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.eventlst ? resp.eventlst : "";
          setLoading(false);
          setEventList(postResult);
        } else {
          setEventList([]);
        }
      });
    });
  };

  //API to hit push notification

  const eventPush = () => {
    const listForm = {
      Title: "test",
      Body: "test",
      UserID: 0,
      Topic: ToleID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listForm),
    };
    fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions).then(
      (result) => {
        result.json().then((resp) => {
          // console.log(resp);
        });
      }
    );
  };

  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  const [Acdata, setACData] = useState();
    // To delete the event List

    const handleDelete = (data) => {
      setACData(data);
  
      setDelPopup({
        show: true,
        data,
      });
    };

    const handleDeleteTrue = () => {
      const dataForm = {
        ToleID: ToleID,
        UserID: UserID,
        Flag: "R",
        EventID: Acdata,
      };
      // console.log(dataForm);
  
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataForm),
      };
      fetch(`${urll}/admin/event`, requestOptions).then((result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            eventListPop();
            setMsg({
              msg: resp.Message,
              type: "alert alert-success",
            });
            setDelPopup({
              show: false,
              data: null,
            });
          } else {
            setMsg({
              msg: resp.Message,
              type: "alert alert-danger",
            });
          }
        });
      });
    };

      // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };

  return (
    <EventContext.Provider
      value={{
        popup,
        setPopup,
        basicFormErrors,
        setBasicFormErrors,
        initialvalue,
        isSubmit,
        setIsSubmit,
        basicFormValue,
        setBasicFormvalue,
        eventTitlePop,
        setEventTitlePop,
        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        data,
        setData,
        sluga,
        setSluga,
        eventFormError,
        setEventFormError,
        titleSubmit,
        setTitleSubmit,
        loading,
        setLoading,
        editPop,
        setEditPop,
        isEdit,
        setIsEdit,
        eventTitle,
        editIsSubmit,
        setEditIsSubmit,
        handleEdit,
        eventTitleID,
        deactivateEvent,
        eventListPop,
        // eventTitleList,
        deactivateEventTitle,
        closeChecked,
        setCloseChecked,
        reload,
        setReload,
        eventList,
        setEventList,
        // fetchdata,
        pushNotice,
        setPushNotice,
        eventPush,
        dateFormValue,
        setDateFormValue,
        chooseOption,
        setChooseOption,
        chooseType,
        setChooseType,
        editdata,
        eventDropTitle,
        date,
        setDate,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        delPopup,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
}

export default EventState;
