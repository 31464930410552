import React from "react";
import { useContext, useState } from "react";
import "../../css/profile.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ProfileAddress from "./ProfileAddress";
import ProfileFamily from "./ProfileFamily";
import ProfilePersonal from "./ProfilePersonal";
import ProfileProfession from "./ProfileProfession";
import { showImgPreview } from "../hooks/ImagePreview";

const Profile = () => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const { mainBg, mode, mainContent, darkText } = useContext(SidebarContext);
  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
  });

  const handleTab1 = () => {
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab2 = () => {
    setActiveTab({
      tab1: false,
      tab2: true,
      tab3: false,
      tab4: false,
    });
  };
  const handleTab3 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: false,
    });
  };
  const handleTab4 = () => {
    setActiveTab({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
    });
  };

  const fullname = userinfo.FirstName;

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div className="fontHeader" style={darkText}>
              Profile
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>

        <section
          className="content-section main-content mt-3 max-height"
          style={mainContent}
        >
          <div className="user-profile">
            <div className="profile-info">
              <div className="tl">
                <div className="profileImg tl">
                  <img
                    src={userinfo.CustomerImage}
                    alt="dp"
                    style={{ cursor: "pointer", visibility: "visible" }}
                    onClick={() => {
                      setImgPreview(userinfo.CustomerImage);
                      setImgPrv(true);
                    }}
                  />
                </div>
              </div>
              <div>
                <h5 style={darkText}>
                  {userinfo.FirstName + " " + userinfo.LastName}
                </h5>
                <p style={darkText}>
                  Last Online:{" "}
                  {userinfo.LastLoginedDate != "" ? (
                    userinfo.LastLoginedDate
                  ) : (
                    <span>Not mentioned</span>
                  )}{" "}
                </p>
              </div>
            </div>

            <div className="profilePopup mt-4">
              <ul>
                <li
                  className={activeTab.tab1 === true ? "active" : ""}
                  onClick={handleTab1}
                >
                  Personal Information{" "}
                </li>
                <li
                  className={activeTab.tab2 === true ? "active" : ""}
                  onClick={handleTab2}
                >
                  Address Information{" "}
                </li>
                <li
                  className={activeTab.tab3 === true ? "active" : ""}
                  onClick={handleTab3}
                >
                  Family Member{" "}
                </li>
                <li
                  className={activeTab.tab4 === true ? "active" : ""}
                  onClick={handleTab4}
                >
                  Profession{" "}
                </li>
              </ul>
            </div>

            <div>
              {activeTab.tab1 && <ProfilePersonal />}
              {activeTab.tab2 && <ProfileAddress />}
              {activeTab.tab3 && <ProfileFamily />}
              {activeTab.tab4 && <ProfileProfession />}
            </div>
          </div>
        </section>
      </div>

      {imgPrv &&
        showImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}
    </>
  );
};

export default Profile;
