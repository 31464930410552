import React, { useState, useContext, useEffect } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import '../../css/Complain.css';
import ComplainForm from './ComplainForm';
import ComplainContext from '../contextFolder/ComplainContextFolder/ComplainContext';

export default function ComplainPop(props) {
  const { height } = UsewindowDimension();

  const context = useContext(ComplainContext)

  const { setComplainFormError, setComplainSubmit, complainvalue, setPushNotice } = context;


  const closePopup = (e) => {
    e.preventDefault();
    props.setComplainTrigger(false)
    $('.displayPopup').fadeOut(100);
    setComplainFormError({});
    setComplainSubmit(false);
    setPushNotice(false);
  }

  useEffect(() => {
    if (props.complainTrigger) {
      $('.displayPopup').fadeIn(100)
    }
  }, [props.complainTrigger])

  return (

    <div className="complainPopUp displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "complainInside ip500" : "complainInside"}>
        <div className="popUpHeader ">
          <div className='popUpHeaderText '>View Complain</div>
          <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
        </div>

        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="complain-outlet">
          <ComplainForm state={props.state} />

        </div>

        {/* )}     */}
      </div>
      {/* </OutsideAlerter> */}
    </div>
  )

}