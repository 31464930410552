import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import urlSlug from "url-slug";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { Fetchdata } from "../hooks/getData";

function EventTitleForm() {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID;
  const context = useContext(EventContext);
  const {
    setMsg,
    URL,
    urll,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const {
    data,
    setData,
    sluga,
    setSluga,
    eventFormError,
    setEventFormError,
    titleSubmit,
    setTitleSubmit,
    isEdit,
    setIsEdit,
    deactivateEventTitle,
    setReload,
    reload,
    eventTitle,
    eventTitleList,
  } = context;

  const apisignature = process.env.REACT_APP_SIGNATURE;

  const [titleID, setTitleId] = useState(null);

  function handle(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setData({ ...data, [name]: value });
  }

  const navigate = useNavigate();

  useEffect(() => {
    setSluga(urlSlug(data.titleName));
  }, [data]);
  const status = 1;

  const hashed =
    ChhimekiID + "##" + data.titleName + "##" + sluga + "##" + status;

  //To post event title list

  // const fetchdata = async (dataToSend) => {
  //   const dataForm = {
  //     ChhimekiID: ChhimekiID,
  //     userID: UserID.toString(),
  //     tblOpt: "07",
  //     tblField: "tole_id,title_name,slug,status",
  //     tblValues: hashed,
  //     validationFields: "slug",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   // console.log("encppp", encrypted.toString());
  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   if (tole.STATUS_CODE === "0") {
  //     eventTitleList();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: "Title Alredy Exists. Please choose another Name.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const fetchdata = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      Flag: "i",
      UserID: UserID,
      Title: data.titleName,
      Slug: sluga,
      FetchURL: `${urll}/admin/event-title`,
      Type: "POST",
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        eventTitleList();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const formSubmit = (e) => {
    setEventFormError(validate(data));
    setTitleSubmit(true);
    // setData({ titleName: "" });
  };
  useEffect(() => {
    if (Object.keys(eventFormError).length === 0 && titleSubmit) {
      fetchdata(data);
      setData({ titleName: "" });
      setTitleSubmit(false);
    }
  }, [eventFormError]);

  const validate = (values) => {
    const errors = {};

    if (!values.titleName) {
      errors.titleName = "Required";
    }
    return errors;
  };

  //Update Function

  function selectUser(datas) {
    setData({
      titleName: datas.Title,
    });
    setSluga(datas.Slug);
    setTitleId(datas.TitleID);
    setIsEdit(true);
  }

  // const edithashed = data.titleName + "##" + sluga;

  // const updateUser = async () => {
  //   const dataForm = {
  //     ChhimekiID: ChhimekiID,
  //     userID: UserID.toString(),
  //     tblOpt: "07",
  //     tblField: "title_name,slug",
  //     tblValues: edithashed,
  //     tblID: titleID.toString(),
  //     validationFields: "slug",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(dataForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };

  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();
  //   // console.log(tole);
  //   if (tole.STATUS_CODE === "0") {
  //     eventTitleList();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Edited",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: "Title Alredy Exists. Please choose another Name.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // };

  const updateUser = () => {
    const dataForm = {
      ChhimekiID: ChhimekiID,
      Flag: "U",
      UserID: UserID,
      TitleID: titleID,
      Title: data.titleName,
      Slug: sluga,
      FetchURL: `${urll}/admin/event-title`,
      Type: "POST",
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        eventTitleList();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const changeStatus = (eventID, IsActive) => {
    deactivateEventTitle(eventID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  };

  return (
    <>
      <div className="container-fluid basicform">
        <div className="form-group  ">
          <label className="form-label" htmlFor="slug">
            Title <sup className="sup-col">*</sup>
          </label>
          <input
            type="text"
            name="titleName"
            onChange={handle}
            value={data.titleName}
            className="form-control form-control-sm mb-1"
            id="titleName"
          />
          <p className="errormsg ">{eventFormError.titleName}</p>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="title">
            Slug
          </label>
          <input
            type="text"
            name="slug"
            className="form-control form-control-sm mb-1"
            value={sluga}
            id="slug"
            placeholder="slug"
            disabled
          />
        </div>
        <div className="floatyy">
          {isEdit ? (
            <button
              className="btn btn-sm btn-cmpy "
              style={{ background: "0079bf" }}
              onClick={updateUser}
            >
              Update
            </button>
          ) : (
            <button
              className="btn btn-sm btn-cmpy "
              style={{ background: "0079bf" }}
              onClick={formSubmit}
            >
              Save
            </button>
          )}
        </div>
      </div>

      <section
        className="content-section main-content"
        style={{ margin: "3px" }}
      >
        <div className="content">
          <div className=" col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-md-4 Search" style={{ padding: "0px" }}>
                <div className="dashboard-search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none" }}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                  <i
                    style={{
                      paddingTop: "7px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      color: "#999",
                    }}
                    className="fas fa-search searchIconPosition"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 p-1">
              <div className="outer-wrapper">
                <div
                  className="table-wrapper"
                  style={{
                    overflowX: "scroll",
                    overflowY: "scroll",
                    margin: "3px",
                    height: "200px",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr className="tableHead">
                        <td className="tl">S.N.</td>
                        <td className="tl">Title</td>
                        <td className="tl widthOfT1">Slug</td>
                        <td className="tl widthOfT"> Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {eventTitle
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.Title.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Slug.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            {/* <td className='tc'>{item.EventTitleID}</td> */}
                            <td className="tl">{i + 1}</td>
                            <td className="tl">{item.Title}</td>
                            <td className="tl">{item.Slug}</td>
                            <td className="tl">
                              <span
                                className="rejectspan badge"
                                onClick={() => selectUser(item)}
                              >
                                Edit
                              </span>{" "}
                              <span
                                className="editspan badge"
                                onClick={() =>
                                  changeStatus(item.TitleID, item.Status)
                                }
                              >
                                {checkStatus(item.Status)}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventTitleForm;
