import React, { useContext, useEffect } from "react";
import "../../css/dashboard.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { VscCalendar } from "react-icons/vsc";
import DashboardList from "./DashboardList";
import EventList from "./EventList";
import BirthdayList from "./BirthdayList";
import HelplineList from "./HelplineList";
import NotificationList from "./NotificationList";
import ManagementList from "./ManagementList";
import ComplainList from "./ComplainList";
const todayDate = new Date().toLocaleDateString();

const Dashboard = () => {
  const { mainBg, darkText, mainContent } = useContext(SidebarContext);

  return (
    <>
      <section className="side__content pb-5" style={mainBg}>
        <div className="uk-flex uk-flex-between uk-flex-wrap uk-flex-middle head">
          <div className="uk-margin-right">
            <p className="uk-text-bold" style={darkText}>
              Welcome!
            </p>
          </div>

          <div className="uk-margin-left">
            <p style={darkText}>
              <VscCalendar /> {todayDate} | Fiscal Year: 2079-2080
            </p>
          </div>
        </div>
        <div className="content-section main-content mt-3" style={mainContent}>
          <div className="uk-grid uk-child-width-1-2@m dashboard-wrapper">
            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Dashboard List
              </div>
              <DashboardList />
            </div> */}

            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Upcoming Birthday
              </div>
              <BirthdayList />
            </div> */}

            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Upcoming Events
              </div>
              <EventList />
            </div> */}

            {/* <div>
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Management
              </div>
              <ManagementList />
            </div> */}

            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Helpline
              </div>
              <HelplineList />
            </div> */}

            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Notification
              </div>
              <NotificationList />
            </div> */}

            {/* <div className="dasboard-wrap">
              <div className="fontHeader mb-3 ms-1" style={darkText}>
                Complain
              </div>
              <ComplainList />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
