import React, { useContext, useEffect } from 'react'
import EventContext from '../contextFolder/EventContextFolder/EventContext';
import UsewindowDimension from '../hooks/UsewindowDimension';
import $ from "jquery";
import EventTitleForm from './EventTitleForm';

function EventTitlePop(props) {


  const { height } = UsewindowDimension();


  const context = useContext(EventContext)

  const { data, setData, setEventFormError, isEdit, setIsEdit, setTitleSubmit } = context;


  const closePopup = (e) => {
    e.preventDefault();
    props.setTriggernew1(false)
    $('.displaypop').fadeOut(100);
    setData({ titleName: "" });
    setIsEdit(false);
    setEventFormError({});
    setTitleSubmit(false);
  }

  useEffect(() => {
    if (props.trigger1) {
      $('.displaypop').fadeIn(100);
    }
  }, [props.trigger1])

  return (
    <div className="eventPop displaypop">
      {/* <OutsideAlerter> */}
      <div className={height < 250 ? "inside--event ip150" : "inside--event"}>
        <div className="popUpHeader ">
          <div className='popUpHeaderText'>Add Event Title</div>
          <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
        </div>

        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="event-outlet" style={{ paddingTop: "10px" }}>
          {/* {activeTab.tab1 &&(<Basicform active={activeTab} setActive={setActiveTab} />)} */}

          <EventTitleForm />

        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  )
}

export default EventTitlePop