import React, { useContext, useState, useEffect } from 'react';
import ComplainContext from '../contextFolder/ComplainContextFolder/ComplainContext';
import $ from "jquery";


export default function ComplainForm({ state }) {
    const context = useContext(ComplainContext);
    const { popup, setPopup, complainFormError,checkComplainType, setComplainFormError, complainSubmit, setComplainSubmit, complainFormValue, setComplainFormValue, fetchdata, setReload, reload, closeChecked, setCloseChecked, ComplainPush, pushNotice, setPushNotice } = context;

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === "checkbox" ? target.checked : target.value;
        setComplainFormValue({ ...complainFormValue, [name]: value });
    };

    const handleOnChange = () => {
        setCloseChecked(!closeChecked);
    };

    const handlePushNotification = () => {
        setPushNotice(!pushNotice);
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setComplainFormError(validate(complainFormValue));
        setComplainSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(complainFormError).length === 0 && complainSubmit) {
            fetchdata(complainFormValue);
            if (pushNotice === true) {
                ComplainPush();
            }
            if (closeChecked === true) {
                $(".displayPopup").fadeOut(100);
                setPopup(false);
            }
            setComplainSubmit(false);
            setPushNotice(false);
        }
    }, [complainFormError]);

    const closePopup = (e) => {
        e.preventDefault();
        setReload(!reload);
        $('.displayPopup').fadeOut(100);
        setPopup(false);
        setComplainFormError({});
        setComplainSubmit(false);
        setPushNotice(false);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.description) {
            errors.description = "Required";
        }
        if (!values.complainStatus) {
            errors.complainStatus = "Required";
        }
        return errors;
    };

    return (
        <>
            <div>
                <div className="col-lg-12 p-1">
                    <div className="outer-wrapper">
                        <table className="complain-table">
                            <thead>
                                <tr className='tableHead'>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Date</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Name</td>
                                    <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Topic</td>
                                    <td className='tc' style={{ fontSize: "12px", paddingLeft: "10px", position: "relative" }}>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {state && (
                                    <tr>
                                        <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{state.CreatedDate}</td>
                                        <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{state.FullName}</td>
                                        <td className='tl' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>{state.Topic}</td>
                                        <td className='tc' style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "0px" }}>
                                        <span className="editspan badge">
                    { checkComplainType(state.ComplainStatus)}
                </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {state && (
                    <div>
                        <div style={{ padding: "10px 15px 0px 15px" }}>
                            <h4 style={{ fontSize: "12px", fontWeight: "bold" }}>Description</h4>
                            <p style={{ fontSize: "12px", color: "#212529" }}>{state.Description}</p>
                        </div>
                    </div>
                )}
            </div>

            {state?.ComplainStatus === "P" && (
                <div className="container-fluid basicform">
                    <form>
                        <div className="form-group">
                            <div>
                                <label className="form-label" htmlFor="purpose">Status</label>
                            </div>
                            <div>
                                <input type="radio" name="complainStatus" id="pending" value="P" onChange={handleChange} checked={complainFormValue.complainStatus === "P"} />
                                <label className="label-radio" htmlFor="pending" style={{ marginRight: "10px" }}>Pending</label>

                                <input type="radio" name="complainStatus" id="approved" onChange={handleChange} value="A" checked={complainFormValue.complainStatus === "A"} />
                                <label className="label-radio" htmlFor="approved" style={{ marginRight: "10px" }}>Approved</label>

                                <input type="radio" name="complainStatus" id="rejected" onChange={handleChange} value="R" checked={complainFormValue.complainStatus === "R"} />
                                <label className="label-radio" htmlFor="rejected">Rejected</label>
                            </div>
                            <p className="errormsg">{complainFormError.complainStatus}</p>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Complain Remarks</label>
                            <textarea className="form-control" name="description" value={complainFormValue.description} onChange={handleChange} id="exampleFormControlTextarea1" rows="4" style={{ fontSize: '12px', resize: "none" }}></textarea>
                            <p className="errormsg">{complainFormError.description}</p>
                        </div>
                    </form>
                </div>
            )}

            {state?.ComplainStatus === "P" && (
                <div className="col-lg-12 complainFormFooter">
                    <div className="form-check notification-form-check" style={{ display: 'flex' }}>
                        <div>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                                name="isChecked"
                                onChange={handlePushNotification}
                                checked={pushNotice}
                            />
                            <label className="form-check-label" htmlFor="exampleCheck1">
                                Notify User
                            </label>
                        </div>

                        <div className="checkbox-close">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck2"
                                name="closeChecked"
                                onChange={handleOnChange}
                            />
                            <label className="form-check-label" htmlFor="exampleCheck2">
                                Exit after submit
                            </label>
                        </div>
                    </div>
                    <div className="notification-form-button">
                        <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit}>Submit</button>
                        <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
                    </div>
                </div>
            )}
        </>
    );
}
