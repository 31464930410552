import React, { useContext, useState } from "react";
import "../../css/Complain.css";
import SliderContext from "../contextFolder/SliderContextFolder/SliderContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import GovernmentPopup from "./GovernmentPopup";
import GovernmentTable from "./GovernmentTable"
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

function Slider() {
  const { mode, popup, setPopup, mainBg, mainContent } =
    useContext(SidebarContext);
  const context = useContext(SliderContext);
  const { setSliderFormValue,setChooseOption,chooseOption, slidervalue } = context;
  const memcontext = useContext(MemberContext);
  const { memberDrop } = memcontext;

  const [searchTerm, setSearchTerm] = useState("");

  const handleAddSlider = (e) => {
    e.preventDefault();
    setPopup(true);
    setSliderFormValue(slidervalue);
  };
  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseOption(value);
  };

  return (
    <>
    <div
      className="col-lg-12 col-md-12 col-sm-12 side__content"
      style={mainBg}
    >
      <div>
        <div className="mainHeader">
          <div
            className="fontHeader"
            style={{ color: mode === "light" ? "#000" : "#fff" }}
          >
            Government Identity
          </div>
        </div>
        {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
      </div>
      <section className="content-section main-content mt-3" style={mainContent}>
        <div className="content">
          <div className=" col-lg-12 col-sm-12">
            <div style={{ textAlign: "right" }}>
              <button
                className="btn btn-cmpy btn-sm"
                style={{ marginRight: "-11px", marginTop: "4px" }}
                onClick={handleAddSlider}
              >
                {" "}
                Add Government Identity +
              </button>
            </div>

            <div className="row" style={{ marginTop: "10px" }}>
              <div
                className="col-lg-2  col-md-3 col-sm-2 "
                style={{ padding: "5px" }}
              >
                 <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                  Select Member
                </label>
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  className="form-control form-control-sm mb-1"
                  value={chooseOption}
                  onChange={handleChange}
                >
                  <option selected disabled style={{ fontSize: "11px" }}>
                    Select Member
                  </option>
                   <option value="-1">All</option> 
                   {memberDrop.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select> 
                 <i class="fas fa-angle-down  accountarrowposition text-dark"></i> 

                 
              </div>

              {/* <div
                className="col-lg-2 col-md-3 col-sm-2 "
                style={{ padding: "5px" }}
              >
                <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                  From Date
                </label>
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  defaultDate="2079/01/01"
                  theme="default"
                  language="en"
                  values={dateFormValue.fromDate}
                  onChange={handleDate}
                />
                <i
                  class="fa fa-calendar-o account--date--picker text-dark"
                  aria-hidden="true"
                ></i>
              </div> */}

              {/* <div
                className="col-lg-2 col-md-3 col-sm-2 "
                style={{ padding: "5px" }}
              >
                <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                  To Date
                </label>
                <Calendar
                  className="form-control form-control-sm mb-1"
                  dateFormat="YYYY/MM/DD"
                  defaultDate="2079/01/01"
                  theme="default"
                  language="en"
                  values={dateFormValue.toDate}
                  onChange={handleDate1}
                />
                <i
                  class="fa fa-calendar-o account--date--picker text-dark"
                  aria-hidden="true"
                ></i>
              </div>  */}
              <div
                className="col-lg-1 col-md-3 col-sm-2 "
                style={{ padding: "5px" }}
              ></div>

              <div
                className="col-lg-2 offset-lg-7 offset-md-8 offset-sm-1 col-md-3 col-sm-3 align-self-end Search"
                style={{ padding: "8px" }}
              >
                <div className="dashboard-search-field justify-content-end">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    style={{ border: "none" }}
                  />
                  <i
                    style={{
                      paddingTop: "7px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      color: "#999",
                    }}
                    className="fas fa-search searchIconPosition"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="col-lg-12 p-1">
                <GovernmentTable searchTerm={searchTerm} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>















      <GovernmentPopup sliderTrigger={popup} setSliderTrigger={setPopup} />
    </>
  );
}

export default Slider;
