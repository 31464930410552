import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import "../css/Uppersidebar.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import AuthContext from "./auth-context";
import OutsideAlerterlogout from "./hooks/OutsideAlerterLogout";
import { isContentEditable } from "@testing-library/user-event/dist/utils";
import logo from "../images/logo.png";
import img from "../images/img.jpg";
import { FaRegBell } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { formControlClasses } from "@mui/material";

export default function Uppersidebar() {
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const context = useContext(SidebarContext);
  const {
    darkText,
    darkBg,
    darkBg2,
    menutoggle,
    setMenutoggle,
    logoutDropdownactive,
    setlogoutDropdownactive,
    alert,
    sideMenu,
    setSideMenu,
    logoutdata,
    setLogout,
    setAlert,
    chhimekiList,
    mode,
    themeText,
    toggletheme,
    setReload,
  } = context;
  const [mobNav, setMobNav] = useState(true);

  const [options, setOptions] = useState([]);
  const [select, setSelect] = useState("");

  // const [searchpop, setsearchPop] = useState(false);
  // console.log(menutoggle);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const UserName = userinfo.UserName;
  const ChhimekiName = userinfo.ChhimekiName;
  const ChhimekiAddress = userinfo.ChhimekiAddress;
  const ChhimekiLogo = userinfo.ChhimekiLogo;

  const handleLogoClick = (e) => {
    e.preventDefault();
    setlogoutDropdownactive(!logoutDropdownactive);
  };
  function logOut(e) {
    e.preventDefault();
    setlogoutDropdownactive(!logoutDropdownactive);
    setAlert({
      fade: "fade-in",
      msg: "Do you want to Logout ?",
      type: "logout",
    });
  }
  function logoutFunction() {
    authCtx.logout();
    localStorage.clear();
    sessionStorage.clear();
    setAlert({
      fade: "fade-default",
    });
    navigate("/login");
  }

  useEffect(() => {
    if (logoutdata) {
      logoutFunction();
    }
  }, [logoutdata]);

  // useEffect(() => {
  //   axios
  //     .get("https://libretranslate.de/languages", {
  //       headers: { accept: "application/json" },
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setOptions(res.data);
  //     });
  // }, []);

  // const { translate } = require("free-translate");

  // (async () => {
  //   const translatedText = await translate("Hello World", {
  //     from: "en",
  //     to: "ja",
  //   });

  //   console.log(translatedText); // こんにちは世界
  // })();

  // const translate1 = () => {
  //   const body = document.querySelector("body");
  //   console.log(body);
  //   const params = new URLSearchParams();
  //   params.append("q", body);
  //   params.append("source", select);
  //   params.append("target", select);
  //   params.append("api_key", "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");
  //   axios
  //     .post("https://libretranslate.de/translate", params, {
  //       headers: {
  //         accept: "application/json",
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //     });
  // };

  // };
  // const searchPopup = (e) => {
  //   e.preventDefault();
  //   setsearchPop(!searchpop);
  // };
  // const closeSearchPopup = (e) => {
  //   e.preventDefault();
  //   setsearchPop(false);
  // };
  // useEffect(() => {
  //   if (searchpop) {
  //     $(".displaySearchbar").slideDown(100);
  //   } else {
  //     $(".displaySearchbar").slideUp(100);
  //   }
  // }, [searchpop]);

  // const manageOutsideClick = (e) => {
  //     if (myref && myref.current && myref.current.contains(e.target)) {
  //     }
  //     else {
  //         $(".displaySearchbar").fadeOut(100);
  //     }
  // }

  // const myref = useRef()
  // useEffect(() => {

  //     document.addEventListener("click", manageOutsideClick);
  //     return () => {
  //         document.removeEventListener("click", manageOutsideClick);
  //     };
  // }, [])

  const handleMenuChange = () => {
    setSideMenu(!sideMenu);
    console.log("clicked");
  };

  const handleRefresh = () => {
    // window.location.reload(false);
    // setReload({})
    // setReload(Math.random());
  };

  // const { Translate } = require("@google-cloud/translate").v2;

  // const translate = new Translate({ projectId });

  // async function quickStart() {

  //   const text = "Hello, world!";

  //   const target = "ru";

  //   const [translation] = await translate.translate(text, target);
  //   console.log(`Text: ${text}`);
  //   console.log(`Translation: ${translation}`);
  // }

  // quickStart();

  return (
    <>
      {/* <div className="col-lg-12 col-md-12 col-sm-12 UpperNav">
        <div className="wrapper">
          <div className="navbar">
            <div className="navbar__left">
              <button className="toogle" onClick={handleMenuChange}>
                <i className="fas fa-bars"></i>
              </button>
              <span className="mx-3"></span>

              <div className="mainNavUserInfo pl-0">
                <div>
                  <img
                    className="upperuserImg pl-0"
                    src={ChhimekiLogo}
                    alt=""
                  />
                </div>
                <div className="uppernavuserInfotext pl-2">
                  {" "}
                  <p className="mb-0">{ChhimekiName}</p>
                  <p className="mb-0">{ChhimekiAddress}</p>
                </div>
              </div>
            </div>

            <div className="navbar__right">
              <div className="d-flex justify-content-center align-items-center ">
                <i
                  className="fas fa-redo mx-4 pointer"
                  onClick={handleRefresh}
                ></i>
                <i className="fas fa-search pointer" onClick={searchPopup}></i>
                <i className="fas fa-sync ml-4 pointer"></i>
                <i className="fas fa-comment-dots ml-4 pointer"></i>
                <i className="fas fa-bell mx-4 pointer"></i>
                <i
                  className="fas fa-power-off mr-2 pointer"
                  onClick={handleLogoClick}
                ></i>
              </div>

              {logoutDropdownactive && (
                <OutsideAlerterlogout>
                  <div className="dropLogomenu">
                    <ul>
                      <li>
                        <a href="" onClick={logOut}>
                          <span>
                            <i className="fas fa-power-off mr-2"></i>Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </OutsideAlerterlogout>
              )}
            </div>

            <div className="searchbar displaySearchbar">
              <div className="searchBarPosition">
                <input
                  type="text"
                  className="form-control seachradius"
                  placeholder="Search...."
                />
                <div className="searchbarabsolute">
                  <div className="cooperative-search-field">
                    <select
                      className="form-control"
                      style={{ width: "150px", fontSize: "12px" }}
                    >
                      <option selected>Select an Option</option>
                      <option value="Cooperative">Cooperative</option>
                      <option value="Collector">Collector</option>
                    </select>
                    <i
                      style={{ paddingTop: "4px", color: "#999" }}
                      className="fas fa-angle-down notificationpositondrop"
                    ></i>
                  </div>
                </div>
                <div className="searchcloseAbsolute">
                  <i
                    className="fas fa-times  pointer searchBarclose"
                    onClick={closeSearchPopup}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <nav className="nav" style={darkBg}>
        <div className="uk-grid uk-flex-middle uk-flex-between uk-width-1">
          <div className="logo">
            <Link to="/profile">
              <img
                src={logo}
                alt="logo"
                style={{ height: "50px" }}
                className="uk-margin-auto uk-display-block"
              />
            </Link>
            <div className="ham-menu">
              <span>
                <i>
                  <GiHamburgerMenu
                    className="ham-icon"
                    color={mode === "light" ? "#777" : "#fff"}
                    size="1.5rem"
                    onClick={handleMenuChange}
                  />
                </i>
              </span>
            </div>
          </div>

          <div className="uk-width-expand ps-3" id="large-nav">
            <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
              <div>
                <h3 style={darkText}>
                  <span
                    className="uk-margin-remove"
                    style={{ color: mode === "light" ? "#004aad" : "#fff" }}
                  >
                    Hami
                  </span>{" "}
                  Chhimeki
                </h3>
                <p style={darkText}>Head Office</p>
                <p style={darkText} className="address">
                  Bharatpur Nepal, 027-400078
                </p>
              </div>

              <div>
                <span>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    defaultChecked
                    onClick={toggletheme}
                  />
                  <span className="uk-margin-small-left" style={darkText}>
                    {themeText}
                  </span>
                </span>

                {/* <span>
                  <select onChange={(e) => setSelect(e.target.value)}>
                    {options.map((opt) => (
                      <option
                        onClick={translate1}
                        key={opt.code}
                        value={opt.code}
                      >
                        {opt.name}
                      </option>
                    ))}
                  </select>
                </span> */}

                {/* <span className="notification">
                  <FaRegBell
                    size="1.3rem"
                    color={mode === "light" ? "#000" : "#fff"}
                  />
                  <span className="uk-badge">3</span>
                </span> */}
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-wrap uk-padding-remove uk-flex-middle">
            <span
              className="user uk-padding-remove uk-margin-remove"
              onClick={handleLogoClick}
            >
              <span>
                <img src={img} alt="user" />
                <span className="uk-margin-remove" style={darkText}>
                  {UserName}
                </span>
                <FiChevronDown color={mode === "light" ? "#000" : "#fff"} />
              </span>

              {logoutDropdownactive && (
                <OutsideAlerterlogout>
                  <span className="dropLogomenu">
                    <ul>
                      <li>
                        <a href="" onClick={logOut}>
                          <span>
                            <i className="fas fa-power-off mr-2"></i>Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </span>
                </OutsideAlerterlogout>
              )}
            </span>
            <span>
              <i
                className="fas fa-redo ms-3 pointer"
                onClick={handleRefresh}
                style={darkText}
                title="Refresh"
              ></i>
            </span>
            <div
              className="dots-icon"
              onClick={() => {
                setMobNav(!mobNav);
              }}
            >
              <BsThreeDotsVertical
                className="dots"
                color={mode === "light" ? "#000" : "#fff"}
              />
            </div>
          </div>
        </div>

        <div
          id="mob-nav"
          className={`${mobNav ? "mob-nav" : ""}`}
          style={darkBg2}
        >
          <div className="uk-margin-bottom">
            <h3 style={darkText}>
              <span
                className="uk-margin-remove"
                style={{ color: mode === "light" ? "#004aad" : "#fff" }}
              >
                Hami
              </span>{" "}
              Chhimeki
            </h3>
            <p style={darkText}>Head Office</p>
            <p style={darkText}>Bharatpur Nepal, 027-400078</p>
          </div>

          <div className="uk-margin-bottom">
            <span className="uk-padding">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                defaultChecked
                onClick={toggletheme}
              />
              <span className="uk-margin-small-left" style={darkText}>
                {themeText}
              </span>
            </span>
          </div>

          {/* <div className="uk-margin-bottom">
            <span>
              <select name="" id="">
                {options.map((opt) => (
                  <option value={opt.code}>{opt.name}</option>
                ))}
              </select>
            </span>
          </div>

          <div className="uk-margin-bottom">
            <span className="notification">
              <FaRegBell
                size="1.3rem"
                color={mode === "light" ? "#000" : "#fff"}
              />
              <span className="uk-badge">3</span>
            </span>
          </div> */}

          {/* <div className="uk-margin-bottom">
            <span className="user" onClick={handleLogoClick}>
              <img src={img} alt="user" />
              <span className="uk-margin-remove">{UserName}</span>
              <FiChevronDown />

              {logoutDropdownactive && (
                <OutsideAlerterlogout>
                  <span className="dropLogomenu1">
                    <ul>
                      <li>
                        <a href="" onClick={logOut}>
                          <span>
                            <i className="fas fa-power-off mr-2"></i>Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </span>
                </OutsideAlerterlogout>
              )}
            </span>
          </div> */}
        </div>
      </nav>
    </>
  );
}
