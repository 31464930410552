import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Member.css";
import HelplineContext from "../contextFolder/HelplineContextFolder/HelplineContext";
import EditHelpline from "./EditHelpline";

export default function EditHelplinePopup(props) {
  const { height } = UsewindowDimension();
  const {
    setHelplineFormValue,
    setHelplineFormError,
    setPerEditSubmit,
    setCloseChecked,
    helplineValue,
  } = useContext(HelplineContext);

  const closePopup = (e) => {
    e.preventDefault();
    props.setHelplineTrigger(false);
    $(".viewHelplinePop").fadeOut(100);
    setHelplineFormValue(helplineValue);
    setHelplineFormError({});
    setPerEditSubmit(false);
    setCloseChecked(false);
  };

  useEffect(() => {
    if (props.helplineTrigger) {
      $(".viewHelplinePop").fadeIn(100);
    }
  }, [props.helplineTrigger]);

  return (
    <div className="complainPopUp viewHelplinePop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div className={height < 500 ? "inside--fam ip500" : "inside--fam"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Edit Helpline</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="notice-outlet">
          <EditHelpline />
        </div>
      </div>
    </div>
  );
}
