import React, { useContext, useState } from "react";
import "../../css/Complain.css";
import SliderContext from "../contextFolder/SliderContextFolder/SliderContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import SliderPopup from "./SliderPopup";
import SliderTable from "./SliderTable";
// import ProfessionContext from '../contextFolder/ProfessionContextFolder/ProfessionContext';
// import ProfessionPopup from './ProfessionPopup';
// import ProfessionTable from './ProfessionTable';
// import ManagementPopUp from './ManagementPopUp';
// import ManagementTable from './ManagementTable';

function Slider() {
  const { mode, mainBg, mainContent } =
    useContext(SidebarContext);
  const context = useContext(SliderContext);
  const { setSliderFormValue, slidervalue ,popup, setPopup} = context;

  const [searchTerm, setSearchTerm] = useState("");

  const handleAddSlider = (e) => {
    // e.preventDefault();
    setPopup(true);
    setSliderFormValue(slidervalue);
  };

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Slider
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>
        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                  <button
                    className="btn btn-cmpy btn-sm"
                    onClick={handleAddSlider}
                  >
                    {" "}
                    Add Slider +
                  </button>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <SliderTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

      <SliderPopup sliderTrigger={popup} setSliderTrigger={setPopup} />
    </>
  );
}

export default Slider;
