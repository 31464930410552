import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
// import Basicform from './Basicform';
import UsewindowDimension from "../hooks/UsewindowDimension";
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import BasicForm from "./BasicForm";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import EventEdit from "./EventEdit";

export default function EventEditPop(props) {
  const { height } = UsewindowDimension();

  const context = useContext(EventContext);

  const {
    basicFormErrors,
    setBasicFormErrors,
    initialvalue,
    isSubmit,
    setIsSubmit,
    basicFormValue,
    setBasicFormvalue,
    setIsUploaded,
    setImage,
    setPushNotice
  } = context;

  // const context = useContext(cooperativeContext)
  // const {loading,basicformInitialValue,contactFormInitailValue,licenseformValueInitialValue
  //   ,setContactFormvalue,setBasicFormvalue,setlicenseformValue,popup,setPopup, basicFormErrors, setBasicFormErrors, basicIsSubmit, setBasicIsSubmit} = context;

  // const [activeTab, setActiveTab] = useState({
  //     tab1:true,
  // });
  // const handleTab1 = () => {
  //     // update the state to tab1
  //     setActiveTab({
  //         tab1:true,
  //     });
  //   };

  const closePopup = (e) => {
    e.preventDefault();
    props.setTriggernew(false);
    $(".displayPopups").fadeOut(100);
    setBasicFormErrors({});
    setIsSubmit(false);
    setImage(null);
    setIsUploaded(false);
    setPushNotice(false);
  };

  useEffect(() => {
    if (props.trigger) {
      $(".displayPopups").fadeIn(100);
    }
  }, [props.trigger]);

  return (
    <div
      className="popUP displayPopups container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
      style={{ overflow: "scroll" }}
    >
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "insidePopup ip500" : "insidePopup"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Event & Meeting Edit</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="outlet">
          {/* {activeTab.tab1 &&(<Basicform active={activeTab} setActive={setActiveTab} />)} */}
          <EventEdit />
        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  );
}
