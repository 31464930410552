import React, { useContext, useEffect, useState } from "react";
import "../../css/Complain.css";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import Spinner from "../Spinner/Spinner";
import FamilyEditPop from "./FamilyEditPop";

const FamilyTable = ({ searchTerm }) => {
  const {
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);
  const context = useContext(FamilyContext);
  const {
    familylist,
    deactivateFam,
    editPop,
    setEditPop,
    handleEdit,
    loading,
  } = context;
  //   const {  } = useContext(MemberContext);

  //To change activate/deactivate status
  const changeStatus = (id, IsActive) => {
    deactivateFam(id, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  };

  // const handleDelete = (i) => {
  //   console.log(i, "ccc");
  // };
  // console.log(familylist);
  if (familylist.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              height: "254px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <table className="table">
                <thead>
                  <tr className="tableHead">
                    <td>S.N.</td>
                    {/* <td className='tc'>Image</td> */}
                    <td>Full Name</td>
                    <td>Relation</td>
                    <td>Gender</td>
                    <td>Contact</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {familylist
                    .filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.FullName.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        )
                      ) {
                        return val;
                      }
                    })
                    .map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        {/* <td className="contentLogo tc" ><div className="mgmtImg tl"><img src={item.Image} alt="" /> </div></td> */}
                        <td>{item.FullName}</td>
                        <td>{item.Relation}</td>
                        <td>{item.Gender} </td>
                        <td>{item.Contact} </td>
                        <td>
                          {" "}
                          <span
                            className="rejectspan badge"
                            onClick={() => handleEdit(item)}
                          >
                            Edit
                          </span>
                          <span
                            className="editspan badge"
                            onClick={() =>
                              changeStatus(item.FamilyID, item.Status)
                            }
                          >
                            {checkStatus(item.Status)}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <FamilyEditPop familyTrigger={editPop} setFamilyTrigger={setEditPop} />
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{ overflowX: "scroll", overflowY: "scroll", height: "254px" }}
        >
          <table className="table">
            <thead>
              <tr className="tableHead">
                <td>S.N.</td>
                {/* <td
                  
                >
                  Image
                </td> */}
                <td>Full Name</td>
                <td>Relation</td>
                <td>Gender</td>
                <td>Contact</td>
                {/* <td
                  
                >
                  Action
                </td> */}
              </tr>
            </thead>
          </table>
          <div className="text-center mt-4">
            <p> Data Not Found </p>
          </div>
        </div>
      </div>
    );
  }
};

export default FamilyTable;
