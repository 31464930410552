import React, { useState, useContext, useEffect } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
// import Basicform from './Basicform';
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ManagementForm from './ManagementForm';
import ManagementContext from '../contextFolder/ManagementContextFolder/ManagementContext';


export default function ManagementPopUp(props) {
  const { height } = UsewindowDimension();

  const context = useContext(ManagementContext)

  const { setIsUploaded, setImage, managementFormValue, setManagementFormValue, managementvalue, setManagementFormError, setIsSubmit, setPushNotice } = context;


  const closePopup = (e) => {
    e.preventDefault();

    setManagementFormValue(managementvalue);

    props.setManagementTrigger(false)
    $('.displayPopup').fadeOut(100);
    setIsUploaded(false);
    setImage(null);

    setManagementFormError({});
    setIsSubmit(false);
    setPushNotice(false);
  }

  useEffect(() => {
    if (props.managementTrigger) {
      $('.displayPopup').fadeIn(100);
    }
  }, [props.managementTrigger])

  return (

    <div className="popUP displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 " style={{ overflow: "scroll" }}>
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "insidePopup ip500" : "insidePopup"}>
        <div className="popUpHeader ">
          <div className='popUpHeaderText '> Create Management</div>
          <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
        </div>


        <div className="outlet">
          <ManagementForm />

        </div>

      </div>

      {/* </OutsideAlerter> */}
    </div>
  )

}