import React, { useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import ManagementPositionForm from "./ManagementPositionForm";
import ManagementContext from "../contextFolder/ManagementContextFolder/ManagementContext";

function ManagementTypePop(props) {
  const { height } = UsewindowDimension();

  const context = useContext(ManagementContext);

  const {
    data,
    setData,
    setPositionFormError,
    update,
    setUpdate,
    setPositionSubmit,
  } = context;

  const closePopup = (e) => {
    e.preventDefault();
    props.setTriggernew1(false);
    $(".displaypop").fadeOut(100);
    setData({ titleName: "" });
    setPositionFormError({});
    setPositionSubmit(false);
    setUpdate(false);
  };

  useEffect(() => {
    if (props.trigger1) {
      $(".displaypop").fadeIn(100);
    }
  }, [props.trigger1]);

  return (
    <div className="eventPop displaypop">
      {/* <OutsideAlerter> */}
      <div className={height < 250 ? "inside--event ip150" : "inside--event"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText">Create Position</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        {/* {loading ? <Spinner/> :(        */}

        <div className="event-outlet" style={{ paddingTop: "10px" }}>
          <ManagementPositionForm />
        </div>
      </div>
    </div>
  );
}

export default ManagementTypePop;
