import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import '../../css/Notification.css';
import NoticeContext from '../contextFolder/NoticeContextFolder/NoticeContext';
import ChhimekiContext from '../contextFolder/ChhimekiContextFolder/ChhimekiContext';
import ChhimekiForm from './ChhimekiForm';



export default function ChhimekiPopUp(props) {
    const { height } = UsewindowDimension();

    const context = useContext(ChhimekiContext)

    const {

        chhimekiFormValue, setChhimekiFormValue, chhimekivalue, chhimekiFormError, setChhimekiFormError, isSubmit, setIsSubmit, loading, setLoading, setPopup, setIsUploaded, setImage


    } = context;



    const closePopup = (e) => {
        e.preventDefault();
        setChhimekiFormValue(chhimekivalue);
        setChhimekiFormError({});
        setIsSubmit(false);
        setIsUploaded(false);
        setImage(null);
        props.setChhimekiTrigger(false)
        $('.chhimekiPop').fadeOut(100);

    }

    useEffect(() => {

        if (props.chhimekiTrigger) {
            $('.chhimekiPop').fadeIn(100);
        }
    }, [props.chhimekiTrigger])



    return (

        <div className="chhPopUp chhimekiPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >

            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"}>
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Create Chhimkei List</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>




                <div className="notice-outlet">
                    <ChhimekiForm />

                </div>


            </div>


        </div>
    )

}