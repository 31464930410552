import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import React, { useContext, useEffect, useState } from "react";
import "../../../css/Complain.css";
import AccountSummaryTable from "./AccountSummaryTable";
import AccountContext from "../../contextFolder/AccountContextFolder/AccountContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BsFileEarmarkPdfFill } from "react-icons/bs";

function AccountSummary() {
  const [searchTerm, setSearchTerm] = useState("");
  const { sumDateFormValue, setSumDateFormValue } = useContext(AccountContext);
  const { mode, mainBg, mainContent } = useContext(SidebarContext);

  const handleDate = ({ bsDate }) => {
    setSumDateFormValue({ ...sumDateFormValue, fromDate: bsDate });
  };
  const handleDate1 = ({ bsDate }) => {
    setSumDateFormValue({ ...sumDateFormValue, toDate: bsDate });
  };

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Account Summary
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>
        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row" style={{ marginTop: "10px" }}>
                <div
                  className="col-lg-2  col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label
                    htmlFor="fromDate"
                    style={{ color: mode === "light" ? "#000" : "#fff" }}
                  >
                    From Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={sumDateFormValue.fromDate}
                    onChange={handleDate}
                    defaultDate="YYYY/MM/DD"
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div>

                <div
                  className="col-lg-2 col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label
                    htmlFor="toDate"
                    style={{ color: mode === "light" ? "#000" : "#fff" }}
                  >
                    To Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    values={sumDateFormValue.toDate}
                    onChange={handleDate1}
                    defaultDate="YYYY/MM/DD"
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div>

                <div
                  className="col-lg-1 col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                ></div>

                <div
                  className="col-lg-2 offset-lg-5 offset-md-3 offset-sm-0 col-md-3 col-sm-3 align-self-end Search "
                  style={{ padding: "5px" }}
                >
                  {/* <PDFDownloadLink
                    document={<AccountSummaryTable />}
                    fileName="PDF"
                  >
                    <button uk-tooltip="Pdf">
                      <BsFileEarmarkPdfFill
                        style={{ color: mode === "light" ? "red" : "#fff" }}
                      />
                    </button>
                  </PDFDownloadLink> */}

                  <div className="dashboard-search-field justify-content-end">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="">
                <div className="col-lg-12 p-1">
                  <AccountSummaryTable searchTerm={searchTerm} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AccountSummary;
