import React, { useContext, useEffect, useState } from "react";
import "../../css/Complain.css";

import CryptoJS from "crypto-js";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import Spinner from "../Spinner/Spinner";
import ProfessionEditPop from "./ProfessionEditPop";
import SidebarContext from "../sidebarcontext/SidebarContext";

const ProfessionTable = ({ searchTerm }) => {
  const {
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);
  const context = useContext(ProfessionContext);
  const { proList, deactivateProf, editPop, setEditPop, handleEdit, loading } =
    context;

  //To change activate/deactivate status
  const changeStatus = (id, IsActive) => {
    deactivateProf(id, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  };

  if (proList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              height: "254px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <table className="table">
                <thead>
                  <tr className="tableHead">
                    <td>S.N.</td>
                    <td>Profession</td>
                    <td>Organization</td>
                    <td>Address</td>
                    <td>Position</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {proList
                    .filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.Profession.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        ) ||
                        val.OrgName.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        ) ||
                        val.OrgAddress.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        ) ||
                        val.OrgPosition.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        )
                      ) {
                        return val;
                      }
                    })
                    .map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.Profession}</td>
                        <td>{item.OrgName}</td>
                        <td>{item.OrgAddress}</td>
                        <td>{item.OrgPosition} </td>
                        <td>
                          {" "}
                          <span
                            className="rejectspan badge"
                            onClick={() => handleEdit(item)}
                          >
                            Edit
                          </span>{" "}
                          <span
                            className="editspan badge"
                            onClick={() =>
                              changeStatus(item.ProfID, item.Status)
                            }
                          >
                            {checkStatus(item.Status)}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <ProfessionEditPop
          professionTrigger={editPop}
          setProfessionTrigger={setEditPop}
        />
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{ overflowX: "scroll", overflowY: "scroll", height: "254px" }}
        >
          <table className="table">
            <thead>
              <tr className="tableHead">
                <td>S.N.</td>
                <td>Profession</td>
                <td>Organization Name</td>
                <td>Address</td>
                <td>Position</td>
                {/* <td
                  
                >
                  Action
                </td> */}
              </tr>
            </thead>
          </table>
          <div className="text-center mt-4">
            <p> Data Not Found </p>
          </div>
        </div>
      </div>
    );
  }
};

export default ProfessionTable;
