import React, { useContext } from "react";
import CloseIcon from "../../images/CloseIcon.svg";
import '../../css/DeletePop.css';
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

export default function ResetPassword({ handleResetTrue, handleResetFalse}) {
    const context = useContext(MemberContext);
    const {generatedPassword} = context;
    // console.log({generatedPassword})
    return (
        <>
            <div className="container confirm-popup">
                <div className="confirm-popup-inner ">
                    <div className="popUpHeader ps-0 pe-0">
                        <div className="popUpDelTitle">Hami Chhimeki</div>
                        <div className="popUpClose">
                            <img style={{ paddingRight: "10px" }}
                                className="popUpCloseIcon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={handleResetFalse}
                            />
                        </div>
                    </div>

                    <div className="confirm-body ps-3 pe-3">
                        <div className="row text-start ">
                            <div className="delText">Are you sure want to Reset Password ?</div>
                          
                            <small><b>Your password will be {generatedPassword}</b></small> 
                            {/* <button onClick={generatedPassword}>Generate Password</button>
                            {console.log(generatedPassword)} */}
                        </div>
                    </div>

                    <div className="confirm-footer">
                        <div className="row  mt-2 mb-2">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-sm me-2"
                                    style={{ background: "#0079bf", color: "white" }}
                                    onClick={handleResetTrue}
                                >
                                    Okay
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger me-3"
                                    style={{ color: "white" }}
                                    onClick={handleResetFalse}
                                >
                                    Cancel
                                </button>
                                
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

