import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import Spinner from "../Spinner/Spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import HelplineContext from "../contextFolder/HelplineContextFolder/HelplineContext";
import EditHelplinePopup from "./EditHelplinePopup";
import HelplineDelete from "./HelplineDelete";

const HelplineTable = ({ searchTerm }) => {
  const { tableHeadBg, tableDataBg, darkText, tableHeadWidthBg } =
    useContext(SidebarContext);

  const {
    loading,
    helplineList,
    handleEdit,
    viewPop,
    setViewPop,
    deactivateHelpline,
    handleDeleteTrue,
    handleDeleteFalse,
    handleDelete,
    delPopup,
  } = useContext(HelplineContext);

  //To change activate/deactivate status
  const changeStatus = (id, IsActive) => {
    const obj = {
      IsActive: IsActive,
    };
    deactivateHelpline(id, obj.IsActive.toString());
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };

  if (helplineList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "65vh",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div>
                  <table className="table">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>S.N.</td>
                        <td style={tableHeadBg}>Title</td>
                        <td style={tableHeadBg}>Name</td>
                        <td style={tableHeadBg}>Contact</td>
                        <td style={tableHeadWidthBg}>Status</td>
                        <td style={tableHeadWidthBg}>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {helplineList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.contactname.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.forhelp.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg}>{i + 1}</td>
                            <td style={tableDataBg}>{item.forhelp}</td>
                            <td style={tableDataBg}>{item.contactname}</td>
                            <td style={tableDataBg}>{item.contact}</td>

                          
                              <td style={tableDataBg}>
                              <span
                                className="editspan badge"
                                onClick={() =>
                                  changeStatus(item.helplineid, item.isactive)
                                }
                                style={darkText}
                              >
                                {checkStatus(item.isactive)}
                              </span>
                            </td>

                            <td style={tableDataBg}>
                              <span
                                className="rejectspan badge"
                                onClick={() => handleEdit(item)}
                                style={darkText}
                              >
                                Edit
                              </span>
                              <span
                              className="rejectspan badge"
                              onClick={() => {
                                handleDelete(item.helplineid);
                              }}
                              style={darkText}
                            >
                              Remove
                            </span>
                              </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <EditHelplinePopup
          helplineTrigger={viewPop}
          setHelplineTrigger={setViewPop}
        />
         {delPopup.show && (
          <HelplineDelete
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "400px",
          }}
        >
          <div>
            <div>
              <table className="table">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>S.N.</td>
                    <td style={tableHeadBg}>Title</td>
                    <td style={tableHeadBg}>Name</td>
                    <td style={tableHeadBg}>Contact</td>
                    <td style={tableHeadWidthBg}>Status</td>
                    <td style={tableHeadWidthBg}>Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}>No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default HelplineTable;
