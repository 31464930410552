import React, { useContext, useState, useEffect } from "react";
import "../../css/Complain.css";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import FamilyPopUp from "./FamilyPopUp";
import FamilyTable from "./FamilyTable";
import $ from "jquery";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import SidebarContext from "../sidebarcontext/SidebarContext";

function FamilyMain() {
  const { setPopup } = useContext(SidebarContext);
  const context = useContext(FamilyContext);
  const {
    setStep,
    setCloseChecked,
    closeChecked,
    otherSubmit,
    setOtherSubmit,
    editdata,
    setViewPop,
    memberFormValue,
    setActiveTab,
    setDef,
  } = useContext(MemberContext);
  const {
    famPopup,
    setFamPopup,
    setFamilySubmit,
    familyFormValue,
    setFamilyFormValue,
    formValue,
    editData,
    familySubmit,
    familyFormError,
  } = context;

  const [last, setLast] = useState(false);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
  };

  const closePopup = (e) => {
    e.preventDefault();
    $(".viewMemberPop").fadeOut(100);
    setPopup(false);
    setStep(1);
    // setFamilySubmit(false);
    setOtherSubmit(false);
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchresult, setSearchresult] = useState([]);

  const handleAddFamily = (e) => {
    e.preventDefault();
    setFamPopup(true);
    setFamilyFormValue(formValue);
    setDef(Math.random());
  };

  const handleSearch = (e) => {
    // e.preventDefault();
    // setSearchTerm(e.target.value);
    // if (searchTerm !== "") {
    //     const searchresultdata = cooperative.filter((item) => {
    //         return Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase());
    //     })
    //     setSearchresult(searchresultdata)
    // } else {
    //     setSearchresult(cooperative);
    // }
  };
  useEffect(() => {
    if (last === true && otherSubmit) {
      editdata(memberFormValue);
      if (closeChecked === true) {
        setLast(false);
        $(".viewMemberPop").fadeOut(100);
        setViewPop(false);
      }
      setOtherSubmit(false);
    }
  }, [last]);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const formSubmit = (e) => {
    setLast(true);
    e.preventDefault();
    setOtherSubmit(true);
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <section className="content-section">
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 p-2 Search">
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      value={searchTerm}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>

                <div className="col-lg-5 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                  <button
                    className="btn btn-cmpy btn-sm"
                    onClick={handleAddFamily}
                  >
                    {" "}
                    Add Family +
                  </button>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <FamilyTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

      <div className="col-lg-12 Member--Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <FamilyPopUp familyTrigger={famPopup} setFamilyTrigger={setFamPopup} />
    </>
  );
}

export default FamilyMain;
