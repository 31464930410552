import React, { useContext, useState } from "react";
import "../../css/Complain.css";
import SidebarContext from "../sidebarcontext/SidebarContext";
import HelplinePopup from "./HelplinePopup";
import HelplineTable from "./HelplineTable";

function Helpline() {
  const { mode, mainBg, darkText, setPopup, popup, mainContent } =
    useContext(SidebarContext);
 

  const [searchTerm, setSearchTerm] = useState("");

  const handleAddMember = (e) => {
    e.preventDefault();
    setPopup(true);
  };

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div className="fontHeader" style={darkText}>
              Helpline
            </div>
          </div>
          {/* <hr style={darkText} /> */}
        </div>
        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-sm-6 p-2 Search">
                  <div className="dashboard-search-field">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-4 offset-md-0 offset-sm-0 col-md-6 col-sm-6 text-end p-2">
                  <button
                    className="btn btn-cmpy btn-sm"
                    onClick={handleAddMember}
                  >
                    {" "}
                    Add Helpline +
                  </button>
                </div>
              </div>
            </div>
            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <HelplineTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

      <HelplinePopup helplineTrigger={popup} setHelplineTrigger={setPopup} />
    </>
  );
}

export default Helpline;
