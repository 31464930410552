import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import Family from "./Family";
import FamilyEdit from "./FamilyEdit";

export default function FamilyEditPop(props) {
  const context = useContext(FamilyContext);

  const { setFamilyFormValue, formValue, setFamilyFormError, setEditIsSubmit } =
    context;
  const { height } = UsewindowDimension();

  const closePopup = (e) => {
    e.preventDefault();
    props.setFamilyTrigger(false);
    $(".displayPopups").fadeOut(100);
    setFamilyFormError({});
    setEditIsSubmit(false);
  };

  useEffect(() => {
    if (props.familyTrigger) {
      $(".displayPopups").fadeIn(100);
    }
  }, [props.familyTrigger]);

  return (
    <div className="complainPopUp displayPopups container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div className={height < 500 ? "inside--prof ip500" : "inside--prof"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Edit Family</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="notice-outlet">
          <FamilyEdit />
        </div>
      </div>
    </div>
  );
}
