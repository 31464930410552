import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
// import Basicform from './Basicform';
import UsewindowDimension from "../hooks/UsewindowDimension";
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ManagementContext from "../contextFolder/ManagementContextFolder/ManagementContext";
import ManagementEdit from "./ManagementEdit";

export default function ManagementEditPop(props) {
  const { height } = UsewindowDimension();

  const context = useContext(ManagementContext);

  const {
    setIsUploaded,
    setImage,
    managementFormValue,
    setManagementFormValue,
    managementvalue,
    setManagementFormError,
    setEditIsSubmit,
    setPushNotice
  } = context;

  const closePopup = (e) => {
    e.preventDefault();
    props.setManagementTrigger(false);
    $(".displayPopups").fadeOut(100);
    setIsUploaded(false);
    setImage(null);
    setManagementFormError({});
    setEditIsSubmit(false);
    setPushNotice(false);
  };

  useEffect(() => {
    if (props.managementTrigger) {
      $(".displayPopups").fadeIn(100);
    }
  }, [props.managementTrigger]);

  return (
    <div
      className="popUP displayPopups container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
      style={{ overflow: "scroll" }}
    >
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "insidePopup ip500" : "insidePopup"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText "> Edit Management</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="outlet">
          <ManagementEdit />
        </div>
      </div>

      {/* </OutsideAlerter> */}
    </div>
  );
}
