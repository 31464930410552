import React from "react";
import { useContext } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";

const ProfileAddress = () => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  console.log("co", userinfo);
  const { darkText } = useContext(SidebarContext);
  return (
    <div className="wrapper">
      <p className="profile-border mt-3" style={darkText}>
        Temporary Address
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Provience</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempProvince != "" ? (
              userinfo.TempProvince
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Region</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempZone != "" ? (
              userinfo.TempZone
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>District</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempDistrict != "" ? (
              userinfo.TempDistrict
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Municipality</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempVDC != "" ? (
              userinfo.TempVDC
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Ward No.</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempWard != "" ? (
              userinfo.TempWard
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>House No.</h5>
          <p style={darkText}>
            {" "}
            {userinfo.TempHouseNo != "" ? (
              userinfo.TempHouseNo
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <p className="profile-border" style={darkText}>
        Permanent Address
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Provience</h5>
          <p style={darkText}>
            {" "}
            {userinfo.PermProvinceID != "" ? (
              userinfo.PermProvinceID
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Region</h5>
          <p style={darkText}>
            {" "}
            {userinfo.PermZone != "" ? (
              userinfo.PermZone
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>District</h5>
          <p style={darkText}>
            {" "}
            {userinfo.PermDistrict != "" ? (
              userinfo.PermDistrict
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Municipality</h5>
          <p style={darkText}>
            {" "}
            {userinfo.PermVDC != "" ? (
              userinfo.PermVDC
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Ward No.</h5>
          <p>
            {" "}
            {userinfo.PermWard != "" ? (
              userinfo.PermWard
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>House No.</h5>
          <p style={darkText}>
            {" "}
            {userinfo.PermHouseNo != "" ? (
              userinfo.PermHouseNo
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileAddress;
