import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";

const ProfileFamily = () => {
  const { darkText, urll } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [familylist, setFamilylist] = useState([]);

  useEffect(() => {
    const dataForm = {
      ChhimekiID: userinfo.ChhimekiID,
      MemberID: userinfo.UserID,
      Flag: "S",
    };
    console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/family-detail`, requestOptions).then((result) => {
      result.json().then((resp) => {
        console.log(resp, "ress");
        if (resp.StatusCode === 200) {
          const postResult = resp.FamilyLst ? resp.FamilyLst : "";
          setFamilylist(postResult);
        } else {
          setFamilylist([]);
        }
      });
    });
  }, []);

  return (
    <div className="mt-3">
      {familylist.length > 0 ? (
        <>
          {familylist.map((props) => {
            const { FullName, Relation, FamilyID } = props;
            return (
              <div key={FamilyID} className="profile-wrap">
                <h5 style={darkText}>{FullName}</h5>
                <p style={darkText}>
                  {Relation === null ? <span>Not mentioned</span> : Relation}
                </p>
              </div>
            );
          })}
        </>
      ) : (
        <span style={darkText}>No Data</span>
      )}
    </div>
  );
};

export default ProfileFamily;
