import React, { useState, useContext, useEffect, useRef } from 'react'
import UsewindowDimension from '../hooks/UsewindowDimension'
import $ from "jquery";
import '../../css/Notification.css';
import LedgerForm from './LedgerForm';
import LedgerContext from '../contextFolder/LedgerContextFolder/LedgerContext';
import SidebarContext from '../sidebarcontext/SidebarContext';


export default function LedgerPopUp(props) {
    const { height } = UsewindowDimension();

    const context = useContext(LedgerContext)

    const {  setLedgerFormValue, ledgervalue, setLedgerFormError, setLedgerSubmit, setPushNotice } = context;

    const {setPopup}=useContext(SidebarContext)



    const myRef = useRef(null)


    const closePopup = (e) => {
        e.preventDefault();
        props.setLedgerTrigger(false)
        $(".displayPopup").fadeOut(100);
        setPopup(false)
        setLedgerFormValue(ledgervalue);
        setLedgerFormError({});
        setLedgerSubmit(false);
        setPushNotice(false);
        myRef.current.scrollIntoView()
    }

    useEffect(() => {

        if (props.ledgerTrigger) {
            $('.displayPopup').fadeIn(100);
        }
    }, [props.ledgerTrigger])



    return (

        <div className="noticePopUp displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"} ref={myRef} >
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Create Ledger</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>



                <div className="ledger-outlet">
                    <LedgerForm />

                </div>

            </div>
        </div>
    )

}