import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Member.css";
import HelplineContext from "../contextFolder/HelplineContextFolder/HelplineContext";


export default function EditHelpline(props) {
  const { height } = UsewindowDimension();
  const {
    helplineFormValue,
    setHelplineFormValue,
    helplineFormError,
    setHelplineFormError,
    perEditSubmit,
    setPerEditSubmit,
    closeChecked,
    setCloseChecked,
    helplineValue,
    editdata,
    setViewPop,
  } = useContext(HelplineContext);

  const closePopup = (e) => {
    e.preventDefault();

    $(".viewHelplinePop").fadeOut(100);
    setHelplineFormValue(helplineValue);
    setHelplineFormError({});
    setPerEditSubmit(false);
    setCloseChecked(false);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setHelplineFormValue({ ...helplineFormValue, [name]: value });
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    if (!values.service) {
      errors.service = "Required";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.number) {
      errors.number = "Required";
    } else if (!numv.test(values.number)) {
      errors.number = "Must be digits";
    } else if (!digits.test(values.number)) {
      errors.number = "Must be 10 digits";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setHelplineFormError(validate(helplineFormValue));
    setPerEditSubmit(true);
  };
  
  useEffect(() => {
    if (Object.keys(helplineFormError).length === 0 && perEditSubmit) {
      editdata(helplineFormValue);
      // if (closeChecked === true) {
        $(".viewHelplinePop").fadeOut(100);
        setViewPop(false);
      // }
      setHelplineFormError({});
      setHelplineFormValue(helplineValue);
      setPerEditSubmit(false);
    }
  }, [helplineFormError]);

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="service">
                Service <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="service"
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="service"
                value={helplineFormValue.service}
              />
              <p className="errormsg ">{helplineFormError.service}</p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="name">
                Name <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="name"
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="name"
                value={helplineFormValue.name}
              />
              <p className="errormsg ">{helplineFormError.name}</p>
            </div>
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="number">
                Number <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="number"
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                id="number"
                value={helplineFormValue.number}
              />
              <p className="errormsg ">{helplineFormError.number}</p>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div class=" notification-form-check" style={{ display: "flex" }}>
          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
