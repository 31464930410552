import React from "react";
import { useContext } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";

const ProfilePersonal = () => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { darkText } = useContext(SidebarContext);

  return (
    <div className="wrapper">
      <p className="profile-border mt-3" style={darkText}>
        Personal Information
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Birth Date (B.S)</h5>
          <p style={darkText}>
            {userinfo.DOBBS != "" ? userinfo.DOBBS : <span>Not mentioned</span>}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Birth Date (A.D)</h5>
          <p style={darkText}>
            {userinfo.DOBAD != "" ? userinfo.DOBAD : <span>Not mentioned</span>}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Nationality</h5>
          <p style={darkText}>
            {userinfo.Nationality != "" ? (
              userinfo.Nationality
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Religion</h5>
          <p style={darkText}>
            {userinfo.Religion != "" ? (
              userinfo.Religion
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Title</h5>
          <p style={darkText}>
            {userinfo.Title != "" ? userinfo.Title : <span>Not mentioned</span>}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Gender</h5>
          <p style={darkText}>
            {userinfo.Gender != "" ? (
              userinfo.Gender
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Marital Status</h5>
          <p style={darkText}>
            {userinfo.MaritialStatus != "" ? (
              userinfo.MaritialStatus
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Blood Group</h5>
          <p style={darkText}>
            {userinfo.BloodGroup != "" ? (
              userinfo.BloodGroup
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Father's Name</h5>
          <p style={darkText}>
            {userinfo.FatherName != "" ? (
              userinfo.FatherName
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Mother's Name</h5>
          <p style={darkText}>
            {userinfo.MotherName != "" ? (
              userinfo.MotherName
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Grandfather's Name</h5>
          <p style={darkText}>
            {userinfo.GrandFather != "" ? (
              userinfo.GrandFather
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Academic Qualification</h5>
          <p style={darkText}>
            {userinfo.Qualification != "" ? (
              userinfo.Qualification
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <div className="row profile-wrap mt-sm-3">
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Phone (House)</h5>
          <p style={darkText}>
            {userinfo.PhoneHome != "" ? (
              userinfo.PhoneHome
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Phone (Office)</h5>
          <p style={darkText}>
            {userinfo.PhoneOffice != "" ? (
              userinfo.PhoneOffice
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Pan No.</h5>
          <p style={darkText}>
            {userinfo.PAN != "" ? userinfo.PAN : <span>Not mentioned</span>}
          </p>
        </div>
      </div>

      <p className="profile-border" style={darkText}>
        Citizenship Card Information
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Citizenship No.</h5>
          <p style={darkText}>
            {userinfo.CitizenshipNo != "" ? (
              userinfo.CitizenshipNo
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4">
          <h5 style={darkText}>Issued Date</h5>
          <p style={darkText}>
            {userinfo.CitizenshipIssueDate != "" ? (
              userinfo.CitizenshipIssueDate
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Issued By</h5>
          <p style={darkText}>
            {userinfo.CitizenshipIssueBy != "" ? (
              userinfo.CitizenshipIssueBy
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <p className="profile-border" style={darkText}>
        Voter Info
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Voter's Id</h5>
          <p style={darkText}>
            {userinfo.VoterID != "" ? (
              userinfo.VoterID
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4">
          <h5 style={darkText}>Issued Date</h5>
          <p style={darkText}>
            {userinfo.VoterIssueDate != "" ? (
              userinfo.VoterIssueDate
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Issued By</h5>
          <p style={darkText}>
            {userinfo.VoterIssueBy != "" ? (
              userinfo.VoterIssueBy
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <p className="profile-border" style={darkText}>
        License Information
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>License No.</h5>
          <p style={darkText}>
            {userinfo.LicenseNo != "" ? (
              userinfo.LicenseNo
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4">
          <h5 style={darkText}>Issued Date</h5>
          <p style={darkText}>
            {userinfo.LicenseIssueDate != "" ? (
              userinfo.LicenseIssueDate
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Issued By</h5>
          <p style={darkText}>
            {userinfo.LicesneIssueBy != "" ? (
              userinfo.LicesneIssueBy
            ) : (
              <span>Not mentioned</span>
            )}
          </p>
        </div>
      </div>

      <p className="profile-border" style={darkText}>
        Electricity Information
      </p>

      <div className="row profile-wrap">
        <div className="col-sm-4">
          <h5 style={darkText}>Electricity No.</h5>
          <p style={darkText}>
            {userinfo.ElectricNo === null ? (
              <span>Not mentioned</span>
            ) : (
              userinfo.ElectricNo
            )}
          </p>
        </div>
        <div className="col-sm-4">
          <h5 style={darkText}>Issued Date</h5>
          <p style={darkText}>
            {userinfo.ElectricIssueDate === null ? (
              <span>Not mentioned</span>
            ) : (
              userinfo.ElectricIssueDate
            )}
          </p>
        </div>
        <div className="col-sm-4 mt-3 mt-sm-0">
          <h5 style={darkText}>Issued By</h5>
          <p style={darkText}>
            {userinfo.ElectricIssueBy === null ? (
              <span>Not mentioned</span>
            ) : (
              userinfo.ElectricIssueBy
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfilePersonal;
