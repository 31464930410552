import React, { useContext, useState, useEffect } from "react";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/imagePreview.css";
import "../../css/Member.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import SidebarContext from "../sidebarcontext/SidebarContext";

export default function Other(props) {
  const { setPopup } = useContext(SidebarContext);
  // const { setFamilyList } = useContext(FamilyContext);
  // const { setProList } = useContext(ProfessionContext);
  const context = useContext(MemberContext);
  const {
    reload,
    setReload,

    memberFormValue,
    setMemberFormValue,
    finalSubmit,
    setFinalSubmit,
    setStep,
    setTemporaryIsSubmit,
    setTemporaryFormError,
    setPermanentIsSubmit,
    setPermanentFormError,
    setIsSubmit,
    setMemberFormErrors,
    membervalue,
    fetchdata,
    finalFormError,
    setFinalFormError,
    closeChecked,
    setCloseChecked,
    image,
    re,
    setRe,
  } = context;

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const [last, setLast] = useState(false);

  const closePopup = (e) => {
    e.preventDefault();

    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setMemberFormValue(membervalue);
    setMemberFormErrors({});
    setIsSubmit(false);
    setPermanentFormError({});
    setPermanentIsSubmit(false);
    setTemporaryFormError({});
    setTemporaryIsSubmit(false);
    setFinalSubmit(false);
    setStep(1);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLast(true);
    // setFinalFormError(validate(memberFormValue))
    console.log(memberFormValue);
    setFinalSubmit(true);
    // setRe(Math.random());
  };

  useEffect(() => {
    if (last === true && finalSubmit) {
      fetchdata(memberFormValue, image);
      if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setPopup(false);
      }

      setIsSubmit(false);
    }
  }, [last]);

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setMemberFormValue({ ...memberFormValue, [name]: value });
  };

  const handleDate1 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, cDate: bsDate });
  };

  const handleDate3 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, pDate: bsDate });
  };

  const handleDate5 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, vDate: bsDate });
  };

  const handleDate7 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, lDate: bsDate });
  };

  const handleDate9 = ({ bsDate }) => {
    setMemberFormValue({ ...memberFormValue, eDate: bsDate });
  };

  // const validate = (values) => {
  //     const errors = {};
  //     if (!values.tprovience) {
  //         errors.citizenship = "Required";
  //     }

  //     return errors;
  // };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="uName">
                Username
              </label>

              <input
                type="text"
                name="uName"
                onChange={handleChange}
                value={memberFormValue.uName}
                className="form-control form-control-sm mb-1"
                id="uName"
              />
              {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
            </div>

            <div className="form-group col-md-6">
              <label className="form-label" htmlFor="passport">
                Password
              </label>
              <input
                type="text"
                name="passport"
                onChange={handleChange}
                value={memberFormValue.passport}
                className="form-control form-control-sm mb-1"
                id="passport"
              />
              {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
            </div>

          </div>


        </form>
      </div>

      {/* <div className="col-lg-12 memberFormFooter">

                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "red" }} onClick={() => setStep(3)} >Back</button>
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formSubmit} >Submit</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div> */}

      <div className="col-lg-12 Member-Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "red" }}
            onClick={() => setStep(5)}
          >
            Back
          </button>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
