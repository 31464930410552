import React, { useState, useContext, useEffect, useRef } from 'react'
import UsewindowDimension from '../hooks/UsewindowDimension'
import $ from "jquery";
import '../../css/Notification.css';
import IncomeForm from './IncomeForm';
import IncomeContext from '../contextFolder/IncomeContextFolder/IncomeContext';
import SidebarContext from '../sidebarcontext/SidebarContext';


export default function IncomePopUp(props) {
    const { height } = UsewindowDimension();

    const context = useContext(IncomeContext)

    const {  setIncomeFormValue, incomevalue, setIncomeFormError, setIncomeSubmit, setPushNotice } = context;

    const {setPopup}=useContext(SidebarContext)



    const myRef = useRef(null)


    const closePopup = (e) => {
        e.preventDefault();
        props.setIncomeTrigger(false)
        $(".displayPopup").fadeOut(100);
        setPopup(false)
        setIncomeFormValue(incomevalue);
        setIncomeFormError({});
        setIncomeSubmit(false);
        setPushNotice(false);
        myRef.current.scrollIntoView()
    }

    useEffect(() => {

        if (props.incomeTrigger) {
            $('.displayPopup').fadeIn(100);
        }
    }, [props.incomeTrigger])



    return (

        <div className="noticePopUp displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
            <div className={height < 500 ? "noticeInside ip500" : "noticeInside"} ref={myRef} >
                <div className="popUpHeader">
                    <div className='popUpHeaderText '>Create Income/Expense</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>

                <div className="income-outlet">
                    <IncomeForm />

                </div>

            </div>
        </div>
    )

}