import React, { useContext, useState } from "react";
import "../../css/Complain.css";

import SidebarContext from "../sidebarcontext/SidebarContext";

function ToLet() {
  const {
    mode,
    mainBg,
    darkText,
    tableHeadBg,
    tableDataBg,
    setPopup,
    popup,
    mainContent,
  } = useContext(SidebarContext);

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div className="fontHeader" style={darkText}>
              To-let
            </div>
          </div>
          {/* <hr style={darkText} /> */}
        </div>
        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div
              className="row"
              style={{ paddingLeft: "5px", paddingRight: "5px" }}
            >
              <div
                className="col-lg-2  col-md-3 col-sm-3 "
                style={{ padding: "5px" }}
              >
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  className="form-control form-control-sm mb-1 account-bar"
                  //   value={chooseOption}
                  //   onChange={handleChange}
                >
                  <option selected disabled style={{ fontSize: "11px" }}>
                    Select Member
                  </option>
                  <option value="-1">All</option>
                </select>
                <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
              </div>
              <div
                className="col-lg-2  col-md-3 col-sm-3 "
                style={{ padding: "5px" }}
              >
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  className="form-control form-control-sm mb-1 account-bar"
                  //   value={chooseOption}
                  //   onChange={handleChange}
                >
                  <option selected disabled style={{ fontSize: "11px" }}>
                    Select Category
                  </option>
                  <option value="-1">All</option>
                </select>
                <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
              </div>
              <div
                className="col-lg-2  col-md-3 col-sm-3 "
                style={{ padding: "5px" }}
              >
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  className="form-control form-control-sm mb-1 account-bar"
                  //   value={chooseOption}
                  //   onChange={handleChange}
                >
                  <option selected disabled style={{ fontSize: "11px" }}>
                    Select Purpose
                  </option>
                  <option value="-1">All</option>
                </select>
                <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
              </div>
              <div
                className="col-lg-2  col-md-3 col-sm-3 "
                style={{ padding: "5px" }}
              >
                <select
                  style={{ fontSize: "11px" }}
                  name="snotifiaction"
                  className="form-control form-control-sm mb-1 account-bar"
                  //   value={chooseOption}
                  //   onChange={handleChange}
                >
                  <option selected disabled style={{ fontSize: "11px" }}>
                    Select Type
                  </option>
                  <option value="-1">All</option>
                </select>
                <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
              </div>
            </div>

            <div
              className="table-wrapper"
              style={{
                overflowX: "scroll",
                overflowY: "scroll",
                margin: "3px",
                height: "400px",
              }}
            >
              <table className="table">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg} className="text-center">
                      S.N.
                    </td>
                    <td style={tableHeadBg}>Member</td>
                    <td style={tableHeadBg}>Category</td>
                    <td style={tableHeadBg}>Title</td>
                    <td style={tableHeadBg}>Description</td>
                    <td style={tableHeadBg}>Contact</td>
                    <td style={tableHeadBg}>Area</td>
                    <td style={tableHeadBg}>Total Floor</td>
                    <td style={tableHeadBg}>Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ToLet;
