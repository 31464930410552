import React, { useState, useContext, useEffect, useRef } from 'react'
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import NotificationForm from './NotificationForm';
import '../../css/Notification.css';
import NoticeContext from '../contextFolder/NoticeContextFolder/NoticeContext';



export default function NoticePopUp(props) {
  const { height } = UsewindowDimension();

  const context = useContext(NoticeContext)

  const { noticevalue, setNoticeFormValue, noticeFormError, setNoticeFormError, noticeSubmit, setNoticeSubmit, image, setImage, isUploaded, setIsUploaded, typeFile, setTypeFile, setPushNotice, easyList } = context;


  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 

  const myRef = useRef(null)
  // const executeScroll = () => scrollToRef(myRef)

  const closePopup = (e) => {
    e.preventDefault();
    setNoticeFormValue(noticevalue);
    props.setNoticeTrigger(false)
    $('.displayPopup').fadeOut(100);
    setNoticeFormError({});
    setNoticeSubmit(false);
    setIsUploaded(false);
    setImage(null);
    setPushNotice(false)
    easyList();
    myRef.current.scrollIntoView()
  }

  useEffect(() => {

    if (props.noticeTrigger) {
      $('.displayPopup').fadeIn(100);
    }
  }, [props.noticeTrigger])



  return (

    <div className="noticePopUp displayPopup container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
    >
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "noticeInside ip500" : "noticeInside"} ref={myRef} >
        <div className="popUpHeader ">
          <div className='popUpHeaderText '>Create Notification</div>
          <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
        </div>

        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="notice-outlet">
          <NotificationForm />

        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  )

}