import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/Management.css";
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";

import ManagementContext from "../contextFolder/ManagementContextFolder/ManagementContext";
import ManagementTypePop from "./ManagementTypePop";
import "../../css/imagePreview.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

export default function ManagementEdit(props) {
  const { setMsg, urll } = useContext(SidebarContext);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID
  const apisignature = process.env.REACT_APP_SIGNATURE;

  const context = useContext(ManagementContext);

  const {
    popup,
    setPopup,
    image,
    setImage,
    managementFormValue,
    setManagementFormValue,
    managementvalue,
    managementFormError,
    setManagementFormError,
    isSubmit,
    setIsSubmit,
    managementTypePop,
    setManagementTypePop,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    addManagement,
    editIsSubmit,
    setEditIsSubmit,
    mgmtTitle,
    setEditPop,
    reload, setReload,
    titleID, managemenList, setPushNotice, pushNotice, ManagementPush, managementTitleList, fetchdata, mgmtDropDown
  } = context;

  const [closeChecked, setCloseChecked] = useState(false)

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setManagementFormValue({ ...managementFormValue, [name]: value });
  };
  // console.log(managementFormValue);

  const handleOnChange = () => {
    setCloseChecked(!closeChecked)
  };
  const handlePushNotification = () => {
    setPushNotice(!pushNotice)
  }

  const { memberList } = useContext(MemberContext)


  const formSubmit = (e) => {
    // console.log("here")
    e.preventDefault();
    setManagementFormError(validate(managementFormValue));
    setEditIsSubmit(true);

  };

  useEffect(() => {
    if (Object.keys(managementFormError).length === 0 && editIsSubmit) {
      fetchdata(managementFormValue);
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(200);
        setEditPop(false);
      }
      setEditIsSubmit(false);
      if (pushNotice === true) {
        ManagementPush();
      }
      setPushNotice(false);
    }
  }, [managementFormError]);

  const closePopup = (e) => {
    e.preventDefault();

    $(".displayPopups").fadeOut(200);
    setEditPop(false);
    setManagementFormError({});
    setEditIsSubmit(false);
    setImage(null);
    setIsUploaded(false);
    setPushNotice(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.position) {
      errors.position = "Required";
    }
    if (!values.user) {
      errors.user = "Required";
    }


    return errors;
  };





  // for Event Title Popup

  const handleEventTitle = (e) => {
    e.preventDefault();
    setManagementTypePop(true);
  };


  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div
              className="form-group col-md-11"
              style={{ marginBottom: "0px" }}
            >
              <label className="form-label" htmlFor="category">
                Select Position<sup className="sup-col">*</sup>
              </label>
              <select
                id="position"
                name="position"
                value={managementFormValue.position}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option selected>Select Option</option>

                {mgmtDropDown.map((item) => (<option value={item.TypeID}>{item.Type}</option>))}
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{managementFormError.position}</p>
            </div>

            <div className="form-group col-md-1">
              <div className="add-title-button">
                {/* <label className="form-label" htmlFor="button">Add </label> */}
                <button
                  className="btn btn-sm btn-cmpy"
                  style={{
                    background: "0079bf",
                    width: "50px",
                  }}
                  onClick={handleEventTitle}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="form-group  ">
            <label className="form-label" htmlFor="category">
              Select User<sup className="sup-col">*</sup>
            </label>
            <select
              id="user"
              name="user"
              value={managementFormValue.user}
              onChange={handleChange}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
            >
              <option selected>Select Option</option>

              {memberList.map((item) => (<option value={item.MemberID}>{item.MemberName}</option>))}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrops"
            ></i>
            <p className="errormsg ">{managementFormError.user}</p>
          </div>
        </form>
      </div>

      <div className="col-lg-12 managementFormFooter ">
        <div class="form-check notification-form-check" style={{ display: 'flex' }}>
          <div><input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            name="isChecked"
            onChange={handlePushNotification}
            checked={pushNotice}
          />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <ManagementTypePop
        trigger1={managementTypePop}
        setTriggernew1={setManagementTypePop}
      />
    </>
  );
}
