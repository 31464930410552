import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import HelplineContext from "./HelplineContext";
import $ from "jquery";
import DashboardContext from "../DashboardContextFolder/DashboardContext";

function HelplineState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const { setShowHelpline } = useContext(DashboardContext);
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const helplineValue = {
    service: "",
    name: "",
    number: "",
    isChecked: false,
  };
  const [helplineFormValue, setHelplineFormValue] = useState(helplineValue);
  const [helplineFormError, setHelplineFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  //Api to submit profession form value
  const obj = {
    tole_id: ToleID,
    user_id: UserID,
    Services: helplineFormValue.service,
    Name: helplineFormValue.name,
    Contact: helplineFormValue.number,
    status: "A",
  };
  const obj1 = Object.values(obj);
  const obj2 = obj1.join("##");

  // to create
  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "i",
      ForHelp: obj.Services,
      ContactName: obj.Name,
      Contact: obj.Contact,
      IsActive:"A",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/help-line`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          $(".complainTopicPop").fadeOut(100);
          helpList();
          setShowHelpline(true);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

    // This will perform the deletion and hide the Confirmation Box
    const [delPopup, setDelPopup] = useState({
      show: false, // initial values set to false and null
      data: null,
    });
    const [Acdata, setACData] = useState();
    const handleDelete = (data) => {
      setACData(data);
  
      setDelPopup({
        show: true,
        data,
      });
    };
    const handleDeleteTrue = () => {
      const dataForm = {
        ToleID: ToleID,
        UserID: UserID,
        Flag: "R",
        HelplineID: Acdata,
      };
      // console.log(dataForm);
  
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataForm),
      };
      fetch(`${urll}/admin/help-line`, requestOptions).then((result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            helpList();
            setMsg({
              msg: resp.Message,
              type: "alert alert-success",
            });
            setDelPopup({
              show: false,
              data: null,
            });
          } else {
            setMsg({
              msg: resp.Message,
              type: "alert alert-danger",
            });
          }
        });
      });
    };

     // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };
  //To populate
  const [helplineList, setHelplineList] = useState([]);

  useEffect(() => {
    helpList();
  }, []);

  const helpList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: "1",
      Flag: "S",
      IsActive: "-1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/help-line`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {

          const postResult = resp.HelpLineLst ? resp.HelpLineLst : "";
          setLoading(false);
          setHelplineList(postResult);
        } else {
          setHelplineList([]);
        }
      });
    });
  };

  //   to edit
  const [perEditSubmit, setPerEditSubmit] = useState(false);
  const [perID, setPerId] = useState(null);
  const [viewPop, setViewPop] = useState(false);

  const handleEdit = (data) => {
    setPerId(data.helplineid);
    setCloseChecked(false);
    $(".viewHelplinePop").fadeIn(100);
    setViewPop(true);
    setHelplineFormValue({
      name: data.contactname,
      service: data.forhelp,
      number: data.contact,
    });
    // console.log(perID);
  };

  const editobj = {
    Services: helplineFormValue.service,
    Name: helplineFormValue.name,
    Contact: helplineFormValue.number,
    HelplineID: perID,
  };

  const editdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "U",
      UserID: UserID,
      HelplineID: editobj.HelplineID,
      ForHelp: editobj.Services,
      ContactName: editobj.Name,
      Contact: editobj.Contact,
      FetchURL: `${urll}/admin/help-line`,
      Type: "POST",
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        $(".complainTopicPop").fadeOut(100);
        helpList();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  // to deactivate
  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateHelpline = (ID, IsActive) => {
    const editobj = {
      HelplineID: ID,
      Status: IsActive,
    };
    const dataForm = {
      ToleID: ToleID,
      Flag: "AI",
      UserID: UserID,
      HelplineID: editobj.HelplineID,
      isActive: editobj.Status.toString(),
    };
    // console.log(dataForm);
    if (IsActive === "A") {
      dataForm.isActive = "I";
    } else {
      dataForm.isActive = "A";
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/help-line`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          helpList();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.isActive === "A") {
            pitchStatus = "Activated";
          } else if (dataForm.isActive === "I") {
            pitchStatus = "Deactivated";
          }
          // console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  return (
    <HelplineContext.Provider
      value={{
        helplineFormValue,
        setHelplineFormValue,
        helplineFormError,
        setHelplineFormError,
        isSubmit,
        setIsSubmit,
        closeChecked,
        setCloseChecked,
        helplineValue,
        loading,
        setLoading,
        fetchdata,
        helplineList,
        setHelplineList,
        editdata,
        handleEdit,
        perEditSubmit,
        setPerEditSubmit,
        viewPop,
        setViewPop,
        delPopup,
        deactivateHelpline,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
      }}
    >
      {props.children}
    </HelplineContext.Provider>
  );
}

export default HelplineState;
