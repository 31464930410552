import React, { useContext, useEffect, useState, useRef } from "react";
import "../../../css/Complain.css";
import UseHttp from "../../hooks/Use-http";
import CryptoJS from "crypto-js";
import Spinner from "../../Spinner/Spinner";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import AccountContext from "../../contextFolder/AccountContextFolder/AccountContext";
import { Fetchdata } from "../../hooks/getData";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import { AiFillPrinter } from "react-icons/ai";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AccountSummaryTable = ({ searchTerm }) => {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const {
    urll,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const { reload, sumDateFormValue } = useContext(AccountContext);

  const [loading, setLoading] = useState(true);

  const { sendRequest } = UseHttp();
  const [accountSummaryList, setAccountSummaryList] = useState([]);

  // useEffect(() => {
  //     const listForm = {
  //         ChhimekiID: ChhimekiID,
  //         repoOpt: "acsummaryweb",
  //         dtFrom: sumDateFormValue.fromDate,
  //         dtUpto: sumDateFormValue.toDate,
  //         DateTime: "2022/02/03 03:00 AM",
  //         Flag: "Y"

  //     };
  //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //     var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
  //         iv: iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //     });
  //     var encryptedData = encrypted.toString();

  //     const eventData = {
  //         param: encryptedData,
  //     };

  //     sendRequest(
  //         {
  //             url: `${URL}/toleAPI/api/selectReport`,
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 Signature: apisignature,
  //             },
  //             body: eventData,
  //         },

  //         (tasksObj) => {
  //             const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
  //                 iv: iv,
  //                 mode: CryptoJS.mode.CBC,
  //                 padding: CryptoJS.pad.Pkcs7,
  //             });
  //             const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //             console.log(response.dataList)

  //             if (tasksObj.STATUS_CODE === '0') {

  //                 setAccountSummaryList(response.dataList);
  //                 setLoading(false)

  //             }
  //             else {
  //                 setAccountSummaryList([]);
  //                 setLoading(false)
  //             }
  //         }
  //     )
  // }, [reload, sumDateFormValue.fromDate, sumDateFormValue.toDate])

  useEffect(() => {
    const params = {
      ChhimekiID: ChhimekiID,
      UserID: -1,
      Flag: "ss",
      FromDate: sumDateFormValue.fromDate,
      ToDate: sumDateFormValue.toDate,
      AccID: 0,
      Type: "POST",
      FetchURL: `${urll}/admin/account`,
    };

    Fetchdata(params).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        const postResult = result.Acclst ? result.Acclst : "";
        setLoading(false);
        setAccountSummaryList(postResult);
      } else {
        setAccountSummaryList([]);
      }
    });
  }, [sumDateFormValue.fromDate, sumDateFormValue.toDate]);

  const componentRef = useRef();
  // const ref = React.createRef();
  // const options = {
  //   orientation: "landscape",
  //   unit: "in",
  //   format: [12, 12],
  // };

  const columns = [
    { title: "S.N", field: "S.N" },
    { title: "Name", field: "Name" },
    { title: "Debit", field: "Debit" },
    { title: "Credit", field: "Credit" },
    { title: "Balance", field: "Balance" },
  ];

  const toPdf = () => {
    const doc = new jsPDF();
    doc.text("Account Summary Table", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: accountSummaryList,
    });
    doc.save("table.pdf");
  };

  if (accountSummaryList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "360px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="table__button__wrapper">
                  <button uk-tooltip="Excel" className="m-0">
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText={
                        <RiFileExcel2Fill
                          style={{ color: mode === "light" ? "green" : "#fff" }}
                        />
                      }
                    />
                  </button>

                  {/* <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf} uk-tooltip="Pdf">
                        <BsFileEarmarkPdfFill
                          style={{ color: mode === "light" ? "red" : "#fff" }}
                        />
                      </button>
                    )}
                  </Pdf> */}

                  <button onClick={toPdf} uk-tooltip="Pdf">
                    <BsFileEarmarkPdfFill
                      style={{ color: mode === "light" ? "red" : "#fff" }}
                    />
                  </button>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button uk-tooltip="Print">
                          <AiFillPrinter style={darkText} />
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  />
                </div>
                <div>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>SN</td>

                        <td style={tableHeadBg}>Name</td>
                        <td style={tableHeadBg}>Debit</td>
                        <td style={tableHeadBg}>Credit</td>
                        <td style={tableHeadBg}>Balance</td>
                      </tr>
                    </thead>
                    <tbody>
                      {accountSummaryList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.MemberName.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i + 1}>
                            <td style={tableDataBg}>{i + 1}</td>

                            <td style={tableDataBg}>{item.MemberName}</td>
                            <td style={tableDataBg}>
                              {" "}
                              {item.Debit.toFixed(2)}
                            </td>
                            <td style={tableDataBg}>
                              {" "}
                              {item.Credit.toFixed(2)}
                            </td>
                            <td style={tableDataBg}>
                              {" "}
                              {item.Debit.toFixed(2) - item.Credit.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              <button uk-tooltip="Excel" className="m-0">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={
                    <RiFileExcel2Fill
                      style={{ color: mode === "light" ? "green" : "#fff" }}
                    />
                  }
                />
              </button>

              {/* <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf} uk-tooltip="Pdf">
                    <BsFileEarmarkPdfFill
                      style={{ color: mode === "light" ? "red" : "#fff" }}
                    />
                  </button>
                )}
              </Pdf> */}

              <button onClick={toPdf} uk-tooltip="Pdf">
                <BsFileEarmarkPdfFill
                  style={{ color: mode === "light" ? "red" : "#fff" }}
                />
              </button>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button uk-tooltip="Print">
                      <AiFillPrinter style={darkText} />
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              />
            </div>
            <div>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>SN</td>
                    {/* <td style={tableHeadBg}>Date</td> */}
                    <td style={tableHeadBg}>Name</td>
                    <td style={tableHeadBg}> Debit</td>
                    <td style={tableHeadBg}> credit</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default AccountSummaryTable;
