import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from '../hooks/UsewindowDimension'
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import SliderContext from '../contextFolder/SliderContextFolder/SliderContext';
import GovernmentForm from './GovernmentForm';




export default function SliderPopup(props) {
    const context = useContext(SliderContext)

    const { slidervalue, setSliderFormValue, setSliderFormError, setIsSubmit, setImage, setIsUploaded, setCloseChecked } = context;
    const { height } = UsewindowDimension();


    const closePopup = (e) => {
        e.preventDefault();
        props.setSliderTrigger(false)
        $('.complainTopicPop').fadeOut(100);
        setSliderFormValue(slidervalue)
        setSliderFormError({})
        setImage("")
        setIsUploaded(false);
        setIsSubmit(false);
        setCloseChecked(false)
    }

    useEffect(() => {

        if (props.sliderTrigger) {
            $('.complainTopicPop').fadeIn(100);
        }
    }, [props.sliderTrigger])



    return (

        <div className="complainPopUp complainTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
            {/* <OutsideAlerter> */}
            <div className={height < 500 ? "inside--fam ip500" : "inside--fam"}>
                <div className="popUpHeader ">
                    <div className='popUpHeaderText '>Add Government Identity</div>
                    <div style={{ cursor: "pointer" }}><i className="bi bi-x " onClick={closePopup} style={{ fontSize: "25px" }}></i></div>
                </div>



                <div className="notice-outlet">
                    <GovernmentForm />

                </div>
            </div>
        </div>
    )

}