import React, { useContext, useState } from "react";
import "../../css/Complain.css";
import ComplainContext from "../contextFolder/ComplainContextFolder/ComplainContext";
import ComplainTable from "./ComplainTable";
import ComplainTopicPop from "./ComplainTopicPop";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import SidebarContext from "../sidebarcontext/SidebarContext";

function Complain() {
  const { mode, mainBg, mainContent } = useContext(SidebarContext);
  const [searchTerm, setSearchTerm] = useState("");

  const context = useContext(ComplainContext);
  const {
    pop,
    setPop,
    dateFormValue,
    setDateFormValue,
    chooseOption,
    setChooseOption,
    chooseMember,
    setChooseMember,
    complainList,
    chooseComplainStatus,
    setChooseComplainStatus,
  } = context;

  const { memberList } = useContext(MemberContext);

  const handleAddComplain = (e) => {
    e.preventDefault();
    setPop(true);
  };

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseOption(value);
  };

  const handleMember = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseMember(value);
  };
  const handleStatus = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseComplainStatus(value);
  };

  // console.log(chooseOption, chooseMember, dateFormValue, chooseComplainStatus);

  const handleDate = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, fromDate: bsDate });
  };
  const handleDate1 = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, toDate: bsDate });
  };

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Complain
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>

        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-cmpy btn-sm"
                  style={{ marginRight: "-11px", marginTop: "4px" }}
                  onClick={handleAddComplain}
                >
                  {" "}
                  Add Complain Topic +
                </button>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div
                  className="col-lg-2  col-md-2 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                   Select Topic
                  </label>
                 
                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1"
                    value={chooseOption}
                    onChange={handleChange}
                  >
                    <option selected disabled style={{ fontSize: "11px" }}>
                      Select Complain Topic
                    </option>
                    <option value="-1">All</option>
                    {complainList.map((item) => (
                      <option key={item.Topic} value={item.Topic}>
                        {item.Topic}
                      </option>
                    ))}
                  </select>
                  <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
                </div>

                {/* <div
                  className="col-lg-2 col-md-2 col-sm-2 "
                  style={{ padding: "5px" }}
                > */}
                  {/* <div className="col-lg-2  col-md-3 col-sm-2 " style={{ padding: "5px" }}> */}

                  {/* <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1 account-bar"
                    value={chooseMember}
                    onChange={handleMember}
                  >
                    <option selected disabled style={{ fontSize: "11px" }}>
                      Select Member
                    </option>
                    <option value="-1">All</option>
                    {memberList.map((item) => (
                      <option key={item.MemberID} value={item.MemberID}>
                        {item.MemberName}
                      </option>
                    ))}
                  </select> */}
                  {/* <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
                </div> */}

                {/* <div
                  className="col-lg-2 col-md-2 col-sm-2 "
                  style={{ padding: "5px" }}
                > */}
                  {/* <div className="col-lg-2  col-md-3 col-sm-2 " style={{ padding: "5px" }}> */}

                  {/* <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1 account-bar"
                    value={chooseComplainStatus}
                    onChange={handleStatus}
                  >
                    <option selected disabled style={{ fontSize: "11px" }}>
                      Select Status
                    </option>
                    <option value="-1">All</option>
                    <option value="0">Pending</option>
                    <option value="1">Reviewed</option>
                    <option value="2">Approved</option>
                    <option value="3">Rejected</option>
                  </select>
                  <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
                </div> */}

                {/* <div
                  className="col-lg-2 col-md-2 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    From Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    defaultDate="2079/01/01"
                    theme="default"
                    language="en"
                    values={dateFormValue.fromDate}
                    onChange={handleDate}
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div> */}

                {/* <div
                  className="col-lg-2 col-md-2 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    To Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    defaultDate="2079/01/01"
                    theme="default"
                    language="en"
                    values={dateFormValue.toDate}
                    onChange={handleDate1}
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div> */}

                {/* </div> */}

                <div
                  className="col-lg-2 offset-md-8 col-md-2 col-sm-2  align-self-end Search"
                  style={{ padding: "8px" }}
                >
                  <div className="dashboard-search-field justify-content-end">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="">
                <div className="col-lg-12 p-1">
                  <ComplainTable searchTerm={searchTerm} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <ComplainTopicPop triggerPop={pop} setTriggerPop={setPop} />
      </div>
    </>
  );
}

export default Complain;
