import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import UsewindowDimension from "../hooks/UsewindowDimension";
// import Spinner from '../component/Spinner/Spinner';
// import OutsideAlerter from '../component/hooks/OutsideAlerter';
import $ from "jquery";
import ProfessionForm from "./ProfessionForm";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import "../../css/imagePreview.css";
import AcademicPopupForm from "./AcademicPopupForm";


export default function AcademicPopup(props) {
  const [last, setLast] = useState(false);
  const context = useContext(ProfessionContext);

  const {
    setAcademicName,
    setAddAcademicSubmit,
    setAddAcademicFormError,
    setUpdate3,
  } = context;
  const { height } = UsewindowDimension();


  const closePopup = (e) => {
    e.preventDefault();
    // setProfessionFormValue(professionvalue);
    props.setAcademicPopup(false);
    $(".academicTopicPop").fadeOut(100);
    setAddAcademicSubmit(false);
    setAcademicName({ name: "" });
    setAddAcademicFormError({});
    setUpdate3(false);
    // setProfessionFormError({});
    // setProfessionSubmit(false);
  };

  useEffect(() => {
    if (props.academicPopup) {
      $(".academicTopicPop").fadeIn(100);
    }
  }, [props.academicPopup]);


  return (
    <div className="complainPopUp academicTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "inside--prof ip500" : "inside--prof"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Add Academic</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>
        {/* <nav >
                <ul>    
                    <li className={activeTab.tab1 === true ? "active" : ""}  onClick={handleTab1}><i class="fas fa-home icon"></i>Basic </li>
                </ul>
            </nav> */}
        {/* {loading ? <Spinner/> :(        */}

        <div className="notice-outlet">
         <AcademicPopupForm/>
        </div>

        {/* )}     */}
      </div>

      {/* </OutsideAlerter> */}
    </div>
  );
}
