import React, { useContext, useState, useEffect, useRef } from "react";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/imagePreview.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
// import StreetPopup from "../ProfessionPage/StreetPopup";

export default function Permanent(props) {
  const { setPopup } = useContext(SidebarContext);
  // const { streetDropdown } = useContext(ProfessionContext);
  const context = useContext(MemberContext);
  const {
    provinceData,
    pdistrictData,
    getPProvince,
    getPDistrict,
    pmunicipalityData,

    reload,
    setReload,
    memberFormValue,
    setMemberFormValue,
    setStep,
    permanentIsSubmit,
    setPermanentIsSubmit,
    permanentFormError,
    setPermanentFormError,
    setMemberFormErrors,
    setIsSubmit,
    setTemporaryFormError,
    setTemporaryIsSubmit,
    setFinalSubmit,
    membervalue,
    fetchdata,
    closeChecked,
    setCloseChecked,
  } = context;

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const closePopup = (e) => {
    e.preventDefault();

    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setMemberFormValue(membervalue);
    setMemberFormErrors({});
    setIsSubmit(false);
    setPermanentFormError({});
    setPermanentIsSubmit(false);
    setTemporaryFormError({});
    setTemporaryIsSubmit(false);
    setFinalSubmit(false);
    setStep(1);
    setMemberFormValue(membervalue);
  };
  const formNext = (e) => {
    e.preventDefault();
    setPermanentFormError(validate(memberFormValue));
    setPermanentIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(permanentFormError).length === 0 && permanentIsSubmit) {
      // fetchdata(memberFormValue)
      setStep(3);
    }
    setPermanentIsSubmit(false);
  }, [permanentFormError]);
  const PreviousPage = () => {
    setStep(1);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setMemberFormValue({ ...memberFormValue, [name]: value });
  };
  // console.log("member form value", memberFormValue);

  // const selectValue = (value) => {
  //     setMemberFormValue({ ...memberFormValue, provience: value })
  // }

  // const handleStreetPopup = (e) => {
  //   e.preventDefault();
  //   setStreetPopup(true);
  //   $(".streetTopicPop").fadeIn(100);
  //   // setProfessionFormValue(professionvalue);
  // };

  const validate = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = "Required";
      inputRef5.current.focus();
    }
    if (!values.ward) {
      errors.ward = "Required";
      inputRef4.current.focus();
    }
    if (!values.municipality) {
      errors.municipality = "Required";
      inputRef3.current.focus();
    }
    if (!values.district) {
      errors.district = "Required";
      inputRef2.current.focus();
    }

    if (!values.provience) {
      errors.provience = "Required";
      inputRef1.current.focus();
    }
    return errors;
  };

  // const value = document.querySelector('.css-qc6sy-singleValue');
  // console.log(value)
  // if (value != null) {
  //     value.innerHTML = memberFormValue.provience;

  // }

  useEffect(() => {
    if (memberFormValue.provience != "") {
      getPProvince();
    }

    if (memberFormValue.district != "") {
      getPDistrict();
    }
  }, [memberFormValue]);

  return (
    <>
      <div className="container-fluid basicform">
        <div className="form-row">
          <div className="form-group col-md-6 pos">
            <label className="form-label" htmlFor="provience">
              Province<sup className="sup-col">*</sup>
            </label>

            {/* <Select options={provience} onChange={selectValue} name="provience" /> */}
            <select
              id="provience"
              name="provience"
              onChange={handleChange}
              value={memberFormValue.provience}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef1}
              required
            >
              <option value="" selected disabled>
                Select Province
              </option>
              {provinceData.map((item) => {
                return (
                  <option key={item.ProvinceID} value={item.ProvinceID}>
                    {item.Province}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>

            <p className="errormsg ">{permanentFormError.provience}</p>
          </div>

          {/* <div className=" form-group col-md-4 pos">
            <label className="form-label" htmlFor="zone">
              Zone<sup className="sup-col">*</sup>
            </label>
            <select
              id="zone"
              name="zone"
              onChange={handleChange}
              value={memberFormValue.zone}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef2}
              required
            >
              <option value="none" selected>
                Select Zone
              </option>
              <option value="1">Bagmati</option>
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{permanentFormError.zone}</p>
          </div> */}

          <div className=" form-group col-md-6 pos ">
            <label className="form-label" htmlFor="district">
              District<sup className="sup-col">*</sup>
            </label>
            <select
              id="district"
              name="district"
              onChange={handleChange}
              value={memberFormValue.district}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef2}
              required
            >
              <option value="" selected disabled>
                Select District
              </option>
              {pdistrictData.map((item) => {
                return (
                  <option key={item.DistrictID} value={item.DistrictID}>
                    {item.District}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{permanentFormError.district}</p>
          </div>
        </div>

        <div className="form-row">
          <div className=" form-group col-md-6 pos">
            <label className="form-label" htmlFor="municipality">
              Municipality/VDC<sup className="sup-col">*</sup>
            </label>

            <select
              id="municipality"
              name="municipality"
              onChange={handleChange}
              value={memberFormValue.municipality}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef3}
              required
            >
              <option value="" selected disabled>
                Select Municipality
              </option>
              {pmunicipalityData.map((item) => {
                return (
                  <option key={item.MunicipalityID} value={item.MunicipalityID}>
                    {item.Municipality}
                  </option>
                );
              })}
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{permanentFormError.municipality}</p>
          </div>

          <div className="form-group col-md-6 pos ">
            <label className="form-label" htmlFor="ward">
              Ward No.<sup className="sup-col">*</sup>
            </label>
            <select
              id="ward"
              name="ward"
              onChange={handleChange}
              value={memberFormValue.ward}
              className="form-control form-control-sm mb-1"
              style={{ fontSize: "12px" }}
              ref={inputRef4}
              required
            >
              <option value="" selected disabled>
                Select Ward
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
            </select>
            <i
              style={{ paddingTop: "4px", color: "#999" }}
              className="fas fa-angle-down notificationpositondrop"
            ></i>
            <p className="errormsg ">{permanentFormError.ward}</p>
          </div>

          {/* <div className=" form-group col-md-4 pos">
            <label className="form-label" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              name="address"
              onChange={handleChange}
              value={memberFormValue.address}
              className="form-control form-control-sm mb-1"
              id="address"
            />
            <p className="errormsg ">{permanentFormError.address}</p>
          </div> */}
        </div>

        <div className="form-row">
           {/*
          <div className=" form-group col-md-6 pos ">
            <label className="form-label" htmlFor="address">
              Street<sup className="sup-col">*</sup>
            </label>
            <div className="uk-flex">
              <select
                style={{ fontSize: "12px" }}
                name="address"
                onChange={handleChange}
                value={memberFormValue.address}
                className="form-control form-control-sm mb-1"
                id="address"
                ref={inputRef5}
                required
              >
                <option value="" selected disabled>
                  Select Street
                </option>
                {streetDropdown.map((item, i) => (
                  <option value={item.ID}>{item.Name}</option>
                ))}
              </select>
              <span>
                <button
                  onClick={handleStreetPopup}
                  type="button"
                  className="btn btn-primary"
                >
                  +
                </button>
              </span>
            </div>
            <p className="errormsg ">{permanentFormError.address}</p>
          </div> */}

          <div className=" form-group col-md-6 pos">
            <label className="form-label" htmlFor="house">
              House/Block No.
            </label>
            <input
              type="text"
              name="house"
              onChange={handleChange}
              value={memberFormValue.house}
              className="form-control form-control-sm mb-1"
              id="house"
            />
          </div>

          <div className=" form-group col-md-6 pos">
            <label className="form-label" htmlFor="address">
             Address<sup className="sup-col">*</sup>
            </label>
            <input
              type="text"
              name="address"
              onChange={handleChange}
              value={memberFormValue.address}
              className="form-control form-control-sm mb-1"
              id="adress"
            />
          </div>
        </div>

        {/* <div className="form-row">
          <div className=" col-md-6  ">
            <label className="form-label" htmlFor="text">
              Latitude
            </label>
            <input
              type="text"
              name="latitude"
              className="form-control form-control-sm mb-1"
              id="text"
              onChange={handleChange}
              value={memberFormValue.latitude}
            />
          </div>
          <div className=" col-md-6  ">
            <label className="form-label" htmlFor="text">
              Longitude
            </label>
            <input
              type="text"
              name="longitude"
              onChange={handleChange}
              value={memberFormValue.longtitude}
              className="form-control form-control-sm mb-1"
              id="text"
            />
          </div>
        </div> */}
        {/* <div className=" col-md-1">

                            <div className="add-title-button">
                                <label className="form-label" htmlFor="button">Add </label>
                                <button className='btn btn-sm btn-cmpy' style={{
                                    background: "0079bf", width: "50px"
                                }} ><i class="fa fa-map-marker" aria-hidden="true"></i></button>
                            </div>
                        </div> */}
      </div>

      {/* <div className="col-lg-12 notificationFormFooterpermanent " >

                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "red" }} onClick={PreviousPage} >Back</button>
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formNext} >Next</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div> */}

      <div className="col-lg-12 Member-Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "red" }}
            onClick={PreviousPage}
          >
            Back
          </button>
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formNext}
          >
            Next
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
      {/* <StreetPopup streetPopup={streetPopup} setStreetPopup={setStreetPopup} /> */}
    </>
  );
}
