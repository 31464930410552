import classes from "../css/abs.css";
import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import UsewindowDimension from "./hooks/UsewindowDimension.js";
import "../css/Sidebarone.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import AuthContext from "./auth-context";
import MemberContext from "./contextFolder/MemberContextFolder/MemberContext";
import { pad } from "crypto-js";
import $ from "jquery";
import ComplainContext from "./contextFolder/ComplainContextFolder/ComplainContext";

export default function Sidebarone(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const UserName = userinfo.UserName;
  const CustomerImage = userinfo.CustomerImage;
  const TempHouseNo = userinfo.TempHouseNo;

  const { setViewPop } = useContext(MemberContext);
  // console.log(UserName)
  const { setPop } = useContext(ComplainContext);
  const context = useContext(SidebarContext);
  const {
    setPopup,
    padding,
    setPadding,
    sideMenu,
    setSideMenu,
    menutoggle,
    setMenutoggle,
    setAlert,
    logoutdata,
    mode,
    setReload,
    reload,
    sidePanelBg,
  } = context;

  const [active, setActive] = useState(false);
  const [hide, setHide] = useState(false);

  const [dropToggle, setDropToggle] = useState(false);

  const handleLogoClick = (e) => {
    e.preventDefault();
    setActive(!active);
  };

  const { width } = UsewindowDimension();
  const handleMenuChange = () => {
    setPopup(false);
    setPop(false);
    setViewPop(false);
    setReload(Math.random());
    // setHide(true);
    // setPadding(false);
    if (width < 960) {
      setSideMenu(!sideMenu);
    } else {
      setSideMenu(sideMenu);
    }
    console.log("clicked");
    // document.querySelectorAll(".side__content").forEach((el) => {
    //   el.classList.add("padding");
    // });
  };

  // const handleMenuChange = () => {
  //   setMenutoggle(!menutoggle);
  //   console.log("Clicked");
  // };

  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const auth = localStorage.getItem("userInfo");

  function logOut(e) {
    e.preventDefault();
    setAlert({
      fade: "fade-in",
      msg: "Do you want to logout ?",
      type: "logout",
    });
  }
  function logoutFunction() {
    authCtx.logout();
    localStorage.clear();
    setAlert({
      fade: "fade-default",
      msg: "",
      type: "",
    });
    navigate("/login");
  }

  useEffect(() => {
    if (logoutdata) {
      logoutFunction();
    }
  }, [logoutdata]);

  function ChangePW(e) {
    e.preventDefault();
    // setSideMenu(!sideMenu);
    if (width < 960) {
      setSideMenu(!sideMenu);
    } else {
      setSideMenu(sideMenu);
    }
    setAlert({
      fade: "fade-in",
      msg: "",
      type: "change",
    });
  }

  // const { width } = UsewindowDimension();
  // const handleDrawer = () => {
  //   if (width < 1100) {
  //     setMenutoggle(!menutoggle);
  //   } else {
  //     setMenutoggle(menutoggle);
  //   }
  //   console.log("clicked");
  // };

  const handlePadding = () => {
    document.querySelectorAll(".side__content").forEach((el) => {
      el.classList.add("padding");
    });
  };

  useEffect(() => {
    if (hide) {
      document.querySelectorAll(".uk-accordion-content").forEach((el) => {
        el.classList.add("none");
      });
    } else {
      document.querySelectorAll(".uk-accordion-content").forEach((el) => {
        el.classList.remove("none");
      });
    }
  }, [hide]);

  useEffect(() => {
    if (padding) {
      document.querySelectorAll(".side__content").forEach((el) => {
        el.classList.remove("padding");
      });
    } else {
      document.querySelectorAll(".side__content").forEach((el) => {
        el.classList.add("padding");
      });
    }
  }, [padding]);

  const handleMouseOver = () => {
    setHide(false);
    setPadding(false);
  };
  const handleMouseLeave = () => {
    setHide(!hide);
    setPadding(!padding);
  };

  $(".dropdown-list").click(function () {
    $("#active").addClass("active");
  });
  $(".non-active").click(function () {
    $("#active").removeClass("active");
  });

  return (
    <>
      {auth ? (
        <>
          {/* <div className="menu">
            <div className="img ">
              <img src={logo} alt="" className="imgMain" />
              <span className="logoHeading">Hami Chhimeki</span>
              <span className="close-button" onClick={handleMenuChange}>
                <i className="fas fa-times"></i>
              </span>
            </div>
            <nav className="MainNavbar">
              <div className="mainNavUserInfo">
                <div>
                  <div className="contentsLogo tc">
                    <div className="mgmtImgs tc">
                      {" "}
                      <img src={CustomerImage} alt="" />{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                <div className="userInfotext">
                  {" "}
                  <p className="mb-0">{UserName}</p>
                  <p className="mb-0" style={{ textAlign: "left" }}>
                    {TempHouseNo}
                  </p>
                </div>
              </div>
              <ul>
                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    to="/dashboard"
                    onClick={handleDrawer}
                  >
                    <i className="fa fa-tachometer mx-3 icon"></i>Dashboard
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/member"
                  >
                    <i className="bi bi-people mx-3 icon"></i>Member
                  </NavLink>{" "}
                </li>

                <li
                  className="list-name"
                  data-bs-toggle="collapse"
                  href="#account"
                >
                  <Link to="#account">
                    <i className="fa fa-table mx-3 icon"></i>Account{" "}
                    <i
                      class="fas fa-angle-down "
                      style={{ float: "right", marginTop: "3px" }}
                    ></i>
                    <div class="collapse submenu-dropdown " id="account">
                      <Link
                        to="/account"
                        onClick={handleDrawer}
                        className="link"
                      >
                        <div className="sublist-name">New</div>
                      </Link>
                      <Link
                        to="/summary"
                        onClick={handleDrawer}
                        className="link"
                      >
                        <div className="sublist-names">Summary</div>
                      </Link>
                    </div>{" "}
                  </Link>
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/places"
                  >
                    <i className="fa fa-globe mx-3 icon"></i>Places
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/complain"
                  >
                    <i className="fa fa-bug mx-3 icon"></i>Complain
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/event"
                  >
                    <i className="far fa-calendar mx-3 icon"></i>Event & Meeting
                  </NavLink>{" "}
                </li>

                <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/chhimeki-list" ><i className="far fa-calendar mx-3 icon"></i>Chhimeki List</NavLink> </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/notice"
                  >
                    <i className="fa fa-bell-o mx-3 icon"></i>Notice
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/management"
                  >
                    <i className="fa fa-users mx-3 icon"></i>Management
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow "
                    onClick={handleDrawer}
                    to="/slider"
                  >
                    <i className="fa fa-users mx-3 icon"></i>Slider
                  </NavLink>{" "}
                </li>

                <li>
                  <a
                    activeclassname={classes.active}
                    className="arrow"
                    href="/"
                    onClick={ChangePW}
                  >
                    <i className="fa fa-unlock-alt mx-3 icon"></i>Change
                    Password
                  </a>{" "}
                </li>

                <li><NavLink activeclassname={classes.active} className="arrow " onClick={handleDrawer} to="/password" ><i class="fa fa-cog mx-3 icon"></i>Change Password</NavLink> </li>
              </ul>
            </nav>
          </div> */}

          <div
            className={`side__panel ${sideMenu ? "side-menu" : ""}`}
            style={sidePanelBg}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            {/* <div className="img ">
                        <img src={logo} alt="" className='imgMain' />
                        <span className='logoHeading'>Hami Chhimeki</span>
                        <span className="close-button" onClick={handleMenuChange}><i className="fas fa-times"></i></span>
                    </div> */}
            <div className="side-panel-list">
              {/* <div className="mainNavUserInfo">
                        
                            <div><div className="contentsLogo tc" ><div className="mgmtImgs tc"> <img src={CustomerImage} alt="" />  </div> </div> </div>
                            <div className="userInfotext"> <p className='mb-0'>{UserName}</p>
                                <p className='mb-0' style={{ textAlign: "left" }}>{TempHouseNo}</p>
                            </div>
                        </div> */}
              <ul>
                {/* <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active"
                    to="/dashboard"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-tachometer mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Dashboard
                      </span>
                    </div>
                  </NavLink>{" "}
                </li> */}

                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active"
                    to="/profile"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-user mx-2 icon"></i>
                    </div>
                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Profile
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>

                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active"
                    to="/member"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="bi bi-people mx-2 icon"></i>
                    </div>
                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Member
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>

                <li
                  
                  className="uk-margin-remove"
                  href="#account"
                >
                  <Link
                    to="#account"
                    className="list-title"
                    onMouseOverCapture={handlePadding}
                    onClick={() => setDropToggle(!dropToggle)}
                    activeclassname={classes.active}
                    id="active"
                  >
                    <div>
                      <i className="fa fa-table mx-2 icon"></i>
                    </div>
                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Account
                        {dropToggle ? (
                          <span
                            uk-icon="icon: chevron-down"
                            className="dropdown-icon"
                          ></span>
                        ) : (
                          <span
                            uk-icon="icon: chevron-right"
                            className="dropdown-icon"
                          ></span>
                        )}
                      </span>
                    </div>
                  </Link>
                  <ul
                    class={`uk-accordion-content ${
                      dropToggle ? "show" : "hide"
                    }`}
                    style={{
                      background: mode === "light" ? "#e6edf7" : "#fff",
                    }}
                    id="uk-accordion-content"
                    key={reload}
                  >
                    <li>
                      <Link
                        to="/account"
                        className="dropdown-list"
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                        style={{ color: mode === "light" ? "#0049ae" : "#000" }}
                      >
                        Personal Account
                      </Link>
                      {/* <Link
                        to="/summary"
                        className="dropdown-list"
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                        style={{ color: mode === "light" ? "#0049ae" : "#000" }}
                      >
                        Summary
                      </Link> */}
                      <Link
                        to="/ledger"
                        className="dropdown-list"
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                        style={{ color: mode === "light" ? "#0049ae" : "#000" }}
                      >
                        Ledger
                      </Link>
                      <Link
                        to="/incomeexpense"
                        className="dropdown-list"
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                        style={{ color: mode === "light" ? "#0049ae" : "#000" }}
                      >
                        Income/Expense
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active"
                    to="/places"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-globe mx-2 icon"></i>
                    </div>
                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Places
                      </span>
                    </div>
                  </NavLink>{" "}
                </li> */}

                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/complain"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-bug mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Complain
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>


                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/gov-identity"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-bug mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Government Identity
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>






                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/event"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="far fa-calendar mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Event & Meeting
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>

                {/* <li><NavLink activeclassname={classes.active} className="arrow uk-accordi to="/chhimeki-list" ><i className="far fa-calendar mx-2 icon"></i>Chhimeki List</NavLink> </li> */}

                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/notice"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-bell-o mx-2 icon"></i>
                    </div>
                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Notice
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>

                {/* <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/management"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-users mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Management
                      </span>
                    </div>
                  </NavLink>{" "}
                </li> */}

                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/slider"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fa fa-users mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Slider
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>
                <li
                  
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/helpline"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fas fa-hands-helping mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Helpline
                      </span>
                    </div>
                  </NavLink>{" "}
                </li>


{/* 
                <li
                  className="uk-margin-remove"
                >
                  <NavLink
                    activeclassname={classes.active}
                    className="arrow list-title non-active "
                    to="/to-let"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    key={reload}
                  >
                    <div>
                      <i className="fas fa-house-user mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        To-let
                      </span>
                    </div>
                  </NavLink>{" "}
                </li> */}

                <li
                  
                  className="uk-margin-remove"
                >
                  <a
                    activeclassname={classes.active}
                    className="arrow list-title"
                    href="/"
                    onClick={ChangePW}
                  >
                    <div>
                      <i className="fa fa-unlock-alt mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        Change Password
                      </span>
                    </div>
                  </a>{" "}
                </li>

                <li
                  
                  className="uk-margin-remove"
                >
                  <a
                    activeclassname={classes.active}
                    className="arrow list-title"
                    href="https://docs.google.com/document/d/1BMBn32a5eylSgUOJdtiZ4Ydm-i1y041QTcsbGTaGd1I/edit"
                    target="_blank"
                  >
                    <div>
                      <i className="fa fa-user mx-2 icon"></i>
                    </div>

                    <div>
                      <span
                        className="uk-margin-remove list"
                        style={{ color: "#fff" }}
                      >
                        User Manual
                      </span>
                    </div>
                  </a>{" "}
                </li>

                {/* <li><NavLink activeclassname={classes.active} className="arrow uk-accordion-title " onClick={handleDrawer} to="/password" ><i class="fa fa-cog mx-3 icon"></i>Change Password</NavLink> </li> */}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <li className="nav-item">
            <NavLink
              activeClassName={classes.active}
              className="nav-link"
              to="/login"
            >
              Login
            </NavLink>
          </li>
        </>
      )}
    </>
  );
}
