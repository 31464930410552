import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import Spinner from "../Spinner/Spinner";
import IncomeContext from "../contextFolder/IncomeContextFolder/IncomeContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import IncomeDelete from "./IncomeDelete";

const IncomeTable = ({ searchTerm }) => {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const {
    URL,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const {
    handleDelete,
    loading,
    incomeList,
    handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
  } = useContext(IncomeContext);

  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (incomeList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "301px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="table__button__wrapper">
                  {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                  />

                  <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  /> */}
                </div>
                <div ref={ref}>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>SN</td>
                        {/* <td style={tableHeadBg}>Date</td> */}
                        <td style={tableHeadBg}>Account No.</td>
                        <td style={tableHeadBg}>Nepali Date</td>
                        <td style={tableHeadBg}> Account Type</td>
                      
                        <td style={tableHeadWidthBg}> Amount</td>
                        <td style={tableHeadWidthBg}> Action</td>
                      </tr>
                    </thead>
                    <tbody>
  {incomeList
    .filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (
        val.NepDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.Particulars.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      }
    })
    .map((item, i) => {
      // Calculate the total for each row
      const totalAmount = parseFloat(item.Debit || 0) + parseFloat(item.Credit || 0);
      
      return (
        <tr key={i}>
          <td style={tableDataBg}>{i + 1}</td>
          <td style={tableDataBg}>{item.AccNo}</td>
          <td style={tableDataBg}>{item.NepDate}</td>
          <td style={tableDataBg}>{item.AccType}</td>
          
          <td style={tableDataBg}>{totalAmount.toFixed(2)}</td> {/* Display total amount here */}
          <td>
            <span
              className="rejectspan badge"
              onClick={() => {
                handleDelete(item.AccID);
              }}
              style={darkText}
            >
              Delete
            </span>
          </td>
        </tr>
      );
    })}
</tbody>

                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        {delPopup.show && (
          <IncomeDelete
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
              />

              <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf}>
                    <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                  </button>
                )}
              </Pdf>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button>
                      <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              /> */}
            </div>
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>SN</td>
                    <td style={tableHeadBg}>Date</td>
                    <td style={tableHeadBg}>Receipt No.</td>
                    <td style={tableHeadBg}>Particular</td>
                    <td style={tableHeadBg}> Debit</td>
                    <td style={tableHeadBg}> credit</td>
                    <td style={tableHeadBg}> Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default IncomeTable;
