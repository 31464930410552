import React from "react";
import { useContext, useEffect, useState } from "react";
import SidebarContext from "../sidebarcontext/SidebarContext";

const ProfileProfession = () => {
  const { darkText, urll } = useContext(SidebarContext);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [professionlist, setProfessionlist] = useState([]);

  useEffect(() => {
    const dataForm = {
      ChhimekiID: userinfo.ChhimekiID,
      MemberID: userinfo.UserID,
      Flag: "S",
    };
    console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/u-prof`, requestOptions).then((result) => {
      result.json().then((resp) => {
        console.log(resp, "ress");
        if (resp.StatusCode === 200) {
          const postResult = resp.ProfLst ? resp.ProfLst : "";
          setProfessionlist(postResult);
        } else {
          setProfessionlist([]);
        }
      });
    });
  }, []);

  console.log("dds", professionlist);

  return (
    <div className="mt-3">
      {professionlist.length > 0 ? (
        <>
          {professionlist.map((props) => {
            const {
              ProfessionID,
              Profession,
              OrgName,
              OrgAddress,
              OrgPosition,
            } = props;
            return (
              <div key={ProfessionID} className="profile-wrap">
                <h5 style={darkText}>{Profession}</h5>
                <p style={darkText}>{OrgName}</p>
                <p style={darkText}>{OrgPosition}</p>
                <p style={darkText}>{OrgAddress}</p>
              </div>
            );
          })}
        </>
      ) : (
        <span style={darkText}>No Data</span>
      )}
    </div>
  );
};

export default ProfileProfession;
