import React, { useContext, useEffect, useState } from "react";
import "../../css/Complain.css";
import "../../css/Account.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import AccountTable from "./AccountTable";
import AccountPopUp from "./AccountPopUp";
import AccountContext from "../contextFolder/AccountContextFolder/AccountContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import LedgerContext from "../contextFolder/LedgerContextFolder/LedgerContext";

function Account() {
  const memcontext = useContext(MemberContext);
  const { memberDrop } = memcontext;
  const { mode, mainBg, mainContent } = useContext(SidebarContext);
  const LedContext = useContext(LedgerContext);
  const { ledgerList } = LedContext;

  const context = useContext(AccountContext);
  const {
    chooseOptionTable,
    chooseOptionLedgerTable,
    setChooseOptionLedgerTable,
    setChooseOptionTable,
    dateFormValue,
    setDateFormValue,
  } = context;

  const { popup, setPopup } = useContext(SidebarContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setChooseOptionTable("");
    setChooseOptionLedgerTable(""); // Reset ledger dropdown when member changes
  };

  const handleLedger = (e) => {
    const value = e.target.value;
    setChooseOptionLedgerTable(value);
  };

  const handleDate = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, fromDate: bsDate });
  };
  
  const handleDate1 = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, toDate: bsDate });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleAddAccount = (e) => {
    e.preventDefault();
    setPopup(true);
  };

  // Filter ledgers based on selected member
  const filteredLedgerListTable = ledgerList.filter(
    (ledger) => ledger.MemID === parseInt(chooseOptionTable)
  );

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Account
            </div>
          </div>
        </div>
        <section className="content-section main-content mt-3" style={mainContent}>
          <div className="content">
            <div className="col-lg-12 col-sm-12">
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-cmpy btn-sm"
                  style={{ marginRight: "-11px", marginTop: "4px" }}
                  onClick={handleAddAccount}
                >
                  Add Account +
                </button>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                {/* Member Dropdown */}
                <div
                  className="col-lg-2 col-md-3 col-sm-2"
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    Select Member
                  </label>
                  <select
  style={{ fontSize: "11px" }}
  name="snotifiaction"
  className="form-control form-control-sm mb-1"
  value={chooseOptionTable} // ChooseOption controls the selected value
  onChange={(e) => {
    setChooseOptionTable(e.target.value); // Set the selected member
    setChooseOptionLedgerTable(""); // Reset ledger when member changes
  }}
>
  {/* Default placeholder option */}
  <option value="" disabled style={{ fontSize: "11px" }}>
    Select Member
  </option>
  {memberDrop.map((item) => (
    <option key={item.UserID} value={item.UserID}>
      {item.FullName}
    </option>
  ))}
</select>

                </div>

                {/* Ledger Dropdown */}
                <div
                  className="col-lg-2 col-md-3 col-sm-2"
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    Select Ledger
                  </label>
                  <select
  style={{ fontSize: "11px" }}
  name="snotifiaction"
  className="form-control form-control-sm mb-1"
  value={chooseOptionLedgerTable} // Control selected ledger with state
  onChange={(e) => setChooseOptionLedgerTable(e.target.value)} // Set ledger selection
  disabled={!chooseOptionTable} // Disable if no member is selected
>
  {/* Default placeholder option */}
  <option value="" disabled style={{ fontSize: "11px" }}>
    Select Ledger
  </option>
  {filteredLedgerListTable.map((ledger) => (
    <option key={ledger.AccID} value={ledger.AccID}>
      {ledger.AccName}
    </option>
  ))}
</select>
                </div>

                <div
                  className="col-lg-2 offset-lg-6 offset-md-8 offset-sm-1 col-md-3 col-sm-3 align-self-end Search"
                  style={{ padding: "8px" }}
                >
                  <div className="dashboard-search-field justify-content-end">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Account Table */}
            <div className="row">
              <div className="col-lg-12 p-1">
                <AccountTable searchTerm={searchTerm} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <AccountPopUp accountTrigger={popup} setAccountTrigger={setPopup} />
    </>
  );
}

export default Account;
