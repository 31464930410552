import React, { useContext, useEffect, useState } from "react";
import "../../css/Complain.css";


import CryptoJS from "crypto-js";
import Spinner from "../Spinner/Spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import { showImgPreview } from "../hooks/ImagePreview";
import ChhimekiContext from "../contextFolder/ChhimekiContextFolder/ChhimekiContext";
import ChhimekiPopUpEdit from "./ChhimekiPopUpEdit";


const ChhimekiTable = ({ searchTerm }) => {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("")

  const context = useContext(ChhimekiContext);
  const { editPop, setEditPop, handleEdit, chhimekiList, loading, deactivateDepart, deactivateAgree } = context;




  //chnage activated and deactivated
  const changeStatus = (repID, IsActive) => {
    deactivateDepart(repID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === 1) {
      return "Deactivate";
    } else if (IsActive === 0) {
      return "Activate";
    }
  }

  // For change agree and disagree

  const changeAgree = (repID, IsActive) => {
    deactivateAgree(repID, IsActive);
  };

  const checkAgree = (IsActive) => {
    if (IsActive === "Y") {
      return "Disagree";
    } else if (IsActive === "N") {
      return "Agree";
    }
  }

  if (chhimekiList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "370px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <table className="table table-striped">
                <thead>
                  <tr className="tableHead">
                    <td className="tl">S.N.</td>
                    <td className="tl">Logo</td>
                    <td className="tl">Name</td>
                    <td className="tl">Address</td>
                    <td className="tl">Contact</td>
                    <td className="tl">Member</td>
                    <td className="tl">Created Date</td>
                    <td className="tl">Allow</td>
                    <td className="tl">Expiry Date</td>
                    <td style={{ width: "235px" }}>
                      {" "}
                      Action
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {chhimekiList.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.Name.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) ||
                      val.Address.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) ||
                      val.Contact.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      )
                    ) {
                      return val;
                    }
                  }).map((item, i) => (
                    <tr key={i}>

                      <td className="tc">{i + 1}</td>

                      <td className="contentLogo tl" ><div className="mgmtImg tl"><img src={item.Logo}
                        alt=""
                        onClick={() => { setImagePre(item.Logo); setImgPrv(true) }}

                      /></div></td>
                      <td style={{ textAlign: "left" }}> {item.Name}</td>
                      <td style={{ textAlign: "left" }}> {item.Address}</td>
                      <td className="tl">{item.Contact} </td>
                      <td className="tl">{item.NoOfUser} </td>
                      <td className="tl">{item.CreatedDate} </td>
                      <td className="tl">{item.IsAllow} </td>
                      <td className="tl">{item.ExpiryDate}</td>

                      <td className="tl">
                        <span
                          className="rejectspan badge"
                          onClick={() => handleEdit(item)}
                        >
                          Edit
                        </span>
                        |
                        <span
                          className="editspan badge"
                          onClick={() => changeStatus(
                            item.ChhimekiID, item.Status
                          )}

                        >
                          {checkStatus(item.Status)}
                        </span>
                        |
                        <span
                          className="agreespan badge"
                          onClick={() => changeAgree(
                            item.ChhimekiID, item.IsAllow
                          )}
                        >
                          {checkAgree(item.IsAllow)}
                        </span>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            )}
          </div>
        </div>
        <ChhimekiPopUpEdit
          chhimekTrigger={editPop}
          setChhimekTrigger={setEditPop}
        />

        {imgPrv &&
          showImgPreview({
            img: imagePre,
            setTrigger: setImgPrv,
          })}
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "400px",
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr className="tableHead">
                <td className="tl">S.N.</td>
                <td className="tl">Logo</td>
                <td className="tl">Name</td>
                <td className="tl">Address</td>
                <td className="tl">Contact</td>
                <td className="tl">Member</td>
                <td className="tl">Created Date</td>
                <td className="tl">Allow</td>
                <td className="tl">Expiry Date</td>
                <td style={{ width: "220px" }}>
                  {" "}
                  Action
                </td>
              </tr>
            </thead>
          </table>
          <div className="text-center mt-4">
            <p>No data found</p>{" "}
          </div>
        </div>
      </div>
    );
  }
}

export default ChhimekiTable;


{/* . */ }