import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import GovernmentContext from "../contextFolder/GovernmentContextFolder/GovernmentContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import { Fetchdata } from "../hooks/getData";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function GovernmentEdit(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const memcontext = useContext(MemberContext);
  const { memberDrop } = memcontext;

  const { setMsg, urll} = useContext(SidebarContext);

  const context = useContext(GovernmentContext);

  const {
    governmentFormValue,
    setGovernmentFormValue,
    governmentFormError,
    setGovernmentFormError,
    setPushGovernment,
    setEditPop,
    governmentTitleList,
    pushGovernment,
    GovernmentPushAll,
    editIsSubmit,
    setEditIsSubmit,
    closeChecked,
    setCloseChecked,
    setDate,
    titleID
  } = context;

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setGovernmentFormValue({ ...governmentFormValue, [name]: value });
  };

  const handleDate = ({ bsDate }) => {
    setGovernmentFormValue({ ...governmentFormValue, date: bsDate });
    setDate(Math.random());
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handlePushNotification = () => {
    setPushGovernment(!pushGovernment);
  };

  console.log(governmentFormValue);
  const [newValue, setNewValue] = useState("member");
  // API to submit edit data in notification form
  const obj = {
    identype: governmentFormValue.identype,
    identno: governmentFormValue.identno,
    issueby:governmentFormValue.issueby,
    issuedate:governmentFormValue.issuedate,
  };

  const validates = (values) => {
    const errors = {};
    if (!values.identype) {
      errors.identype = "Required";
    }
    if (!values.identno) {
      errors.identno = "Required";
    }

    return errors;
  };


  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "U",
      UserID: UserID,
      IdentID:titleID,
      IdentType:obj.identype,
      IdentNo:obj.identno,
      IdentDate:obj.issuedate,
      IdentBy:obj.issueby,
      FetchURL: `${urll}/admin/gov-ident`,
      Type: "POST",
    };
     console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        governmentTitleList();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  const formSubmit = (e) => {
    console.log("here")
    e.preventDefault();
    setGovernmentFormError(validates(governmentFormValue));
    setEditIsSubmit(true);
    setEditPop(false);
  };


  useEffect(() => {
    if (Object.keys(governmentFormError).length === 0 && editIsSubmit) {
        fetchdata(governmentFormValue);
      if (pushGovernment === true) {
        GovernmentPushAll();
      }
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(200);
        setEditPop(false);
      }
    //   setReload(!reload);

      setNewValue("member");
      setEditIsSubmit(false);
      setPushGovernment(false);
    }
  }, [governmentFormError]);


  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopups").fadeOut(100);
    setEditPop(false);
    setGovernmentFormError({});
    setEditIsSubmit(false);
    setNewValue("member");
    setPushGovernment(false);
    setDate(Math.random());
  };



  return (
    <>
      <div className="container-fluid basicform">
        <form> 

          <div className="form-row">

          {/* <div className="form-group col-md-12">
              <label className="form-label" htmlFor="category">
                Select Member<sup className="sup-col">*</sup>
              </label>
              <select
                id="slug-title"
                name="member"
                value={governmentFormValue.member}
                onChange={handleChange}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                onClick={(e) => {
                  setNewValue(e.target.value);
                  //console.log(e.target.value);
                }}
              >
                <option defaultValue="Select Event Title">Select Member</option>
                {memberDrop.map((item) => (
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{governmentFormError.member}</p>
            </div> */}



            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="identype">
                Identity Type<sup className="sup-col">*</sup>
              </label>
              <select
                id="identype"
                name="identype"
                onChange={handleChange}
                value={governmentFormValue.identype}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Identity Type
                </option>
                <option value="C">Citizenship</option>
                <option value="D">Driving</option>
                <option value="P">Passport</option>
                <option value="V">Voter</option>
              </select>
              <p className="errormsg " style={{ marginBottom: "0" }}>
                {governmentFormError.identype}
              </p>
            </div>


          <div className="form-group col-md-12">
              <label className="form-label" htmlFor="identno">
                Identity No <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={governmentFormValue.identno}
                onChange={handleChange}
                name="identno"
                className="form-control form-control-sm mb-1"
                id="identno"
              />
              <p className="errormsg ">{governmentFormError.identno}</p>
            </div>


            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="issuedate">
                Issued Date <sup className="sup-col">*</sup>
              </label>
              <input
                type="date"
                value={governmentFormValue.issuedate}
                onChange={handleChange}
                name="issuedate"
                className="form-control form-control-sm mb-1"
                id="issuedate"
              />
              <p className="errormsg">{governmentFormError.issuedate}</p>
            </div>


            <div className="form-group col-md-12 form--group">
              <label className="form-label" htmlFor="issueby">
                Issued By <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                value={governmentFormValue.issueby}
                onChange={handleChange}
                name="issueby"
                className="form-control form-control-sm mb-1"
                id="issueby"
              />
              <p className="errormsg ">{governmentFormError.issueby}</p>
            </div>
            </div>


        </form>
      </div>
      <div className="col-lg-12 notificationFormFooter">
        {/* <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div> */}
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
