import React, { useState, useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Member.css";
import LedgerContext from "../contextFolder/LedgerContextFolder/LedgerContext";
import EditLedger from "./EditLedger";



export default function EditLedgerPopup(props) {
  const { height } = UsewindowDimension();
  const {
    setLedgerFormValue,
    setLedgerFormError,
    setPerEditSubmit,
    setCloseChecked,
    ledgervalue,
  } = useContext(LedgerContext);
  

  const closePopup = (e) => {
    e.preventDefault();
    props.setLedgerTrigger(false);
    $(".viewHelplinePop").fadeOut(100);
    setLedgerFormValue(ledgervalue);
    setLedgerFormError({});
    setPerEditSubmit(false);
    setCloseChecked(false);
  };

  useEffect(() => {
    if (props.ledgerTrigger) {
      $(".viewLedgerPop").fadeIn(100);
    }
  }, [props.ledgerTrigger]);

  return (
    <div className="complainPopUp viewHelplinePop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <div className={height < 500 ? "inside--fam ip500" : "inside--fam"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Edit Ledger</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="notice-outlet">
          <EditLedger />
        </div>
      </div>
    </div>
  );
}
