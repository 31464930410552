import React, { useContext, useState, useEffect, useRef } from "react";
import $ from "jquery";
import "../../css/imagePreview.css";
import "../../css/Member.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import Person from "../../images/person.png";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../css/imagePreview.css";
import FamilyContext from "../contextFolder/FamilyContextFolder/FamilyContext";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import RelationPopup from "../ProfessionPage/RelationPopup";
import ProfessionPopup2 from "../ProfessionPage/ProfessionPopup2";
import AcademicPopup from "../ProfessionPage/AcademicPopup";

export default function FamilyEdit(props) {
  const {
    prList,
    professionFunction,
    relationDropdown,
    academicDropdown,
    professionDropdown,
  } = useContext(ProfessionContext);

  const {
    urll,
    relationPopup,
    setRelationPopup,
    professionPopup2,
    setProfessionPopup2,
    academicPopup,
    setAcademicPopup,
  } = useContext(SidebarContext);

  const context = useContext(FamilyContext);
  const {
    setFamilyFormValue,
    familyFormValue,
    image,
    setImage,
    formValue,
    familyFormError,
    setFamilyFormError,
    editIsSubmit,
    setEditIsSubmit,
    setEditPop,
    editData,
    closeChecked,
    setCloseChecked,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
  } = context;

  const [reBs, setReBs] = useState();

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const inputRef7 = useRef();
  const inputRef8 = useRef();
  const inputRef9 = useRef();
  const inputRef10 = useRef();
  const inputRef11 = useRef();
  const inputRef12 = useRef();

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopups").fadeOut(100);
    setEditPop(false);
    setFamilyFormError({});
    setEditIsSubmit(false);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setFamilyFormValue({ ...familyFormValue, [name]: value });
  };
  const handleDate = ({ bsDate }) => {
    setFamilyFormValue({ ...familyFormValue, dob: bsDate });
    setReBs(Math.random());
  };

  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.marriage) {
      errors.marriage = "Required";
      inputRef10.current.focus();
    }

    if (!values.religion) {
      errors.religion = "Required";
      inputRef9.current.focus();
    }

    if (!values.nationality) {
      errors.nationality = "Required";
      inputRef8.current.focus();
    }
    if (!values.blood) {
      errors.blood = "Required";
      inputRef7.current.focus();
    }
    if (!values.academic) {
      errors.academic = "Required";
      inputRef6.current.focus();
    }
    if (!values.profession) {
      errors.profession = "Required";
      inputRef5.current.focus();
    }
    if (!values.cAddress) {
      errors.cAddress = "Required";
      inputRef4.current.focus();
    }
    if (!values.gender) {
      errors.gender = "Required";
      inputRef3.current.focus();
    }
    if (!values.fullName) {
      errors.fullName = "Required";
      inputRef2.current.focus();
    }
    if (!values.relation) {
      errors.relation = "Required";
      inputRef1.current.focus();
    }

    if (values.pNumber.length === 0) {
      setEditIsSubmit(true);
    } else if (!numv.test(values.pNumber)) {
      errors.pNumber = "Must be digits";
      inputRef11.current.focus();
    } else if (!digits.test(values.pNumber)) {
      errors.pNumber = "Must be 10 digits";
      inputRef11.current.focus();
    }
    if (values.email.length === 0) {
      setEditIsSubmit(true);
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
      inputRef12.current.focus();
    }

    return errors;
  };

  const handleRelationPopup = (e) => {
    // e.preventDefault();
    setRelationPopup(true);
    $(".relationTopicPop").fadeIn(100);
    // setProfessionFormValue(professionvalue);
  };
  const handleProfessionPopup2 = (e) => {
    // e.preventDefault();
    setProfessionPopup2(true);
    $(".professionTopicPop").fadeIn(100);
    // setProfessionFormValue(professionvalue);
  };
  const handleAcademicPopup = (e) => {
    // e.preventDefault();
    setAcademicPopup(true);
    $(".academicTopicPop").fadeIn(100);
    // setProfessionFormValue(professionvalue);
  };

  const formNext = (e) => {
    e.preventDefault();
    setFamilyFormError(validate(familyFormValue));
    setEditIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(familyFormError).length === 0 && editIsSubmit) {
      editData(familyFormValue, image);
      if (closeChecked === true) {
        $(".displayPopups").fadeOut(100);
        setEditPop(false);
      }
      setEditIsSubmit(false);
    }
  }, [familyFormError]);

  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-3">
            <div className="display-picture">
              <div className="image-upload">
                {!image ? (
                  <>
                    <label>
                      <img
                        src={Person}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                      <p style={{ color: "#444" }}>Click to upload image</p>
                    </label>

                    <input
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
            <input
              type="file"
              id="upload-input"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <label for="upload-input" className="upload--display--button">
              Upload Image
            </label>
          </div> */}

          <div className="col-md container-fluid basicform">
            <form>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="relation">
                    Relation <sup className="sup-col">*</sup>
                  </label>
                  <div className="uk-flex">
                    <select
                      id="relation"
                      name="relation"
                      value={familyFormValue.relation}
                      onChange={handleChange}
                      className="form-control form-control-sm mb-1"
                      style={{ fontSize: "12px" }}
                      ref={inputRef1}
                      required
                    >
                      <option value="" selected disabled>
                        Select Relation
                      </option>

                      {relationDropdown.map((item) => (
                        <option value={item.ID}>{item.Name}</option>
                      ))}
                    </select>
                    {/* <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i> */}
                    <span>
                      <button
                        onClick={handleRelationPopup}
                        type="button"
                        className="btn btn-primary"
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <p className="errormsg ">{familyFormError.relation}</p>
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="fullName">
                    Full Name <sup className="sup-col">*</sup>
                  </label>

                  <input
                    type="text"
                    name="fullName"
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="fullName"
                    value={familyFormValue.fullName}
                    ref={inputRef2}
                    required
                  />
                  <p className="errormsg ">{familyFormError.fullName}</p>
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="gender">
                    Gender <sup className="sup-col">*</sup>
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={familyFormValue.gender}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    ref={inputRef3}
                    required
                  >
                    <option value="" selected disabled>
                      Select Gender
                    </option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </select>
                  <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i>
                  <p className="errormsg ">{familyFormError.gender}</p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="cAddress">
                    Current Address <sup className="sup-col">*</sup>
                  </label>
                  <input
                    type="text"
                    name="cAddress"
                    onChange={handleChange}
                    value={familyFormValue.cAddress}
                    className="form-control form-control-sm mb-1"
                    id="cAddress"
                    ref={inputRef4}
                    required
                  />
                  <p className="errormsg ">{familyFormError.cAddress}</p>
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="pNumber">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="pNumber"
                    value={familyFormValue.pNumber}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="pNumber"
                    ref={inputRef11}
                  />
                  <p className="errormsg ">{familyFormError.pNumber}</p>
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={familyFormValue.email}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="email"
                    ref={inputRef12}
                  />
                  <p className="errormsg ">{familyFormError.email}</p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="dob">
                    {" "}
                    Date of Birth
                  </label>

                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    theme="default"
                    language="en"
                    hideDefaultValue={true}
                    placeholder={familyFormValue.dob}
                    value={familyFormValue.dob}
                    onChange={handleDate}
                    name="dob"
                    key={reBs}
                  />
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="profession">
                    Profession <sup className="sup-col">*</sup>
                  </label>
                  <div className="uk-flex">
                    <select
                      value={familyFormValue.profession}
                      name="profession"
                      id="profession"
                      onChange={handleChange}
                      className="form-control form-control-sm mb-1"
                      style={{ fontSize: "12px" }}
                      ref={inputRef5}
                      required
                    >
                      <option value="" selected disabled>
                        Select Profession
                      </option>

                      {professionDropdown.map((item) => (
                        <option value={item.ID}>{item.Name}</option>
                      ))}
                    </select>
                    {/* <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i> */}
                    <span>
                      <button
                        onClick={handleProfessionPopup2}
                        type="button"
                        className="btn btn-primary"
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <p className="errormsg ">{familyFormError.profession}</p>
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="academic">
                    Academic <sup className="sup-col">*</sup>
                  </label>
                  <div className="uk-flex">
                    <select
                      id="academic"
                      name="academic"
                      onChange={handleChange}
                      value={familyFormValue.academic}
                      className="form-control form-control-sm mb-1"
                      style={{ fontSize: "12px" }}
                      ref={inputRef6}
                      required
                    >
                      <option value="" selected disabled>
                        Select Academic
                      </option>

                      {academicDropdown.map((item) => (
                        <option value={item.ID}>{item.Name}</option>
                      ))}
                    </select>
                    {/* <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i> */}

                    <span>
                      <button
                        onClick={handleAcademicPopup}
                        type="button"
                        className="btn btn-primary"
                      >
                        +
                      </button>
                    </span>
                  </div>

                  <p className="errormsg ">{familyFormError.academic}</p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="blood">
                    Blood Group <sup className="sup-col">*</sup>
                  </label>
                  <select
                    id="blood"
                    name="blood"
                    onChange={handleChange}
                    value={familyFormValue.blood}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    ref={inputRef7}
                    required
                  >
                    <option value="" selected disabled>
                      Select Blood Group
                    </option>
                    <option value="1">A+</option>
                    <option value="2">A-</option>
                    <option value="3">B+</option>
                    <option value="4">B-</option>
                    <option value="5">O+</option>
                    <option value="6">O-</option>
                    <option value="7">AB+</option>
                    <option value="8">AB-</option>
                  </select>
                  <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i>
                  <p className="errormsg ">{familyFormError.blood}</p>
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="nationality">
                    Select Nationality <sup className="sup-col">*</sup>
                  </label>
                  <select
                    id="nationality"
                    name="nationality"
                    onChange={handleChange}
                    value={familyFormValue.nationality}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    ref={inputRef8}
                    required
                  >
                    <option value="" selected disabled>
                      Select Nationality
                    </option>
                    <option value="1">Nepali</option>
                  </select>
                  <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i>
                  <p className="errormsg ">{familyFormError.nationality}</p>
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="religion">
                    Religion <sup className="sup-col">*</sup>
                  </label>
                  <select
                    id="religion"
                    name="religion"
                    value={familyFormValue.religion}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    ref={inputRef9}
                    required
                  >
                    <option value="" selected disabled>
                      Select Religion
                    </option>
                    <option value="1">Hindu</option>
                    <option value="2">Buddhist</option>
                    <option value="3">Christian</option>
                    <option value="4">Muslim</option>
                    <option value="5">Kirat</option>
                    <option value="6">Other</option>
                  </select>
                  <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i>
                  <p className="errormsg ">{familyFormError.religion}</p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="marriage">
                    Marital Status <sup className="sup-col">*</sup>
                  </label>
                  <select
                    id="marriage"
                    name="marriage"
                    value={familyFormValue.marriage}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    style={{ fontSize: "12px" }}
                    ref={inputRef10}
                    required
                  >
                    <option value="" selected disabled>
                      Select Marital Status
                    </option>
                    <option value="1">Single</option>
                    <option value="2">Married</option>
                    <option value="3">Widowed</option>
                    <option value="4">Divorced</option>
                  </select>
                  <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i>
                  <p className="errormsg ">{familyFormError.marriage}</p>
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="pan">
                    PAN
                  </label>

                  <input
                    type="text"
                    name="pan"
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="pan"
                    value={familyFormValue.pan}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="citizenship">
                    Citizenship No.
                  </label>

                  <input
                    type="text"
                    name="citizenship"
                    value={familyFormValue.citizenship}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="citizenship"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="cIssued">
                    Issued From
                  </label>
                  <input
                    type="text"
                    name="cIssued"
                    value={familyFormValue.cIssued}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="cIssued"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="cDate">
                    Date of Issue
                  </label>
                  <input
                    type="text"
                    name="cDate"
                    value={familyFormValue.cDate}
                    onChange={handleChange}
                    className="form-control form-control-sm mb-1"
                    id="cDate"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
                </div>

                <div className="form-group col-md-4">
                  <label className="form-label" htmlFor="vid">
                    Voter ID
                  </label>

                  <input
                    type="text"
                    name="vid"
                    onChange={handleChange}
                    value={familyFormValue.vid}
                    className="form-control form-control-sm mb-1"
                    id="vid"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.date}</p> */}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="nid">
                    National ID
                  </label>
                  <input
                    type="text"
                    name="nid"
                    onChange={handleChange}
                    value={familyFormValue.nid}
                    className="form-control form-control-sm mb-1"
                    id="nid"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
                </div>

                <div className="form-group col-md-4  ">
                  <label className="form-label" htmlFor="pno">
                    Passport No.
                  </label>
                  <input
                    type="text"
                    name="pno"
                    onChange={handleChange}
                    value={familyFormValue.pno}
                    className="form-control form-control-sm mb-1"
                    id="pno"
                  />
                  {/* <p className="errormsg ">{basicFormErrors.time}</p> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-lg-12 Member--Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formNext}
          >
            Update
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>

      <RelationPopup
        relationPopup={relationPopup}
        setRelationPopup={setRelationPopup}
      />
      <ProfessionPopup2
        professionPopup2={professionPopup2}
        setProfessionPopup2={setProfessionPopup2}
      />
      <AcademicPopup
        academicPopup={academicPopup}
        setAcademicPopup={setAcademicPopup}
      />
    </>
  );
}
