import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import ComplainContext from "../contextFolder/ComplainContextFolder/ComplainContext";
import ComplainPop from "./ComplainPop";
import Spinner from "../Spinner/Spinner";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import { AiFillPrinter } from "react-icons/ai";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";

const ComplainTable = ({ searchTerm }) => {
  const {
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);
  const context = useContext(ComplainContext);
  const {
    popup,
    setPopup,
    handleViewComplain,
    state,
    complainMainList,
    checkComplainType,
    loading,
  } = context;



  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (complainMainList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "360px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                {/* <div className="table__button__wrapper">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                  />

                  <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  />
                </div> */}
                <div ref={ref}>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>S.N.</td>
                        <td style={tableHeadBg}>Name</td>
                        <td style={tableHeadBg}>Topic</td>
                        <td style={tableHeadBg}>Description</td>
                        {/* <td style={tableHeadBg}>Title</td>
                        <td style={tableHeadBg}> Date</td> */}
                        <td style={tableHeadBg}>Status</td>
                        <td style={tableHeadWidthBg}> Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {complainMainList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.Topic.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Title.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.ComplainDate.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg}>{i + 1}</td>
                            <td style={tableDataBg}>{item.FullName}</td>
                            <td style={tableDataBg}>{item.Topic}</td>
                            <td style={tableDataBg}>{item.Description}</td>
                            <td>
                              {" "}
                              <span className="editspan badge" style={darkText}>
                                {checkComplainType(item.ComplainStatus)}
                              </span>
                            </td>
                            <td style={darkText}>
                              <span
                                className="editspan badge"
                                onClick={() => handleViewComplain(item)}
                                style={darkText}
                              >
                                View
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        <ComplainPop
          complainTrigger={popup}
          setComplainTrigger={setPopup}
          state={state}
        />
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
              />

              <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf}>
                    <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                  </button>
                )}
              </Pdf>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button>
                      <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              /> */}
            </div>
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>S.N.</td>
                    <td style={tableHeadBg}>Name</td>
                    <td style={tableHeadBg}>Topic</td>
                    <td style={tableHeadBg}>Title</td>
                    <td style={tableHeadBg}> Description</td>
                    <td style={tableHeadBg}>Status</td>
                    <td style={tableHeadWidthBg}> Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default ComplainTable;
