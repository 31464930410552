import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import SliderContext from "./SliderContext";
import $ from "jquery";

function SliderState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  // console.log(userinfo)
  const ToleID = userinfo.ToleID;
   const UserID = userinfo.UserID;
  const { setMsg, urll } = useContext(SidebarContext);

  const [sliderFormError, setSliderFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [popup, setPopup] = useState(false);

  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");

  const [closeChecked, setCloseChecked] = useState(false);
  const [pushNotice, setPushNotice] = useState(false);

  const [loading, setLoading] = useState(true);

  const slidervalue = {
    // order: "",
    title: "",
  };

  const [sliderFormValue, setSliderFormValue] = useState(slidervalue);
  useEffect(() => {
    if (popup) {
      $(".complainTopicPop").fadeIn(100);
    }
  }, [popup]);
  
  useEffect(() => {
    if (Object.keys(sliderFormError).length === 0 && isSubmit) {
  const dataForm  = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "i",
      Title: sliderFormValue.title,
      ImgUrl: image !== null ? image.split(",")[1] : "",
      RedUrl:sliderFormValue.redurl,
      ImgOrder:sliderFormValue.imgorder,
      IsActive:"A",
    
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
        
        setPopup(false);
          $(".complainTopicPop").fadeOut(100);
          sliderList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };
}, [sliderFormError]);

  //To populate

  const [slideList, setSlideList] = useState([]);

  useEffect(() => {
    sliderList();
  }, []);

  const sliderList = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "S",
      "IsActive": "-1",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
        if (resp.StatusCode === 200) {
          const postResult = resp.sliderlst ? resp.sliderlst : "";
          setLoading(false);
          setSlideList(postResult);
        } else {
          setSlideList([]);
        }
      });
    });
  };

  //API to delete

  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  const [todos, setToDos] = useState([]);

  const [Acdata, setACData] = useState();

  // To delete the Account List

  const handleDelete = (data) => {
    setACData(data);

    setDelPopup({
      show: true,
      data,
    });
  };
  //to deactivate and activate
  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);
  const deactivateSlider = (ID, IsActives) => {
    const editobj = {
      SliderID: ID,
      Status: IsActives,
    };
    const dataForm = {
      ToleID: ToleID,
      Flag: "ai",
      UserID: UserID,
      SliderID: editobj.SliderID,
      IsActive: editobj.Status,
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          // console.log(resp)
          //eventListPop();
          sliderList();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.IsActive === "A") {
            pitchStatus = "Activated";
          } else if (dataForm.IsActive === "I") {
            pitchStatus = "Deactivated";
          }
          console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "R",
      SliderID: Acdata,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/slider`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          sliderList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
          setDelPopup({
            show: false,
            data: null,
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };

  return (
    <SliderContext.Provider
      value={{
        sliderFormError,
        setSliderFormError,
        isSubmit,
        setIsSubmit,
        popup,
        setPopup,
        image,
        setImage,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        sliderFormValue,
        setSliderFormValue,
        slidervalue,
        loading,
        setLoading,
        closeChecked,
        setCloseChecked,
        pushNotice,
        setPushNotice,
        // fetchdata,
        slideList,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        delPopup,
        deactivateSlider
      }}
    >
      {props.children}
    </SliderContext.Provider>
  );
}

export default SliderState;
