import React, { useContext, useEffect } from "react";
import UsewindowDimension from "../hooks/UsewindowDimension";
import $ from "jquery";
import "../../css/Complain.css";
import ComplainFormPop from "./ComplainFormPop";
import ComplainContext from "../contextFolder/ComplainContextFolder/ComplainContext";

export default function ComplainTopicPop(props) {
  const { height } = UsewindowDimension();
  const { setComplainTitleValue, addComplainValue, setIsEdit, setComplainTopicError, setComplainTopicSubmit } =
    useContext(ComplainContext);

  const closePopup = (e) => {
    e.preventDefault();
    props.setTriggerPop(false);
    $(".complainTopicPop").fadeOut(100);
    setComplainTitleValue({ title: "" });
    setComplainTopicError({});
    setComplainTopicSubmit(false);
    setIsEdit(false)
  };

  useEffect(() => {
    if (props.triggerPop) {
      $(".complainTopicPop").fadeIn(100);
    }
  }, [props.triggerPop]);

  return (
    <div className="complainPopUp complainTopicPop container-fluid   col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      {/* <OutsideAlerter> */}
      <div className={height < 500 ? "complainInside ip500" : "complainInside"}>
        <div className="popUpHeader ">
          <div className="popUpHeaderText ">Add Complain Topic</div>
          <div style={{ cursor: "pointer" }}>
            <i
              className="bi bi-x "
              onClick={closePopup}
              style={{ fontSize: "25px" }}
            ></i>
          </div>
        </div>

        <div className="complain-outlet">
          <ComplainFormPop  />
        </div>
      </div>
    </div>
  );
}
