import { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Complain from "./component/ComplainPage/Complain";
import Dashboard from "./component/DashboardPage/Dashboard";
import Layout from "./component/Layout";
import Login from "./component/Login";
import Event from "./component/EventPage/Event";
import SidebarState from "./component/sidebarcontext/SidebarState";
import EventState from "./component/contextFolder/EventContextFolder/EventState";
import AuthContext from "./component/auth-context";
import Notification from "./component/NotificationPage/Notification";
import NoticeState from "./component/contextFolder/NoticeContextFolder/NoticeState";
import ComplainState from "./component/contextFolder/ComplainContextFolder/ComplainState";
import ManagementState from "./component/contextFolder/ManagementContextFolder/ManagementState";
import Management from "./component/ManagementPage/Management";
import Member from "./component/MemberPage/Member";
import NoMatchPage from "./component/NoMatchPage";
import MemberState from "./component/contextFolder/MemberContextFolder/MemberState";
import Account from "./component/AccountPage/Account";
import AccountState from "./component/contextFolder/AccountContextFolder/AccountState";
import AccountSummary from "./component/AccountPage/AccountSummaryPage/AccountSummary";
import Chhimeki from "./component/ChhimekiPage/Chhimeki";
import Slider from "./component/SliderPage/Slider";
import SliderState from "./component/contextFolder/SliderContextFolder/SliderState";
import Profile from "./component/ProfilePage/Profile";
import Helpline from "./component/HelplinePage/Helpline";
import HelplineState from "./component/contextFolder/HelplineContextFolder/HelplineState";
import ToLet from "./component/ToLetPage/ToLet";
import DashboardState from "./component/contextFolder/DashboardContextFolder/DashboardState";
import GovernmentIdentity from "./component/GovernmentIdentityPage/GovernmentIdentity";
import GovernmentState from "./component/contextFolder/GovernmentContextFolder/GovernmentState"; 
import Ledger from "./component/LedgerPage/Ledger";
import LedgerState from "./component/contextFolder/LedgerContextFolder/LedgerState";
import Income from "./component/IncomePage/Income";
import IncomeState from "./component/contextFolder/IncomeContextFolder/IncomeState";
function App() {
  const auth = localStorage.getItem("userInfo");
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {!auth && <Route path="/login" element={<Login />} />}
      </Routes>

      <div className="App">
        {authCtx.isLoggedIn && (
          <SidebarState>
            <DashboardState>
              <HelplineState>
                <SliderState>
                  {/* <ChhimekiState> */}
                    {/* <PlacesState> */}
                      <AccountState>
                      <LedgerState>
                      <IncomeState>
                        <EventState>
                          <NoticeState>
                            <ComplainState>
                              <GovernmentState>
                              <ManagementState>
                                <MemberState>
                                  {/* <ProfessionState> */}
                                    {/* <FamilyState> */}
                                      <Layout>
                                        <Routes>
                                          {/* <Route
                                            path="/dashboard"
                                            element={<Dashboard />}
                                          /> */}
                                          <Route
                                            path="/complain"
                                            element={<Complain />}
                                          />
                                           <Route
                                            path="/gov-identity"
                                            element={<GovernmentIdentity />}
                                          />
                                          
                                          <Route
                                            path="/event"
                                            element={<Event />}
                                          />
                                          <Route
                                            path="/notice"
                                            element={<Notification />}
                                          />
                                          <Route
                                            path="/management"
                                            element={<Management />}
                                          />
                                          <Route
                                            path="/member"
                                            element={<Member />}
                                          />
                                          <Route
                                            path="/account"
                                            element={<Account />}
                                          />
                                           <Route
                                            path="/ledger"
                                            element={<Ledger />}
                                          />
                                            <Route
                                            path="/incomeexpense"
                                            element={<Income />}
                                          />
                                          <Route
                                            path="/summary"
                                            element={<AccountSummary />}
                                          />
                                          <Route
                                            path="/profile"
                                            element={<Profile />}
                                          />
                                          <Route
                                            path="/chhimeki-list"
                                            element={<Chhimeki />}
                                          />
                                          <Route
                                            path="/slider"
                                            element={<Slider />}
                                          />
                                          <Route
                                            path="/helpline"
                                            element={<Helpline />}
                                          />
                                          <Route
                                            path="/to-let"
                                            element={<ToLet />}
                                          />
                                          <Route
                                            path="*"
                                            element={<NoMatchPage />}
                                          />
                                        </Routes>
                                      </Layout>
                                    {/* </FamilyState> */}
                                  {/* </ProfessionState> */}
                                </MemberState>
                              </ManagementState>
                              </GovernmentState>
                            </ComplainState>
                          </NoticeState>
                        </EventState>
                        </IncomeState>
                        </LedgerState>
                      </AccountState>
                    {/* </PlacesState> */}
                  {/* </ChhimekiState> */}
                </SliderState>
              </HelplineState>
            </DashboardState>
          </SidebarState>
        )}
      </div>
    </>
  );
}

export default App;
