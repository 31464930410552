import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import React, { useContext, useState } from "react";
import "../../css/Complain.css";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import AddNewpopup from "./AddNewpopup";
import EventTable from "./EventTable";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";

function Event() {
  const { mode, mainBg, mainContent } =
    useContext(SidebarContext);
  const context = useContext(EventContext);
  const {
    setBasicFormvalue,
    initialvalue,
    eventList,
    setCloseChecked,
    closeChecked,
    dateFormValue,
    setDateFormValue,
    chooseOption,
    setChooseOption,
    eventTitle,
    chooseType,
    setChooseType,
    popup, setPopup
  } = context;
  const{setDef}=useContext(MemberContext)

  const handleAddEvent = (e) => {
    // e.preventDefault();
    // setCloseChecked(false);
    setPopup(true);
    setBasicFormvalue(initialvalue);
    setDef(Math.random());
  };

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseOption(value);
  };
  const handleType = (e) => {
    const target = e.target;
    const value = target.value;
    setChooseType(value);
  };

  const handleDate = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, fromDate: bsDate });
  };
  const handleDate1 = ({ bsDate }) => {
    setDateFormValue({ ...dateFormValue, toDate: bsDate });
  };

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div
        className="col-lg-12 col-md-12 col-sm-12 side__content"
        style={mainBg}
      >
        <div>
          <div className="mainHeader">
            <div
              className="fontHeader"
              style={{ color: mode === "light" ? "#000" : "#fff" }}
            >
              Event & Meeting
            </div>
          </div>
          {/* <hr style={{ color: mode === "light" ? "#000" : "#fff" }} /> */}
        </div>

        <section
          className="content-section main-content mt-3"
          style={mainContent}
        >
          <div className="content">
            <div className=" col-lg-12 col-sm-12">
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-cmpy btn-sm"
                  style={{ marginRight: "-11px", marginTop: "4px" }}
                  onClick={handleAddEvent}
                >
                  {" "}
                  Add Event & Meeting +
                </button>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                {/* <div
                  className="col-lg-2  col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                > */}
                  {/* <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1 account-bar"
                    value={chooseOption}
                    onChange={handleChange}
                  >
                    <option selected disabled style={{ fontSize: "11px" }}>
                      Select Event Title
                    </option>
                    <option value="-1">All</option>
                    {eventTitle.map((item) => (
                      <option key={item.EventID} value={item.EventID}>
                        {item.Title}
                      </option>
                    ))}
                  </select> */}


                  {/* <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
                </div> */}
                <div
                  className="col-lg-2 col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  {/* <div className="col-lg-2  col-md-3 col-sm-2 " style={{ padding: "5px" }}> */}

                  <select
                    style={{ fontSize: "11px" }}
                    name="snotifiaction"
                    className="form-control form-control-sm mb-1 account-bar"
                    value={chooseType}
                    onChange={handleType}
                  >
                    <option selected disabled style={{ fontSize: "11px" }}>
                      Select Type
                    </option>
                    <option value="-1">All</option>
                    <option value="M">Meeting</option>
                    <option value="E">Event</option>
                  </select>
                  <i class="fas fa-angle-down  accountarrowposition text-dark"></i>
                </div>

                {/* <div
                  className="col-lg-2 col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    From Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    defaultDate="2079/01/01"
                    theme="default"
                    language="en"
                    values={dateFormValue.fromDate}
                    onChange={handleDate}
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div> */}

                {/* <div
                  className="col-lg-2 col-md-3 col-sm-2 "
                  style={{ padding: "5px" }}
                >
                  <label style={{ color: mode === "light" ? "#000" : "#fff" }}>
                    To Date
                  </label>
                  <Calendar
                    className="form-control form-control-sm mb-1"
                    dateFormat="YYYY/MM/DD"
                    defaultDate="2079/01/01"
                    theme="default"
                    language="en"
                    values={dateFormValue.toDate}
                    onChange={handleDate1}
                  />
                  <i
                    class="fa fa-calendar-o account--date--picker text-dark"
                    aria-hidden="true"
                  ></i>
                </div> */}

                {/* </div> */}

                <div
                  className="col-lg-2 offset-lg-8 offset-md-8 offset-sm-1 col-md-3 col-sm-3 align-self-end Search"
                  style={{ padding: "8px" }}
                >
                  <div className="dashboard-search-field justify-content-end">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ border: "none" }}
                    />
                    <i
                      style={{
                        paddingTop: "7px",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color: "#999",
                      }}
                      className="fas fa-search searchIconPosition"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            {
              <div className="row">
                <div className="">
                  <div className="col-lg-12 p-1">
                    <EventTable searchTerm={searchTerm} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>

      <AddNewpopup trigger={popup} setTriggernew={setPopup} />
    </>
  );
}

export default Event;
