import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import EventContext from "../contextFolder/EventContextFolder/EventContext";
import Spinner from "../Spinner/Spinner";
import EventEditPop from "./EventEditPop";
import SidebarContext from "../sidebarcontext/SidebarContext";
import EventDelete from "./EventDelete";


const EventTable = ({ searchTerm }) => {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;

  const context = useContext(EventContext);
  const {
   
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
  
    darkText,

  } = useContext(SidebarContext);
  const {
    
    editPop,
    setEditPop,
    handleEdit,
    deactivateEvent,
   handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
    handleDelete,
    loading,
    eventList,
  } = context;

  // const changeStatus = (eventID, IsActive) => {
  //   deactivateEvent(eventID, IsActive);
  // };

  // const checkStatus = (IsActive) => {
  //   if (IsActive === 1) {
  //     return "Deactivate";
  //   } else if (IsActive === 0) {
  //     return "Activate";
  //   }
  // };

  const checkEventType = (isEvent) => {
    if (isEvent === "E") {
      return "Event";
    } else if (isEvent === "M") {
      return "Meeting"
    }
  }

  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (eventList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "360px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                {/* <div className="table__button__wrapper">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                  />

                  <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  />
                </div> */}
                <div ref={ref}>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>S.N.</td>
                        <td style={tableHeadBg}>Title</td>
                        <td style={tableHeadBg}>Start</td>
                        <td style={tableHeadBg}>End</td>
                        <td style={tableHeadBg}>Venue</td>
                        <td style={tableHeadBg}>No Of Views</td>
                        <td style={tableHeadBg}>Type</td>
                        
                        <td style={tableHeadWidthBg}> Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {eventList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.Title.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Date.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Venue.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.EndTime.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.StartTime.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg}>{i + 1}</td>
                            <td style={tableDataBg}>{item.Title}</td>
                            <td style={tableDataBg}> {item.TimeFrom}</td>
                            <td style={tableDataBg}> {item.TimeTo}</td>
                            <td style={tableDataBg}> {item.Venue}</td>
                            <td style={tableDataBg}>{item.NoOfView}</td>
                            
                            <td style={darkText}>
                              {" "}
                              <span
                                className="editspan badge"
                                style={{ width: "70px" }}
                              >
                                {checkEventType(item.EventType)}
                                {/* {item.EventType} */}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className="rejectspan badge"
                                onClick={() => {
                                  handleEdit(item);
                                }}
                                style={darkText}
                              >
                                Edit
                              </span>{" "}

                              <span
                                className="rejectspan badge"
                                onClick={() => {
                                  handleDelete(item.EventID);
                                }}
                                style={darkText}
                              >
                                Remove
                              </span>{" "}





                              {/* <span
                                className="editspan badge"
                                onClick={() =>
                                  changeStatus(item.EventID, item.Status)
                                }
                                style={darkText}
                              >
                                {checkStatus(item.Status)}
                              </span> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <EventEditPop trigger={editPop} setTriggernew={setEditPop} />
        {delPopup.show && (
          <EventDelete
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "300px",
            }}
          >
            <div>
              {/* <div className="table__button__wrapper">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                />

                <Pdf
                  targetRef={ref}
                  filename="code-example.pdf"
                  options={options}
                  x={0.5}
                  y={0.5}
                  scale={0.8}
                >
                  {({ toPdf }) => (
                    <button onClick={toPdf}>
                      <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                    </button>
                  )}
                </Pdf>

                <ReactToPrint
                  trigger={() => {
                    return (
                      <button>
                        <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    );
                  }}
                  content={() => componentRef.current}
                  documentTitle="new document"
                  pageStyle="print"
                />
              </div> */}
              <div ref={ref}>
                <table ref={componentRef} className="table" id="table-to-xls">
                  <thead>
                    <tr className="tableHead">
                      <td style={tableHeadBg}>S.N.</td>
                      <td style={tableHeadBg}>Title</td>
                      <td style={tableHeadBg}>Date</td>
                      <td style={tableHeadBg}>Start</td>
                      <td style={tableHeadBg}>End</td>
                      <td style={tableHeadBg}>Venue</td>
                      <td style={tableHeadBg}>Type</td>
                      <td style={tableHeadWidthBg}> Action</td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="text-center mt-4">
              <p style={darkText}> No data found</p>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default EventTable;
